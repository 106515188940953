// 协同绘图 分享
import request from "@/utils/request";
import qs from "qs";

// 协同绘图 获取分享地址
export const search_dwg_info = (data) => {
  return request({
    async: false,
    method: "POST",
    url: "/cloud/cloudapi/search_dwg_info",
    data: qs.stringify(data),
  });
};

// 统计接口
export const quickiscussiontj = (data) => {
  return request({
    method: "POST",
    url: "https://app.pcw365.com/tj/quickiscussiontj",
    data: qs.stringify(data),
  });
};

// 统计接口1
export const sync_tj = (data) => {
  return request({
    method: "POST",
    url: "/cloud/tjdata/sync_tj",
    data: qs.stringify(data),
  });
};

export const openWxmini = (data) => {
  return request({
    method: "POST",
    url: "/api/wxopenapp/getsign",
    data: qs.stringify(data),
  });
};
