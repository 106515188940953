<template>
  <div class="invite-page" v-loading="isLoading" :class="isPcWeixin ? 'isPcWeixin' : ''">
    <input
      type="text"
      ref="isFocus1"
      id="is_down_client_input"
      @blur="animateInput('blur')"
      @focus="animateInput('focus')"
    />

    <div class="pc" v-if="device == 'pc'">
      <div class="main">
        <div class="head" v-if="!is_success">
          <div class="left">
            <img
              :src="fromC == 'draw' ? logoDrawImg1 : logoSeeImg"
              alt=""
              style="width: 32px; height: 32px"
            />
            <span style="font-size: 16px !important">{{
              fromC == "draw" ? "CAD迷你画图 /" : "CAD迷你看图 /"
            }}</span>
            <img
              src="@/assets/images/sync/synclogo1.png"
              alt=""
              style="width: 36px; height: 36px"
            />
            <span style="font-size: 16px !important">多人协同</span>
          </div>
        </div>
        <div class="head_bg" v-if="is_success">
          <div class="head">
            <div class="left">
              <img
                :src="fromC == 'draw' ? logoDrawImg : logoSeeImg"
                alt=""
                style="width: 32px; height: 32px"
              />
              <span>{{ fromC == "draw" ? "CAD迷你画图 /" : "CAD迷你看图 /" }}</span>
              <img
                src="@/assets/images/sync/synclogo.png"
                alt=""
                style="width: 36px; height: 36px"
              />
              <span>多人协同</span>
            </div>
          </div>

          <div class="box" v-if="is_success">
            <div class="cardbox">
              <div class="card">
                <div class="text text1">
                  <div>「</div>
                  <div class="share_name">{{ share_name }}</div>
                  <div>」</div>
                  <div>邀请你打开图纸、一起多人协同CAD</div>
                </div>
                <div class="flex">
                  <img src="@/assets/images/sync/sync_file.png" />
                  <div class="text text2" style="line-height: 26px">
                    <span class="name" v-if="is_success">{{ sync_filename }}</span>
                    <span class="unit" v-if="is_success">{{ unit }}</span>
                  </div>
                </div>

                <div class="line566"></div>

                <div class="btn" @click.stop="open_client_btn">
                  {{ fromC == "draw" ? is_install_text : is_install_textSee }}
                </div>
                <div class="text text3">
                  点击上方按钮，在{{
                    fromC == "draw" ? "CAD迷你画图" : "CAD迷你看图"
                  }}里打开图纸，即可一起多人协同CAD
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="gifbox" v-if="is_success">
          <img
            style="width: 1020px; height: 580px; flex-shrink: 0"
            src="@/assets/images/sync/pcysg.gif"
            alt=""
            srcset=""
          />
        </div>

        <div class="box" :class="!is_success ? 'box_center' : ''" v-if="!is_success">
          <div class="error">
            <div class="error_flex">
              <img src="@/assets/images/sync/error.png" alt="" />
              <div class="text1">图纸链接无效</div>
              <div class="text2">请检查链接完整性，然后重试</div>
            </div>
          </div>
        </div>
        <div class="wechat_open_tip" v-show="isWeChat" @click="weChatHideMask">
          <img src="@/assets/images/phone/down_guide_up1.png" mode="" />
          <div class="steps_box">
            <div class="step one_step">
              <span class="txt_one" style="color: #fff">点击右上角的</span>
              <img src="@/assets/images/phone/down_guide_dian1.png" class="edit_icon" mode="" />
            </div>
            <div class="step two_step">
              <img
                src="@/assets/images/phone/down_guide_llq1.png"
                class="browser_text_img"
                mode=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="phone" v-if="device == 'phone'">
      <!-- <div class="head">
        <div class="left">
          <img src="@/assets/images/sync/logo-phone.png" alt="" />
          <span>CAD迷你看图 /</span>
          <img src="@/assets/images/sync/logo1.png" alt="" />
          <span>快速讨论</span>
        </div>
      </div> -->
      <div class="main">
        <div class="box" v-if="is_success">
          <div class="text text1">
            <div>「</div>
            <div class="share_name">{{ share_name }}</div>
            <div>」</div>
            <div>邀请你打开图纸、一起多人协同CAD</div>
          </div>

          <div class="flex">
            <img src="@/assets/images/sync/sync_file.png" />
            <div class="text text2">
              <span class="name" v-if="is_success">{{ sync_filename }}</span>
              <span class="unit" v-if="is_success">{{ unit }}</span>
            </div>
          </div>
          <div class="btn-group" @click="launch" v-if="wechatState && curSystem == 'android'">
            <wx-open-launch-app
              id="launch-btn"
              @error="handleError"
              @launch="handleLaunch"
              :extinfo="extinfo"
              appid="wx2da810cf7990f4bb"
            >
              <script type="text/wxtag-template">
                <button
                  style="
                    display: flex;
                    width: 100%;
                    height: 45px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    border-radius: 6px;
                    background: #ff9603;
                    color: #fff;
                    text-align: center;
                    font-family: Source Han Sans CN;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    cursor: pointer;
                    border-color: 1px solid ff9603;
                  "
                  class="btn download"
                >
                  一起讨论图纸
                </button>
              </script>
            </wx-open-launch-app>
          </div>
          <div class="btn" @click.stop="open_client_btn" v-if="!wechatState">
            {{ is_install_text_phone }}
          </div>

          <div class="text text3">点击上方按钮，在CAD迷你看图里打开图纸，即可一起多人协同CAD</div>

          <div class="text text4">
            注：为了确保能获取到图纸信息，成功打开图纸，如果打开CAD迷你看图APP，出现是否允许粘贴的弹框提示，请点击“允许粘贴”，打开图纸
          </div>
          <img
            style="margin-top: -10px; min-width: 375px; height: 305px; flex-shrink: 0"
            src="@/assets/images/sync/phoneysg.gif"
            alt=""
            srcset=""
          />
        </div>
        <div class="logo_footer">
          <div class="flex1">
            <img src="@/assets/images/sync/logo-phone.png" alt="" />
            <span>CAD迷你看图</span>
          </div>
        </div>

        <div class="box error_box" v-if="!is_success">
          <div class="error">
            <div class="error_flex">
              <img src="@/assets/images/sync/error.png" alt="" />
              <div class="text1">图纸链接无效</div>
              <div class="text2">请检查链接完整性，然后重试</div>
            </div>
          </div>
        </div>
      </div>
      <div class="wechat_open_tip" v-show="isWeChat && !isPcWeixin" @click="weChatHideMask">
        <img src="@/assets/images/phone/down_guide_up.png" mode="" />
        <div class="steps_box">
          <div class="step one_step">
            <img src="@/assets/images/phone/down_guide1.png" class="step_icon" mode="" />
            <span class="txt_one">点击右上角的</span>
            <img src="@/assets/images/phone/down_guide_dian.png" class="edit_icon" mode="" />
            <span class="txt_two">按钮</span>
          </div>
          <div class="step two_step">
            <img src="@/assets/images/phone/down_guide2.png" class="step_icon" mode="" />
            <span class="txt_one">选择</span>
            <img src="@/assets/images/phone/down_guide_llq.png" class="browser_text_img" mode="" />
          </div>
        </div>
      </div>
      <div class="wechat_open_tip" v-show="isWeChat && isPcWeixin" @click="weChatHideMask">
        <img src="@/assets/images/phone/down_guide_up1.png" mode="" />
        <div class="steps_box">
          <div class="step one_step">
            <span class="txt_one" style="color: #fff">点击右上角的</span>
            <img src="@/assets/images/phone/down_guide_dian1.png" class="edit_icon" mode="" />
          </div>
          <div class="step two_step">
            <img src="@/assets/images/phone/down_guide_llq1.png" class="browser_text_img" mode="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { search_dwg_info, quickiscussiontj, sync_tj, openWxmini } from "@/api/team_work";
import utils from "@/utils/utils";
import { loadJS } from "@/utils/wmtools";
// import VConsole from "vconsole";
export default {
  name: "TeamWork",
  data() {
    return {
      device: "phone",
      is_install: false,
      is_install_text: "点击启动CAD迷你画图打开图纸",
      is_install_textSee: "点击启动CAD迷你看图打开图纸",
      is_install_text_phone: "一起讨论图纸",
      logoDrawImg: require("@/assets/images/sync/logo.png"),
      logoDrawImg1: require("@/assets/images/sync/logono.png"),
      logoSeeImg: require("@/assets/images/sync/logosee.png"),
      is_success: true,
      down_timer: null, //计时器id
      down_duration: 60, //倒计时时长
      down_frequency: 0, //点击次数
      input_timer: null,
      sync_filename: "", //分享 协同文件名称
      unit: "", //单位
      sync_id: "", //分享 协同ID
      share_name: "", // 来自谁的分享
      isWeChat: false, // 手机是否微信环境
      isLoading: true,
      jweixin: "https://res.wx.qq.com/open/js/jweixin-1.6.0.js",
      wechatState: false, // 是否显示微信打开app功能按钮
      extinfo: "",
      isWeixin: true, //是否在微信内
      curSystem: "", //当前系统
      isPcWeixin: false, //是否在微信PC浏览器
      param: {},
      fromC: "draw", //哪自哪个客户端
    };
  },

  mounted() {
    let that = this;
    that.sync_id = utils.getQueryVariable("sync_id");
    // const vConsole = new VConsole();
    // 接下来即可照常使用 `console` 等方法
    that.get_team_work_info();
    loadJS(that.jweixin, function (callback) {
      console.log("loadJS success!");
      that.judgePhoneType();
    });

    that.send_tj(3);
    that.sync_tjFun("open");

    window.onresize = () => {
      return (() => {
        that.handleWindow();
      })();
    };

    that.handleWindow();
  },

  beforeRouteLeave(_to, _from, next) {
    // 执行销毁路由的操作
    // this.$router.destroyRoute(to)
    // 执行解绑操作，解绑 document 上的 keydown 事件监听
    this.unbindDocumentKeydown();

    next();
  },

  created() {},

  methods: {
    // 判断是否运行在微信浏览器中
    isInWeChatBrowser() {
      return navigator.userAgent.match(/micromessenger/i);
    },

    closeTip() {},
    judgePhoneType() {
      var u = navigator.userAgent;
      let status = navigator.userAgent.toLowerCase();
      var isWeixin = u.toLowerCase().indexOf("micromessenger") !== -1; // 微信内
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      this.isWeixin = isWeixin;
      if (isIOS) {
        this.curSystem = "ios";
        document.title = "CAD迷你看图";
      }
      if (isAndroid) {
        this.curSystem = "android";
        document.title = "CAD迷你看图";
      }
      if (status.match(/MicroMessenger/i) == "micromessenger") {
        console.log("在PC微信览器");
        this.isPcWeixin = true;
      }
      if (isWeixin && isAndroid) {
        this.judgeNavigator();
      }
    },

    launch() {
      if (!this.wechatState) {
        console.log("不支持标签降级处理");
        return;
      }
      this.send_tj(8);
      var btn = document.getElementById("launch-btn");
      btn.click();
    },

    judgeNavigator() {
      // 微信版本号大于 7.0.12 开放标签才可进行 唤醒 app 跳转
      const wechatInfo = window.navigator.userAgent.match(/MicroMessenger\/([\d\.]+)/i);
      if (!wechatInfo) return;
      let judgewechat = wechatInfo[1].split(".");
      let flag = false;
      if (judgewechat[0] >= 7 && judgewechat[1] >= 0 && judgewechat[2] >= 12) {
        console.log("当前符合 h5 打开指定app");
        this.wechatInit(); // 微信标签唤起app
        flag = true;
      }
      if (!flag) {
        console.log("请升级当前微信版本");
        if (/wxwork/i.test(window.navigator.userAgent)) {
          //如果在企业微信中
          this.isPcWeixin = false;
        }
      }
      // alert("请升级当前微信版本")
    },

    handleError(e) {
      console.log("error1", e, e.detail);
      // 跳到下载download.html页面
    },

    handleLaunch(e) {
      console.log("success", e, e.detail);
    },

    launchBtnInit() {
      // 获取 homelist 组件传递过来的打开app的 显示状态
      var btn = document.getElementById("launch-btn");
      if (!btn) {
        return;
      }

      const that = this;

      const url = that.extinfo;
      btn.addEventListener("launch", function (e) {
        console.log("success", e, e.detail);
      });
      btn.addEventListener("error", function (e) {
        console.log("error", e, e.detail);
        that.copyToClipboard(url);
        if (e.isTrusted == false) {
          console.log("跳转失败");
          window.location.href = "https://a.app.qq.com/o/simple.jsp?pkgname=com.oda_cad";
          // window.location.href = "https://www.olcad.com/m/index.html"
        }
        console.log("error:" + JSON.stringify(e.detail));
      });

      setTimeout(() => {
        btn.click();
      }, 1000);
    },

    wechatInit() {
      openWxmini({ url: window.location.href })
        .then((res) => {
          let data = JSON.parse(res.data);
          console.log(data);
          if (data.code == 1) {
            this.wechatConfig(data.data);
          } else {
            console.log("获取微信签名失败");
          }
        })
        .catch((_error) => {
          alert("获取配置信息失败");
        });
    },

    wechatConfig(config) {
      let that = this;
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: "wx85eb13de4bad364b", // 必填，公众号的唯一标识
        timestamp: config.timestamp, // 必填，生成签名的时间戳
        nonceStr: config.noncestr, // 必填，生成签名的随机串
        signature: config.signature, // 必填，签名，见附录1
        jsApiList: ["checkJsApi, onMenuShareAppMessage", "onMenuShareTimeline"], // 必填，需要使用的JS接口列表，所有JS接口列表见附录2
        openTagList: ["wx-open-launch-app"], // 获取开放标签权限
      });
      wx.error(function (err) {
        console.log("错误：", err);
        console.log(123);
      });
      wx.ready(function () {
        console.log("微信签名成功");
        that.wechatState = true;
        setTimeout(() => {
          that.launchBtnInit();
        }, 1000);

        // var share = {
        //   title: "title",
        //   desc: "aaaaa",
        //   link: window.location.href,
        //   imgUrl: "",
        // };
        // wx.onMenuShareAppMessage(share);
        // wx.onMenuShareTimeline(share);
      });
    },

    get_team_work_info() {
      search_dwg_info({ sync_id: this.sync_id }).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          this.sync_filename = utils.getFileName(data.data.name);
          this.unit = utils.getExtensiona(data.data.name);
          this.share_name = data.data.user_name;
          this.is_success = true;
          this.isLoading = false;
          this.fromC = data.data.type ? data.data.type : "draw";
          document.title = this.fromC == "draw" ? "CAD迷你画图" : "CAD迷你看图";
          this.handleWindow();
          var param = {
            sync_id: this.sync_id,
            share_name: this.share_name,
            sync_filename: this.sync_filename + this.unit,
          };

          param = btoa(encodeURIComponent(JSON.stringify(param)));
          this.param = param;
          let data1 = `https://app.pcw365.com/app/download?param=` + param;
          this.extinfo = JSON.stringify(data1);
        } else {
          this.is_success = false;
          this.sync_filename = "";
          this.share_name = "XXX";
          this.isLoading = false;
        }
      });
    },

    handleWindow() {
      if (window.innerWidth > 475) {
        document.title = this.fromC == "draw" ? "CAD迷你画图" : "CAD迷你看图";
        this.device = "pc";
      } else {
        this.device = "phone";
        document.title = "CAD迷你看图";
      }
    },

    unbindDocumentKeydown() {
      window.onresize = null;
    },

    startTime() {
      if (this.down_timer) {
        //计时器有值，返回
        return;
      }
      var that = this;
      this.down_timer = setInterval(function () {
        that.down_duration--;
        if (that.down_duration === 0) {
          clearInterval(that.down_timer);
          that.down_timer = null;
          that.down_frequency = 0;
          that.down_duration = 60;
        }
      }, 100);
    },

    //打开客户端
    open_client_btn() {
      if (this.isMobile()) {
        this.open_app_btn();
        return;
      }
      this.copyToClipboard(window.location.href);
      if (this.isPcWeixin) {
        this.isWeChat = true;
        return;
      }

      this.send_tj(1);
      this.sync_tjFun("click");

      if (this.down_frequency === 0) {
        //第一次点击开始计时
        this.startTime();
      }
      this.down_frequency++;
      if (this.down_frequency >= 3) {
        this.open();
        return;
      }

      if (this.down_duration === 0) {
        //倒计时为0
        clearInterval(this.down_timer); //清除计时器
        this.down_timer = null;
        this.down_frequency = 0;
        this.down_duration = 60;
        return;
      }

      this.send_kehuduan();
    },

    send_kehuduan() {
      // var param = {
      //   data: window.location.href, // 分享完整url
      // };

      // param = btoa(JSON.stringify(param));
      // var Jump_to_client_url = "dwgdraw://param=" + param;
      var Jump_to_client_url = "dwgdrawstart://param=" + window.location.href;
      // console.log(Jump_to_client_url)`

      this.$refs.isFocus1.focus();
      window.location.href = Jump_to_client_url;

      this.input_timer = setTimeout(() => {
        console.log("检测到，未安装客户端");
        this.is_install = false;
        this.open();
      }, 1000);
    },

    //检测打开客户端时候失去焦点
    animateInput(type) {
      if (type == "blur") {
        console.log("检测到：已安装了客户端");
        this.is_install = true;
        clearTimeout(this.input_timer);
      }
    },

    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },

    open() {
      setTimeout(() => {
        this.is_install = false;
      }, 200);
      let text = "此操作将下载迷你CAD画图客户端, 是否继续?";
      if (this.fromC == "draw") {
        text = "此操作将下载CAD画图客户端, 是否继续?";
      } else {
        text = "此操作将下载CAD看图客户端, 是否继续?";
      }
      this.$confirm(text, "提示", {
        confirmButtonText: "下载",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          // window.open("https://down.appol.com/softwares/MiniCADDraw_X64.exe", "__black");
          this.down_client_down_btn();
        })
        .catch(() => {});
    },

    //下载客户端
    down_client_down_btn() {
      this.send_tj(4);
      this.sync_tjFun("down");
      var agent = navigator.userAgent.toLowerCase();
      var curWindosVer = "32";
      var isMac = (function () {
        return /macintosh|mac os x/i.test(navigator.userAgent);
      })();
      if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
        curWindosVer = "32";
      } else if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
        curWindosVer = "64";
      }
      console.log(curWindosVer);
      if (this.fromC == "draw") {
        if (curWindosVer == "32") {
          window.open("https://down.appol.com/softwares/MiniCADDraw.exe", "__black");
        } else {
          window.open("https://down.appol.com/softwares/MiniCADDraw_X64.exe", "__black");
        }
      } else {
        if (curWindosVer == "32") {
          window.open("https://down.appol.com/softwares/MiniCADSee.exe", "__black");
        } else {
          window.open("https://down.appol.com/softwares/MiniCADSee_X64.exe", "__black");
        }
      }
    },

    //手机端打开app
    open_app_btn() {
      // 判断是那种设备
      var ua = navigator.userAgent.toLowerCase();
      var isWeixin = ua.indexOf("micromessenger") != -1;
      let u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; // Android系统或者uc浏览器
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // iOS系统
      var isQQ = ua.match(/QQ\/[0-9]/i);

      var param = {
        sync_id: this.sync_id,
        share_name: this.share_name,
        sync_filename: this.sync_filename + this.unit,
      };

      this.send_tj(8);
      var that = this;
      param = btoa(encodeURIComponent(JSON.stringify(param)));
      var url = "https://app.pcw365.com/app/download" + "?param=" + param;
      that.copyToClipboard(url);
      if (navigator.userAgent.match(/(iPhone|iPod|iPad);?/i)) {
        window.location.href = "https://app.pcw365.com/app/download" + "?param=" + param; //schema链接或者universal link
      } else if (navigator.userAgent.match(/android/i)) {
        if (isWeixin || isQQ) {
          // this.is_show_go_web_browser = true
          this.isWeChat = true;
          console.log("在微信或QQ", isWeixin, isQQ);
        } else {
          console.log("在游览器");
          try {
            window.location = "mncad://app.pcw365.com/app/download" + "?param=" + param; //schema链接或者universal link
            window.setTimeout(function () {
              // window.location = "http://down.appol.com/softwares/CADView.apk" + "?param=" + param; //android下载地址
              window.location = "http://ft.appol.com/down/CADView.apk" + "?param=" + param; //android下载地址
            }, 1000);
          } catch (e) {}
        }
      }
    },

    copyToClipboard(url) {
      var cinput = document.createElement("textarea"); // 动态创建textarea元素
      cinput.value = url; // 把需要复制的内容赋值到textarea的vlaue上
      cinput.setAttribute("readOnly", "true");
      //注意大小写，不写的话手机端会自动弹出输入框

      document.body.appendChild(cinput); // 把创建的textarea元素添加进body元素内
      cinput.select(); // 选择对象
      document.execCommand("Copy"); // 执行浏览器复制命令
      cinput.className = "oInput";
      cinput.style.display = "none";
      cinput.style.opacity = "0";
      console.log("复制成功");
    },
    weChatHideMask() {
      this.isWeChat = false;
    },

    send_tj(num) {
      let send = {
        type: "online",
        action: num, //网页的邀请页面手机点击次数
        num: 1,
      };
      try {
        quickiscussiontj(send).then((res) => {
          // console.log(res);
        });
      } catch {
        console.log();
      }
    },

    sync_tjFun(type) {
      let send = {
        type: type, //  ("open", "click" 或 "down")
        sync_id: this.sync_id,
      };

      try {
        sync_tj(send).then((res) => {
          // console.log(res);
        });
      } catch {
        console.log();
      }
    },
  },
};
</script>

<style lang="less" scoped>
.invite-page {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  min-width: 375px;
  background-color: #f8f9ff;
  #is_down_client_input {
    position: fixed;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    opacity: 0.1;
  }
  .pc {
    height: 100%;
    background-color: #fff;
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: absolute;
      left: 30px;
      top: 16px;
      .left,
      .right {
        display: inline-flex;
        align-items: center;
        gap: 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        svg {
          display: flex;
          width: 36px;
          height: 36px;
          justify-content: center;
          align-items: center;
        }
        .img {
          display: flex;
          min-width: 36px;
          min-height: 36px;
          justify-content: center;
          align-items: center;
          border-radius: 100px;
          border: 1px solid #b3ddfd;
          background: linear-gradient(0deg, #b3ddfd 0%, #b3ddfd 100%), #a0d5fd;
          overflow: hidden;
        }
        span {
          color: #000;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          line-height: normal;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .left {
        flex: 1;
      }
    }

    .line {
      width: 100%;
      height: 1px;
      background: #e8e8e9;
    }

    .main {
      height: 100%;
      .box {
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        .flex {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 350px;
          text-align: center;
          .text2 {
            margin-left: 8px;
            display: flex;
          }
          .name {
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
          }
          .unit {
            width: 30px;
          }
          img {
            width: 36px;
            height: 36px;
            flex-shrink: 0;
          }
        }

        .text {
          color: #000;
          text-align: center;
          font-size: 20px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .text1 {
          font-size: 24px;
          font-weight: 700;
        }

        .text1 {
          width: 100%;
          span {
            line-height: 20px;
          }
          div {
            display: inline-block;
            overflow: hidden;
          }
          .share_name {
            max-width: 300px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            line-height: normal;
          }
        }

        .text2 {
          font-size: 20px;
          font-weight: 700;
        }

        .text3 {
          color: #666;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding-top: 10px;
          padding-bottom: 36px;
        }

        .line566 {
          width: 566px;
          height: 1px;
          margin: 10px auto;
          // background: #dcdedf;
          background: linear-gradient(
            to left,
            transparent 0%,
            transparent 50%,
            #ccc 50%,
            #ccc 100%
          );
          background-size: 5px 1px;
          background-repeat: repeat-x;
        }

        .btn {
          display: flex;
          padding: 12px 28px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 6px;
          border: 1px solid #ff9603;
          box-sizing: border-box;
          background: #ff9603;
          color: #fff;
          text-align: center;
          font-family: Source Han Sans CN;
          font-size: 20px;
          font-style: normal;
          line-height: normal;
          cursor: pointer;
        }
        .btn:hover {
          opacity: 0.8;
        }
      }

      .box_center {
        justify-content: center !important;
      }

      .head_bg {
        width: 100%;
        height: 420px;
        flex-shrink: 0;
        background: linear-gradient(94deg, #ff9604 1.85%, #fc8730 94.64%);
        background-image: url("@/assets/images/sync/headbg.png");
        background-size: cover;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        span {
          color: #fff;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
        }
        .cardbox {
          padding: 15px;
          background-color: rgba(255, 255, 255, 0.2);
          border-radius: 20px;
          .card {
            display: flex;
            // width: 566px;
            padding: 50px 64px;
            flex-direction: column;
            align-items: center;
            gap: 10px;
            border-radius: 16px;
            background: #fff;
            box-shadow: 0px 10px 20px 0px rgba(39, 27, 15, 0.05);
            border-radius: 16px;
            .text1 {
              .share_name {
                max-width: 137px;
              }
            }
            .flex {
              .text2 {
                line-height: 26px;
              }
              .name {
                color: #000;
                font-size: 20px;
              }

              .unit {
                color: #000;
                font-size: 20px;
              }
            }

            .text3 {
              padding: 0;
            }
          }
        }
      }

      .gifbox {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        img {
          border-radius: 16px;
          border: 4px solid #fff;
          box-shadow: 0px 10px 20px 0px rgba(15, 19, 39, 0.1);
        }
      }
    }
  }

  .phone {
    height: 100%;
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      .left,
      .right {
        display: inline-flex;
        align-items: center;
        gap: 8px;
        img {
          display: flex;
          width: 20px;
          justify-content: center;
          align-items: center;
        }
        .text,
        span {
          color: #000;
          text-align: center;
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        span {
          display: inline-block;
          height: 20px;
          max-width: 105px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 26px;
        }
      }

      .left {
        padding: 22px 0 0px 15px;
      }
    }
    .main {
      height: 100%;
      background: #f8f9ff;
      .box {
        display: flex;
        width: calc(100% - 60px);
        height: 100%;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        margin: 0 auto;
        img {
          width: 36px;
          height: 36px;
          flex-shrink: 0;
        }
        .text1 {
          width: 100%;
          font-weight: 600 !important;
          margin-top: 30px;
          span {
            line-height: 20px;
          }
          div {
            display: inline-block;
            overflow: hidden;
          }
          .share_name {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            line-height: normal;
          }
        }
        .flex {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 14px;
          width: 315px;
          padding: 8px 12px;
          gap: 4px;
          text-align: center;
          border-radius: 8px;
          border: 1px solid #e6e6e9;
          .name {
            max-width: 240px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
          }
          .unit {
            height: 20px;
            line-height: 22px;
            width: 30px;
          }
          img {
            width: 36px;
            height: 36px;
            flex-shrink: 0;
          }
        }

        .text {
          color: #000;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .text2 {
          font-size: 14px;
          display: flex;
          align-items: center;
          color: #3a3d44;
          font-weight: 500;
        }

        .text3 {
          width: 100%;
          color: #666;
          font-size: 12px;
          text-align: left;
        }

        .text4 {
          color: #939393;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: left;
          padding-top: 10px;
        }

        .btn {
          display: flex;
          width: 100%;
          height: 45px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 6px;
          background: #ff9603;
          color: #fff;
          text-align: center;
          font-family: Source Han Sans CN;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          cursor: pointer;
        }
        .btn:hover {
          opacity: 0.8;
        }

        .footer_text {
          padding: 22px 0;
          color: #9da0a3;
          text-align: center;
          /* 客户端-说明文字 */
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px; /* 116.667% */
        }
      }
    }
  }
  .error {
    width: 450px;
    height: 336px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 10px 20px 0px rgba(86, 88, 89, 0.1);
    margin: 0 auto;
    .error_flex {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      height: 100%;
      justify-content: center;
      img {
        width: 90px !important;
        height: 90px !important;
      }
      .text1 {
        font-weight: 500 !important;
        font-size: 16px !important;
        text-align: center;
      }
      .text2 {
        padding: 0 !important;
        color: #999 !important;
        font-weight: 400 !important;
        font-size: 14px !important;
      }
    }
  }

  .phone {
    background-color: #f8f9ff;
    .main .error_box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      margin: 0;
      margin-top: -98px;
      .error {
        width: 315px;
        height: 336px;
      }
    }

    .wechat_open_tip {
      position: fixed;
      top: 0;
      z-index: 9999;
      width: 100%;
      height: 100%;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1fa1eb;
      line-height: 22px;
      background-color: rgba(0, 0, 0, 0.7);

      & > img {
        position: absolute;
        right: 10px;
        width: 38px;
        height: 94px;
      }

      .steps_box {
        margin-top: 25%;
        margin-left: 25%;
        .step {
          display: flex;
          align-items: center;

          .step_icon {
            width: 24px;
            height: 24px;
          }

          .txt_one {
            margin: 0 6px;
          }
        }

        .one_step {
          .edit_icon {
            width: 30px;
            height: 30px;
          }

          .txt_two {
            margin-left: 3px;
          }
        }

        .two_step {
          margin-top: 10px;

          .browser_text_img {
            width: 132px;
            height: 30px;
          }
        }
      }
    }
  }

  .pc {
    .wechat_open_tip {
      position: fixed;
      top: 0;
      z-index: 9999;
      width: 100%;
      height: 100%;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1fa1eb;
      line-height: 22px;
      background-color: rgba(0, 0, 0, 0.7);

      & > img {
        position: absolute;
        right: 100px;
        width: 84px;
        height: 144px;
      }

      .steps_box {
        margin-top: 20%;
        margin-left: 28%;

        .step {
          display: flex;
          align-items: center;

          .step_icon {
            width: 24px;
            height: 24px;
          }

          .txt_one {
            margin: 0 6px;
          }
        }

        .one_step {
          .edit_icon {
            width: 30px;
            height: 30px;
          }

          .txt_two {
            margin-left: 3px;
          }
        }

        .two_step {
          margin-top: 10px;

          .browser_text_img {
            width: 132px;
            height: 30px;
          }
        }
      }
    }
  }
}
.isPcWeixin {
  .pc,
  .phone {
    .wechat_open_tip {
      & > img {
        position: absolute;
        right: 135px;
        width: 84px;
        height: 144px;
      }
    }
    .txt_one {
      color: #fff;
      font-family: Source Han Sans CN;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
    .two_step {
      margin-top: 10px;
      .browser_text_img {
        width: 162px !important;
        height: 36px !important;
      }
    }
  }
  .pc .wechat_open_tip {
    .steps_box {
      margin-top: 0;
      margin-left: 0;
      position: absolute;
      top: 155px;
      right: 200px;
    }
  }

  .phone .wechat_open_tip {
    .steps_box {
      margin-top: 0;
      margin-left: 0;
      position: absolute;
      top: 155px;
      right: 200px;
    }
  }
}

.logo_footer {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  .flex1 {
    display: flex;
    align-items: center;
    gap: 6px;
    img {
      width: 28px;
      height: 28px;
    }
    span {
      color: #3a3d44;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
.btn-group {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 0 auto;
  width: 320px;
  height: 44px;
  position: relative;
  .search {
    color: #2b303c;
  }
}

#launch-btn {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
