<template>
  <div class="invite-page" v-loading="isLoading">
    <div class="phone">
      <div class="main">
        <div class="box" v-if="is_success">
          <div class="text text1">
            <div>「</div>
            <div class="share_name">{{ share_name }}</div>
            <div>」</div>
            <div>邀请你打开图纸、一起多人协同CAD</div>
          </div>
          <div class="flex">
            <img src="@/assets/images/sync/sync_file.png" />
            <div class="text text2">
              <span class="name" v-if="is_success">{{ sync_filename }}</span>
              <span class="unit" v-if="is_success">{{ unit }}</span>
            </div>
          </div>

          <div class="qrcodebox">
            <div class="qrcode_box">
              <div class="qrcode_after"></div>
              <div class="qrcode" ref="dwgwkqrcode" v-loading="qrcodeLoading"></div>
              <div class="wx_logo_items">
                <div class="wx_logo_item">
                  <div class="circle">1</div>
                  <div class="texts1">长按识别二维码</div>
                </div>
                <div class="wx_logo_item">
                  <div class="circle">2</div>
                  <div class="texts1">马上一起多人协同CAD</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="box error_box" v-if="!is_success">
          <div class="error">
            <div class="error_flex">
              <img src="@/assets/images/sync/error.png" alt="" />
              <div class="text1">图纸链接无效</div>
              <div class="text2">请检查链接完整性，然后重试</div>
            </div>
          </div>
        </div>
      </div>

      <div class="logo_footer">
        <div class="flex">
          <img src="@/assets/images/sync/logo-phone.png" alt="" />
          <span>CAD迷你看图</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { search_dwg_info } from "@/api/team_work";
import utils from "@/utils/utils";
import QRCode from "qrcodejs2";

export default {
  name: "TeamTransit",
  data() {
    return {
      is_install: false,
      is_install_text: "一起讨论图纸",
      is_success: true,
      input_timer: null,
      sync_filename: "", //分享 协同文件名称
      unit: "", //单位
      sync_id: "", //分享 协同ID
      share_name: "", // 来自谁的分享
      isLoading: true,
      extinfo: "",
      param: {},
      qrCodeSrc: "",
      qrcodeLoading: true,
    };
  },

  mounted() {
    let that = this;
    that.sync_id = utils.getQueryVariable("sync_id");
    // 接下来即可照常使用 `console` 等方法
    that.judgePhoneType();
    that.get_team_work_info();
    document.title = "CAD迷你看图";
  },

  created() {},

  methods: {
    get_team_work_info() {
      search_dwg_info({ sync_id: this.sync_id }).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          this.sync_filename = utils.getFileName(data.data.name);
          this.unit = utils.getExtensiona(data.data.name);
          this.share_name = data.data.user_name;
          this.is_success = true;
          this.isLoading = false;
          var param = {
            sync_id: this.sync_id,
            share_name: this.share_name,
            sync_filename: this.sync_filename + this.unit,
          };
          this.qrCodeSrc = "https://52cad.com/sync?sync_id=" + this.sync_id;
          this.creatQrCode(this.qrCodeSrc);
          param = btoa(encodeURIComponent(JSON.stringify(param)));
          this.param = param;
          let data1 = `https://app.pcw365.com/app/download?param=` + param;
          this.extinfo = JSON.stringify(data1);
        } else {
          this.is_success = false;
          this.sync_filename = "";
          this.share_name = "XXX";
          this.isLoading = false;
        }
      });
    },

    creatQrCode(url) {
      var that = this;

      try {
        that.$refs.dwgwkqrcode.innerHTML = "";
        setTimeout(() => {
          let qrcode = new QRCode(that.$refs.dwgwkqrcode, {
            text: url,
            width: 140,
            height: 140,
            colorDark: "#000",
            colorLight: "#fff",
          });
          let canvas = document.getElementsByTagName("canvas")[0];
          let img = that.convertCanvasToImage(canvas);
          that.$refs.dwgwkqrcode.innerHTML = "";
          that.$refs.dwgwkqrcode.append(img);
          that.qrcodeLoading = false;

          //   that.$refs.dwgwkqrcode.innerHTML = "";
          //  let qrcode11 = new QRCode(that.$refs.dwgwkqrcode, {
          //     // text 需要转二维码的内容 可以是文本也可以是一个链接 是链接会直接跳走
          //     text: url,
          //     width: 160,
          //     height: 160,
          //     colorDark: "#333", //二维码颜色
          //     colorLight: "#fff", //二维码背景颜色
          //     correctLevel: QRCode.CorrectLevel.H, //容错率,L/M/H
          //   });
          //   let canvas = document.getElementsByTagName('canvas')[0];
          //   let img = this.convertCanvasToImage(canvas);
          //   this.qrcodeLoading = false;
        }, 200);
      } catch (error) {
        //
      }
    },

    // 从 canvas 提取图片 image
    convertCanvasToImage(canvas) {
      let image = new Image();
      image.src = canvas.toDataURL("image/png");
      return image;
    },

    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },

    judgePhoneType() {
      var u = navigator.userAgent;
      var isWeixin = u.toLowerCase().indexOf("micromessenger") !== -1; // 微信内
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Linux") > -1; //android终端
      var isIOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
      console.log(isWeixin, isAndroid, isIOS);

      if (utils.getQueryVariable("from") == "app") {
        this.$router.push({ path: "/sync", query: { sync_id: utils.getQueryVariable("sync_id") } });
        return;
      }

      if (isIOS) {
        //是IOS就直接跳转
        this.$router.push({ path: "/sync", query: { sync_id: utils.getQueryVariable("sync_id") } });
        return;
      }

      if (this.isMobile()) {
        //
        console.log("在手机");
      } else {
        console.log("在浏览器");
        this.$router.push({ path: "/sync", query: { sync_id: utils.getQueryVariable("sync_id") } });
        return;
      }

      if (isWeixin) {
        //在微信
        console.log(isAndroid, "安卓在微信");
      } else {
        // 在浏览器
        console.log("在游览器");
        this.$router.push({ path: "/sync", query: { sync_id: utils.getQueryVariable("sync_id") } });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.invite-page {
  width: 100%;
  height: 100%;
  flex-shrink: 0;
  min-width: 375px;
  background-color: #f8f9ff;
  #is_down_client_input {
    position: fixed;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    opacity: 0.1;
  }

  .phone {
    height: 100%;
    .head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 15px;
      .left,
      .right {
        display: inline-flex;
        align-items: center;
        gap: 8px;
        img {
          display: flex;
          width: 20px;
          justify-content: center;
          align-items: center;
        }
        .text,
        span {
          color: #000;
          text-align: center;
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        span {
          display: inline-block;
          height: 20px;
          max-width: 105px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 26px;
        }
      }

      .left {
        padding: 22px 0 0px 15px;
      }
    }
    .main {
      height: 100%;
      background: #f8f9ff;
      .box {
        display: flex;
        width: calc(100% - 60px);
        height: 100%;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        margin: 0 auto;

        .qrcode_box {
          display: flex;
          width: 270px;
          height: 320px;
          flex-shrink: 0;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          gap: 25px;
          border-radius: 10px;
          border: 1px solid #f3f3f3;
          background: #fff;
          box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04);
          position: relative;
          .qrcode_after {
            position: absolute;
            top: 35px;
            background-image: url("@/assets/images/sync/qrcode.png");
            background-repeat: no-repeat;
            background-size: cover;
            width: 188px;
            height: 188px;
            flex-shrink: 0;
            border-radius: 4px;
          }
          .qrcode {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            width: 160px;
            height: 160px;
            background: #fff;
            position: relative;
            border-radius: 2px;
            margin-top: 15px;
            img {
              width: 100%;
              height: 100%;
              z-index: 1 !important;
            }

            .qrcode_svg {
              color: #bec3c8;
            }
          }

          .wx_logo_items {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 6px;
            .wx_logo_item {
              display: flex;
              align-items: flex-start;
              gap: 5px;
              height: 24px;
              line-height: 24px;
              .circle {
                width: 16px;
                height: 16px;
                line-height: 16px;
                background-color: #0082f0;
                fill: #0082f0;
                border-radius: 100%;
                color: #fff;
                font-size: 12px;
                text-align: center;
              }
              .texts1 {
                color: #666;
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px; /* 200% */
              }
            }
          }
        }

        img {
          width: 36px;
          height: 36px;
          flex-shrink: 0;
        }
        .text1 {
          width: 100%;
          font-weight: 600 !important;
          margin-top: 30px;
          span {
            line-height: 20px;
          }
          div {
            display: inline-block;
            overflow: hidden;
          }
          .share_name {
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
            line-height: normal;
          }
        }
        .flex {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 14px;
          width: 315px;
          padding: 8px 12px;
          gap: 4px;
          text-align: center;
          border-radius: 8px;
          border: 1px solid #e6e6e9;
          .name {
            max-width: 240px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;
          }
          .unit {
            height: 20px;
            line-height: 22px;
            width: 30px;
          }
          img {
            width: 36px;
            height: 36px;
            flex-shrink: 0;
          }
        }

        .text {
          color: #000;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }

        .text2 {
          font-size: 14px;
          display: flex;
          align-items: center;
          color: #3a3d44;
          font-weight: 500;
        }

        .text3 {
          width: 100%;
          color: #666;
          font-size: 12px;
          text-align: left;
        }

        .text4 {
          color: #939393;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-align: left;
          padding-top: 10px;
        }

        .btn {
          display: flex;
          width: 100%;
          height: 45px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 6px;
          background: #ff9603;
          color: #fff;
          text-align: center;
          font-family: Source Han Sans CN;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          cursor: pointer;
        }
        .btn:hover {
          opacity: 0.8;
        }

        .footer_text {
          padding: 22px 0;
          color: #9da0a3;
          text-align: center;
          /* 客户端-说明文字 */
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 14px; /* 116.667% */
        }
      }
    }
  }
  .error {
    width: 450px;
    height: 336px;
    border-radius: 8px;
    background: #fff;
    box-shadow: 0px 10px 20px 0px rgba(86, 88, 89, 0.1);
    margin: 0 auto;
    .error_flex {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      height: 100%;
      justify-content: center;
      img {
        width: 90px !important;
        height: 90px !important;
      }
      .text1 {
        font-weight: 500 !important;
        font-size: 16px !important;
        text-align: center;
      }
      .text2 {
        padding: 0 !important;
        color: #999 !important;
        font-weight: 400 !important;
        font-size: 14px !important;
      }
    }
  }

  .phone {
    .main .error_box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      margin: 0;
      margin-top: -98px;
      .error {
        width: 315px;
        height: 336px;
      }
    }

    .wechat_open_tip {
      position: fixed;
      top: 0;
      z-index: 9999;
      width: 100%;
      height: 100%;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #1fa1eb;
      line-height: 22px;
      background-color: rgba(0, 0, 0, 0.7);

      & > img {
        position: absolute;
        right: 10px;
        width: 38px;
        height: 94px;
      }

      .steps_box {
        margin-top: 20%;
        margin-left: 28%;

        .step {
          display: flex;
          align-items: center;

          .step_icon {
            width: 24px;
            height: 24px;
          }

          .txt_one {
            margin: 0 6px;
          }
        }

        .one_step {
          .edit_icon {
            width: 30px;
            height: 30px;
          }

          .txt_two {
            margin-left: 3px;
          }
        }

        .two_step {
          margin-top: 10px;

          .browser_text_img {
            width: 132px;
            height: 30px;
          }
        }
      }
    }
  }
}

.logo_footer {
  position: fixed;
  bottom: 57px;
  left: 50%;
  transform: translateX(-50%);
  .flex {
    display: flex;
    align-items: center;
    gap: 6px;
    img {
      width: 28px;
      height: 28px;
    }
    span {
      color: #3a3d44;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
</style>
