<template>
  <div class="user_center_main">
    <HeaderView ref="HeaderMethods_ref"></HeaderView>
    <PayView v-show="$store.state.is_show_pay"></PayView>
    <MaskLayer></MaskLayer>
    <WxbindGetCodeView></WxbindGetCodeView>
    <WxQcodeView></WxQcodeView>
    <Forcebindwx></Forcebindwx>
    <div class="user_center_box">
      <!-- 左边 -->
      <div class="user_center_left">
        <div class="user_left_top">
          <div class="user_left_top_ava">
            <img
              class="user_center_avatar"
              :src="is_wx == 0 ? avatar : wx_avatar"
            />
          </div>
          <div class="user_left_top_name" v-if="is_wx == 0">
            {{ user_name == "" ? account : user_name }}
          </div>

          <div class="user_left_top_name" v-if="is_wx == 1">
            {{ wx_nickname }}
          </div>
        </div>
        <div class="user_left_tab_items">
          <div
            class="user_left_tab_item"
            id="my_center"
            data-type="my_center"
            :class="left_cur_active == 1 ? 'user_left_tab_active' : ''"
            @click="set_left_cur_active(1)"
          >
            我的账户
          </div>
          <div
            class="user_left_tab_item"
            id="my_vip"
            data-type="my_vip"
            :class="left_cur_active == 2 ? 'user_left_tab_active' : ''"
            @click="set_left_cur_active(2)"
          >
            我的购买
          </div>
        </div>
      </div>

      <!-- 右边 -->
      <div class="user_center_right">
        <!-- 我的账户 -->
        <div
          class="user_center_right_my_center"
          id="my_center_box"
          v-if="left_cur_active == 1"
        >
          <div class="my_center_top">
            <div class="my_center_top_tabs" id="my_center_tabs">
              <div
                class="my_center_top_tab"
                :class="right_cur_active == 1 ? 'my_center_top_tab_active' : ''"
                @click="set_right_cur_active(1)"
              >
                个人资料
              </div>
              <div
                class="my_center_top_tab"
                :class="right_cur_active == 2 ? 'my_center_top_tab_active' : ''"
                @click="set_right_cur_active(2)"
              >
                修改密码
              </div>
              <div
                class="my_center_top_tab"
                :class="right_cur_active == 3 ? 'my_center_top_tab_active' : ''"
                @click="set_right_cur_active(3)"
              >
                绑定微信
              </div>
              <div
                class="my_center_top_tab"
                :class="right_cur_active == 4 ? 'my_center_top_tab_active' : ''"
                @click="set_right_cur_active(4)"
              >
                注销账号
              </div>
            </div>
          </div>
          <div class="my_center_main">
            <!-- 个人资料 -->
            <div
              class="my_center_userinfo_box"
              v-if="right_cur_active == 1 && left_cur_active == 1"
            >
              <div class="my_center_userinfo">
                <div class="my_center_userinfo_item">
                  <div class="my_center_userinfo_left">账号：</div>
                  <div class="my_center_userinfo_right" id="userinfor_account">
                    {{ account }}
                  </div>
                </div>
                <div class="my_center_userinfo_item">
                  <div class="my_center_userinfo_left">昵称：</div>
                  <div class="my_center_userinfo_right">
                    <input
                      type="text"
                      id="input_user_name"
                      name="usercenter--name"
                      maxlength="8"
                      autocomplete="off"
                      placeholder="请输入您的昵称"
                      v-model="user_name_input"
                    />
                    <span>建议使用真实姓名，以便工作伙伴找到你～</span>
                  </div>
                </div>
                <div class="my_center_userinfo_item">
                  <div class="my_center_userinfo_left">行业岗位：</div>
                  <div class="my_center_userinfo_right">
                    <input
                      type="text"
                      id="input_user_job"
                      name="user_job"
                      maxlength="12"
                      autocomplete="off"
                      placeholder="请输入你的行业岗位"
                      v-model="work_name_input"
                    />
                  </div>
                </div>
                <div class="my_center_userinfo_item">
                  <div class="my_center_userinfo_left"></div>
                  <div class="my_center_userinfo_right">
                    <div
                      id="usercenter_btn"
                      class="usercenter_btn_show"
                      @click="update_user_btn"
                    >
                      确定
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 修改密码 -->
            <div
              class="my_center_updatepwd_box"
              v-if="right_cur_active == 2 && left_cur_active == 1"
            >
              <div class="my_center_updatepwd">
                <div class="my_center_updatepwd_item">
                  <div class="my_center_updatepwd_left">账号</div>
                  <div class="my_center_updatepwd_right">
                    <el-input
                      id="update_input_pwd1"
                      v-model="account"
                      :readonly="true"
                    ></el-input>
                  </div>
                </div>
                <div class="my_center_updatepwd_item">
                  <div class="my_center_updatepwd_left">手机号码验证</div>
                  <div
                    class="my_center_updatepwd_right"
                    style="position: relative"
                  >
                    <el-input
                      class="update_input_pwd2"
                      type="text"
                      placeholder="请收到的验证码"
                      v-model="reset_pwd_code"
                    ></el-input>

                    <span
                      id="update_get_code"
                      data-can-click="yes"
                      @click="reset_pwd_get_code"
                      v-show="getphonecode_btn_show"
                      >点击获取验证码</span
                    >

                    <span
                      id="update_get_code"
                      style="color: #999"
                      v-show="!getphonecode_btn_show"
                      >{{ getphonecode_conut }}s后重新获取</span
                    >

                    <span class="err_msg code_err_msg"></span>
                  </div>
                </div>
                <div class="my_center_updatepwd_item">
                  <div class="my_center_updatepwd_left">新密码</div>
                  <div
                    class="my_center_updatepwd_right"
                    style="position: relative"
                  >
                    <el-input
                      type="text"
                      class="update_input_pwd1"
                      auto-complete="new-password"
                      placeholder="请输入密码"
                      v-model="new_pwd1"
                      maxlength="16"
                      minlength="6"
                    ></el-input>

                    <span class="err_msg password_err_msg"></span>
                  </div>
                </div>
                <div class="my_center_updatepwd_item">
                  <div class="my_center_updatepwd_left">确认密码</div>
                  <div
                    class="my_center_updatepwd_right"
                    style="position: relative"
                  >
                    <el-input
                      type="text"
                      class="update_input_pwd1"
                      placeholder="请再次输入密码"
                      v-model="new_pwd2"
                      auto-complete="new-password"
                      maxlength="16"
                      minlength="6"
                    ></el-input>
                    <span class="err_msg password2_err_msg"></span>
                  </div>
                </div>
                <div class="my_center_updatepwd_item">
                  <div class="my_center_updatepwd_left"></div>
                  <div class="my_center_updatepwd_right">
                    <div
                      id="updatepwd_btn"
                      class="usercenter_btn_show"
                      @click="reset_pwd_sumbit"
                    >
                      提交
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- 微信绑定 -->
            <div
              class="my_center_main_item_box my_center_wxbind_box"
              v-if="right_cur_active == 3 && left_cur_active == 1"
            >
              <div class="my_center_updatepwd" style="width: 550px">
                <div class="bindwx_title" id="bind_wx_top_text" v-if="wx_id == ''">
                  绑定微信号，可提升账号的安全，可支持微信登录
                </div>
                <div class="bind_wx_yes_box" v-if="wx_id != ''">
                  <div id="bind_wx_name">已绑定微信：<span>{{wx_nickname}}</span></div>
                  <p class="bind_wx_text">
                    每个账号可绑定一个微信号，绑定微信号将作为您身份验证的重要方式，请谨慎操作！
                  </p>
                </div>
                <div id="bind_wx_btn" style="width: 100px" v-if="wx_id == ''" @click="bind_wx_btn('bind')">绑定账号</div>
                <div class="bind_wx_flex" v-if="wx_id != ''">
                  <div id="jb_wx_btn" @click="bind_wx_btn('unbind')">解除绑定</div>
                  <div id="jb_qtwx_btn" @click="bind_wx_btn('bind')">
                    绑定其他微信
                  </div>
                </div>
              </div>
            </div>

            <!-- 注销账号 -->
            <div
              class="my_center_main_item_box my_center_account_cancellation_box"
              v-if="right_cur_active == 4 && left_cur_active == 1"
            >
              <div class="my_center_updatepwd">
                <div class="my_center_updatepwd_item">
                  <div class="my_center_updatepwd_left">账号</div>
                  <div class="my_center_updatepwd_right">
                    <el-input
                      id="update_input_pwd1"
                      v-model="account"
                      :readonly="true"
                    ></el-input>
                  </div>
                </div>
                <div class="my_center_updatepwd_item">
                  <div class="my_center_updatepwd_left">手机号码验证</div>
                  <div
                    class="my_center_updatepwd_right"
                    style="position: relative"
                  >
                    <el-input
                      class="update_input_pwd2"
                      type="text"
                      placeholder="请收到的验证码"
                      v-model="zhuxiao_account_code"
                    ></el-input>

                    <span
                      id="update_get_code"
                      data-can-click="yes"
                      @click="zhuxiao_account_get_code"
                      v-show="zhuxiaocode_btn_show"
                      >点击获取验证码</span
                    >

                    <span
                      id="update_get_code"
                      style="color: #999"
                      v-show="!zhuxiaocode_btn_show"
                      >{{ zhuxiaocode_conut }}s后重新获取</span
                    >

                    <span class="err_msg code_err_msg"></span>
                  </div>
                </div>
              
                <div class="my_center_updatepwd_item">
                  <div class="my_center_updatepwd_left"></div>
                  <div class="my_center_updatepwd_right">
                    <div
                      id="account_cancellation_btn"
                      :class="zhuxiao_account_code == '' ? 'updatepwd_btn_hide' : 'account_cancellation_btn_show'"
                      style="width: 100px"
                      @click="zhuxiao_account_btn"
                    >
                      注销账号
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 我的会员 -->
        <div
          class="user_center_right_my_center"
          id="my_vip_box"
          v-if="left_cur_active == 2"
        >
          <div class="my_center_top">
            <div class="my_center_top_tabs" id="my_vip_tabs">
              <div
                class="my_center_top_tab"
                :class="right_cur_active == 1 ? 'my_center_top_tab_active' : ''"
                @click="set_right_cur_active(1)"
              >
                我的购买
              </div>
              <div
                class="my_center_top_tab"
                :class="right_cur_active == 2 ? 'my_center_top_tab_active' : ''"
                @click="set_right_cur_active(2)"
              >
                购买记录
              </div>
              <!-- <div 
              class="my_center_top_tab" 
              :class="right_cur_active == 3 ? 'my_center_top_tab_active': ''"
                @click="set_right_cur_active(3)"
              >开具发票</div> -->
            </div>
          </div>
          <div class="my_center_main">
            <!-- 会员状态 -->
            <div
              class="my_vip_state_box"
              v-if="
                right_cur_active == 1 &&
                left_cur_active == 2 &&
                !is_show_no_no_no
              "
            >
              <div class="my_vip_state">
                <div class="my_vip_state_img">
                  <img src="@/assets/images/common/my_vip_state.png" alt="" />
                </div>
                <div class="my_vip_state_text" style="margin-top: 10px">
                  {{headerTitle1}}
                </div>
                <div
                  class="my_vip_state_text"
                  style="margin-bottom: 30px; margin-top: 5px"
                >
                  个人版
                </div>

                <div class="my_vip_state_start">
                  开通时间：{{ my_vip_state_start }}
                </div>
                <div class="my_vip_state_end">
                  到期时间：{{ my_vip_state_end }}
                </div>
                <div class="my_vip_state_surplus">
                  剩余时间：{{ my_vip_state_surplus }}
                </div>
                <div
                  id="my_vip_pay_btn"
                  class="margin-top20"
                  @click="is_show_pay_btn1"
                >
                  立即续费
                </div>
              </div>
            </div>
            <!-- 购买记录 -->
            <div
              class="my_vip_order_box"
              style="height: 100%"
              v-if="
                right_cur_active == 2 &&
                left_cur_active == 2 &&
                !is_show_no_no_no
              "
            >
              <div class="my_vip_order" style="height: 100%">
                <table border="0" cellspacing="0" id="order_table">
                  <tr class="tr_th">
                    <th>订单编号</th>
                    <th>开通项目</th>
                    <th>购买日期</th>
                    <th>购买金额</th>
                  </tr>
                  <tr v-for="(item, i) in userOrders" :key="i">
                    <td>{{ item.ordersn }}</td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.insert_time }}</td>
                    <td>￥{{ item.money }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <!-- 开具发票 -->
            <div
              class="my_vip_invoice_box"
              v-if="
                right_cur_active == 3 &&
                left_cur_active == 2 &&
                !is_show_no_no_no
              "
            >
              <div class="my_vip_invoice">
                <table border="0" cellspacing="0" id="invoice_table"></table>
              </div>
            </div>
            <!-- 无记录显示 -->
            <div
              class="my_vip_no_box"
              v-if="left_cur_active == 2 && is_show_no_no_no"
            >
              <div class="my_vip_no">
                <div class="my_vip_no_img">
                  <img src="@/assets/images/common/my_vip_no.png" alt="" />
                </div>
                <div class="my_vip_no_text">亲，暂无购买记录，点击购买吧</div>
                <div id="my_vip_pay_vip_btn" @click="is_show_pay_btn1">
                  我要购买
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderView from "./components/header.vue";
import PayView from "@/components/pay.vue";
import MaskLayer from "@/components/mask.vue";
import WxbindGetCodeView from "./components/wxbind_get_code.vue";
import WxQcodeView from "./components/wxqcode.vue";
import Forcebindwx from "./components/forcebindwx.vue";
import utils from "@/utils/utils";
import Common from "@/utils/common"
import {
  get_user_vipinfo,
  get_user_order,
  // get_user_invoice,
  update_userinfo,
  reset_password_getcode,
  reset_user_password,
  cancel_account_send,
  cancel_account
} from "@/api/user";

export default {
  name: "UserCenterPage",
  components: {
    HeaderView,
    PayView,
    MaskLayer,
    WxbindGetCodeView,
    WxQcodeView,
    Forcebindwx,
  },
  data() {
    return {
      headerTitle1: Common.headerTitle1,
      userinfo: null,
      account: "",
      user_name: "",
      vip_endtime: "",
      avatar: require("@/assets/images/common/uc_avatar.png"),
      is_wx: "",
      is_vip: "",
      qy_vip_endtime: "",
      uid: "",
      wx_id: '',
      wx_avatar: "",
      wx_nickname: "",
      work_name: "",

      work_name_input: "",
      user_name_input: "",

      left_cur_active: 1,
      right_cur_active: 1,
      is_show_no_no_no: false,

      reset_pwd_code: "",
      new_pwd1: "",
      new_pwd2: "",
      getphonecode_timer: null,
      getphonecode_conut: 0,
      getphonecode_btn_show: true,
      my_vip_state_start: "",
      my_vip_state_end: "",
      my_vip_state_surplus: "",
      userOrders: [],


      zhuxiaocode_timer: null,
      zhuxiaocode_conut: 0,
      zhuxiaocode_btn_show: true,
      zhuxiao_account_code: '',
    };
  },

  watch: {},

  mounted() {},

  methods: {
    is_show_pay_btn1() {
      this.$store.state.is_show_pay = true;
      this.$store.state.mask = true;
    },

    getUserinfo(user_info) {
      this.userinfo = user_info;
      this.account = user_info.account;
      this.user_name = user_info.user_name;
      this.vip_endtime = user_info.vip_endtime;
      this.is_wx = user_info.is_wx;
      this.is_vip = user_info.is_vip;
      this.qy_vip_endtime = user_info.qy_vip_endtime;
      this.uid = user_info.uid;
      this.wx_id = user_info.wx_id == '' || user_info.wx_id == null || user_info.wx_id == 0 || user_info.wx_id == '0' ? '' : '2'
      this.wx_avatar = user_info.wx_avatar;
      this.wx_nickname = user_info.wx_nickname;
      this.work_name = user_info.work_name;
      this.user_name_input = user_info.user_name;
      this.work_name_input = user_info.work_name;
    },

    set_left_cur_active(value) {
      this.left_cur_active = value;
      if (value == 2) {
        if (this.right_cur_active == 1) {
          this.getUserVip();
        } else if (this.right_cur_active == 2) {
          this.getUserOrder();
        }
      }
    },

    set_right_cur_active(value) {
      this.right_cur_active = value;
      if (this.left_cur_active == 2) {
        if (value == 1) {
          this.getUserVip();
        } else if (value == 2) {
          this.getUserOrder();
        }
      }
    },

    //修改资料确定
    update_user_btn() {
      if (this.user_name_input == "") {
        this.$message.error("新昵称不能为空");
        return;
      }
      if (this.work_name_input == "") {
        this.$message.error("工作岗位不能为空");
        return;
      }
      var send = {
        nickname: this.user_name_input,
        workname: this.work_name_input,
      };
      update_userinfo(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          var USER_INFO = JSON.parse(localStorage.getItem("USER_INFO"));
          USER_INFO.user_name = this.user_name_input;
          this.user_name = this.user_name_input;
          USER_INFO.work_name = this.work_name_input;
          this.$refs.HeaderMethods_ref.updateUserName(this.user_name_input);
          localStorage.setItem("USER_INFO", JSON.stringify(USER_INFO));
        } else if (data.code == 99) {
          this.$message.error("登录信息已失效，请重新登录");
        }
      });
    },

    //修改密码发送验证码
    reset_pwd_get_code() {
      var send = {
        account: this.account,
      };
      reset_password_getcode(send).then((res) => {
        var data = JSON.parse(res.data);
        console.log(data);
      });
      this.reset_pwd_get_code_count();
    },

    // 验证码倒计时
    reset_pwd_get_code_count() {
      const TIME_COUNT = 60;
      if (!this.getphonecode_timer) {
        this.getphonecode_conut = TIME_COUNT;
        this.getphonecode_btn_show = false;
        this.getphonecode_timer = setInterval(() => {
          if (
            this.getphonecode_conut > 0 &&
            this.getphonecode_conut <= TIME_COUNT
          ) {
            this.getphonecode_conut--;
          } else {
            this.getphonecode_btn_show = true;
            clearInterval(this.getphonecode_timer);
            this.getphonecode_timer = null;
          }
        }, 1000);
      }
    },

    //修改密码确定
    reset_pwd_sumbit() {
      if (this.reset_pwd_code == "") {
        this.$message.error("验证码不能为空");
        return;
      }
      if (this.new_pwd1 == "") {
        this.$message.error("密码不能为空");
        return;
      }
      if (this.new_pwd2 == "") {
        this.$message.error("密码不能为空");
        return;
      }
      if (this.new_pwd1 != this.new_pwd2) {
        this.$message.error("两次输入的密码不一致");
        return;
      }
      var send = {
        account: this.account,
        password: this.new_pwd1,
        code: this.reset_pwd_code,
      };
      reset_user_password(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          this.$message.success("重置密码成功");
          this.new_pwd1 = "";
          this.new_pwd2 = "";
        } else if (data.code == 99) {
          this.$message.error("登录信息已失效，请重新登录");
        } else if (data.code == 2) {
          this.$message.error(data.msg);
        } else if (data.code == 3) {
          this.$message.error(data.msg);
        } else if (data.code == 5) {
          this.$message.error(data.msg);
        } else {
          this.$message.error("修改失败，请稍后再试");
        }
      });
    },

    getUserVip() {
      get_user_vipinfo().then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          this.my_vip_state_start = data.data.start_time;
          this.my_vip_state_end = data.data.end_time;
          this.my_vip_state_surplus = data.data.dur_time;
          this.is_show_no_no_no = false;
        } else if (data.code == 2) {
          this.is_show_no_no_no = true;
        } else if (data.code == 3) {
          this.my_vip_state_start = data.data.start_time;
          this.my_vip_state_end = data.data.end_time;
          this.my_vip_state_surplus = data.data.dur_time;
          this.is_show_no_no_no = false;
        }
      });
    },
    getUserOrder() {
      this.is_show_no_no_no = true
      get_user_order().then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          this.userOrders = data.data;
          if (data.data.length == 0) {
            this.is_show_no_no_no = true;
          } else {
            this.is_show_no_no_no = false;
          }
        } else if (data.code == 2) {
          this.is_show_no_no_no = true;
        }
      });
    },



    bind_wx_btn(action) {
      this.$store.state.is_show_bindwx_get_code_box = true
      this.$store.state.cur_bindwx_action = action
      // if (action == 'bind') { //显示绑定
      
      // } else if (action == 'unbind') { //显示解绑

      // }
    },


    zhuxiao_account_get_code() {
      var send = {
        account: this.account,
      };
      cancel_account_send(send).then((res) => {
        var data = JSON.parse(res.data);
        console.log(data);
      });
      this.zhuxiao_get_code_count();
    },

    zhuxiao_get_code_count() {
      const TIME_COUNT = 60;
      if (!this.zhuxiaocode_timer) {
        this.zhuxiaocode_conut = TIME_COUNT;
        this.zhuxiaocode_btn_show = false;
        this.zhuxiaocode_timer = setInterval(() => {
          if (
            this.zhuxiaocode_conut > 0 &&
            this.zhuxiaocode_conut <= TIME_COUNT
          ) {
            this.zhuxiaocode_conut--;
          } else {
            this.zhuxiaocode_btn_show = true;
            clearInterval(this.zhuxiaocode_timer);
            this.zhuxiaocode_timer = null;
          }
        }, 1000);
      }
    },


    //注销账号显示提示框
    zhuxiao_account_btn() {
      var that = this
      if (that.zhuxiao_account_code == '') { return }
      this.$confirm("注销后，账号信息及云盘资料、图库收藏等文件将会删除 购买的会员也将视为自动放弃，请确定是否继续注销？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "alert_del_btn",
        customClass: "cover_messageBox",
      })
        .then(() => {
          that.$nextTick(()=>{
            that.cancel_account_sumbit()
          })
        })
        .catch(() => {
        });
    },


    cancel_account_sumbit() {
      var send = {
        'account': this.account,
        'code': this.zhuxiao_account_code
      }
      cancel_account(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          alert('注销账号成功，退出界面')
          utils.del_localStorae();
          this.$router.push("/");
        } else if (res.code == 3) {
          this.$msg.whiteerr('用户不存在')
        } else if (res.code == 4) {
          this.$msg.whiteerr('请先发送验证码')
        } else if (res.code == 5) {
          this.$msg.whiteerr('验证码错误')
        } else if (res.code == 6) {
          this.$msg.whiteerr('注销失败')
        } else if (res.code == 99) {
          this.$msg.whiteerr('登录信息已过期，请重新登陆')
          utils.del_localStorae();
          this.$router.push("/");
        }
      }).catch(()=>{
        this.$msg.whiteerr('网络错误，请稍后再尝试')
      })
    },
  },
};
</script>

<style lang="less" scoped>

::-webkit-scrollbar {
  /* 滚动条整体部分 */
  width: 5px;
  margin-right: 2px;
}

::-webkit-scrollbar-button {
  /* 滚动条两端的按钮 */
  width: 10px;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
  margin-bottom: 2px;
}

::-webkit-scrollbar-track {
  /* 外层轨道 */
  border-radius: 5px;
}

::-webkit-scrollbar-track-piece {
  /*内层轨道，滚动条中间部分 */
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  /* 滑块 */
  width: 5px;
  border-radius: 5px;
  background: #cbcbcb;
}

::-webkit-scrollbar-corner {
  /* 边角 */
  width: 5px;
}

::-webkit-scrollbar-thumb:hover {
  /* 鼠标移入滑块 */
  background: #cbcbcb;
}

.user_center_main {
  background-color: #f4f4f4;
  width: 100%;
  height: calc(100%);
  overflow: hidden;
}

.user_center_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.user_center_left {
  width: 262px;
  height: 630px;
  background-color: #ffffff;
  border-radius: 4px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.user_center_right {
  width: 1068px;
  height: 630px;
  background-color: #ffffff;
  border-radius: 4px;
  margin-top: 20px;
  margin-left: 15px;
  overflow: hidden;
}

.user_left_top {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 60px 0;
}

.user_left_top_ava {
  width: 80px;
  height: 80px;
}

.user_left_top_name {
  padding-top: 10px;
  font-family: PingFangSC-Medium;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
  width: 212px;
  text-align: center;
  margin: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.user_left_tab_items {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.user_left_tab_item {
  width: 232px;
  height: 68px;
  line-height: 68px;
  background-color: #fff;
  font-family: PingFangSC-Medium;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
}

.user_left_tab_item:hover {
  background-color: #f0f4fc;
}

.user_left_tab_active {
  background-color: #f0f4fc;
  color: #2072EC;
}

.my_center_top {
  border-bottom: 1px solid #e7e7e7;
  width: 100%;
  height: 68px;
}

.my_center_top_tabs {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.my_center_top_tab {
  width: 160px;
  height: 68px;
  line-height: 68px;
  text-align: center;
  font-family: SourceHanSansSC-Regular, PingFangSC-Medium;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #999999;
  cursor: pointer;
}

.my_center_top_tab_active {
  color: #2072EC;
  position: relative;
}

.my_center_top_tab_active::after {
  content: "";
  position: absolute;
  width: 70px;
  height: 3px;
  background-color: #2072EC;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

#my_center_box {
  display: block;
}

.my_center_userinfo_box {
  position: relative;
}

.my_center_userinfo {
  width: 704px;
  height: 292px;
  position: absolute;
  top: 60px;
  left: 50%;
  transform: translateX(-50%);
}

.my_center_userinfo_item {
  width: 428px;
  height: 48px;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.my_center_userinfo_left {
  width: 80px;
  line-height: 48px;
  text-align: right;
  font-family: SourceHanSansSC-Regular, PingFangSC-Medium;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
}

.my_center_userinfo_right {
  margin-left: 10px;
  position: relative;
}

.my_center_userinfo_right span {
  width: 304px;
  height: 24px;
  font-family: SourceHanSansSC-Regular, PingFangSC-Medium;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #999999;
  padding-left: 10px;
  position: absolute;
  top: 12px;
}

#userinfor_account {
  font-family: SourceHanSansSC-Regular, PingFangSC-Medium;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
  padding-left: 14px;
}

.my_center_userinfo_right input {
  width: 300px;
  height: 48px;
  border-radius: 1px;
  border: solid 1px #dcdcdc;
  text-indent: 14px;
  font-family: SourceHanSansSC-Regular, PingFangSC-Medium;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333;
  outline: none;
}

.my_center_userinfo_right input:focus {
  border: solid 1px #2072EC;
}

.my_center_userinfo_right input::-webkit-input-placeholder {
  color: rgba(153, 153, 153, 1);
}

#usercenter_btn,
#updatepwd_btn,
#usercenter_btn,
#updatepwd_btn,
#account_cancellation_btn,
#bind_wx_btn {
  width: 68px;
  height: 48px;
  background-color: #f0f0f0;
  border-radius: 1px;
  border: solid 1px #dcdcdc;
  font-family: SourceHanSansSC-Regular, PingFangSC-Medium;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #999999;
  text-align: center;
  line-height: 48px;
}

.usercenter_btn_hide,
.updatepwd_btn_hide {
  cursor: not-allowed;
}

.usercenter_btn_show,
.updatepwd_btn_show,
.account_cancellation_btn_show {
  cursor: pointer;
  background-color: #2072EC !important;
  color: #fff !important;
  border: solid 1px #2072EC !important;
}

#bind_wx_btn {
  cursor: pointer;
  background-color: #2072EC !important;
  color: #fff !important;
  border: solid 1px #2072EC !important;
  margin: 30px auto;
}

.user_center_right_my_center {
  position: relative;
  height: 100%;
}

.my_center_updatepwd_box {
  position: relative;
}

.my_center_updatepwd {
  width: 486px;
  height: 360px;
  position: absolute;
  top: 67px;
  left: 50%;
  transform: translateX(-50%);
}

.my_center_updatepwd_item {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  /* width: 485px; */
  height: 48px;
  line-height: 48px;
}

.my_center_updatepwd_left {
  width: 96px;
  line-height: 48px;
  font-family: SourceHanSansSC-Regular, PingFangSC-Medium;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
  text-align: right;
}

.my_center_updatepwd_right {
  margin-left: 20px;
}

/deep/ .my_center_updatepwd_right input,
.my_center_updatepwd_right .update_input_pwd1,
.my_center_updatepwd_right .update_input_pwd2 {
  width: 370px;
  height: 48px;
  border-radius: 1px;
  // border: solid 1px #dcdcdc;
  // text-indent: 14px;
  outline: none;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
}

.my_center_updatepwd_right .update_input_pwd1 {
  text-security: disc;
  -webkit-text-security: disc;
}

.my_center_updatepwd_right input:focus {
  border: solid 1px #2072EC;
}

.my_center_updatepwd_right input::-webkit-input-placeholder {
  color: rgba(153, 153, 153, 1);
}

.my_center_updatepwd_right span {
  position: absolute;
  top: 0;
  right: 0;
  width: 112px;
  padding-right: 12px;
  height: 48px;
  line-height: 48px;
  font-family: SourceHanSansSC-Regular, PingFangSC-Medium;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #2072EC;
  cursor: pointer;
  user-select: none;
  text-align: right;
}

.my_center_updatepwd_right .err_msg {
  position: absolute;
  right: -230px;
  font-size: 14px;
  color: #f04343;
  width: 200px;
  height: 1px;
  text-align: left;
}

.my_vip_no_box {
  position: relative;
}

.my_vip_no {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.my_vip_no_text {
  width: 224px;
  height: 24px;
  font-family: SourceHanSansSC-Regular, PingFangSC-Medium;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #999999;
  margin-bottom: 60px;
  user-select: none;
}

.my_vip_no_img {
  width: 160px;
  height: 160px;
  margin-top: 60px;
}

#my_vip_pay_vip_btn,
#my_vip_pay_btn {
  width: 265px;
  height: 45px;
  line-height: 45px;
  text-align: center;
  background-color: #2072EC;
  border-radius: 4px;
  font-family: SourceHanSansSC-Medium, PingFangSC-Medium;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  cursor: pointer;
  user-select: none;
}

.my_vip_state {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.my_vip_state_img {
  width: 50px;
  height: 50px;
  margin-top: 40px;
}

.my_vip_state_text {
  font-family: SourceHanSansSC-Medium, PingFangSC-Medium;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
}

.my_vip_state_start,
.my_vip_state_end,
.my_vip_state_surplus {
  width: 280px;
  height: 24px;
  padding-left: 10px;
  font-family: SourceHanSansSC-Regular, PingFangSC-Medium;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
  margin-bottom: 20px;
}

.margin-top20 {
  margin-top: 20px;
}

.my_vip_invoice_box {
  margin-top: 40px;
  margin-left: 38px;
}

.my_vip_order {
  width: 972px;
  margin-left: 48px;
  margin-top: 40px;
  padding-bottom: 20px;
}

table tr.tr_th {
  width: 972px;
  height: 50px;
  background-color: #f5f9ff;
  border-radius: 4px;
}

table td,
table th {
  height: 50px;
  text-align: center;
  font-family: SourceHanSansSC-Medium, PingFangSC-Medium;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
  line-height: 50px;
}

table td {
  font-family: SourceHanSansSC-Regular, PingFangSC-Medium;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #666666;
  height: 42px;
  line-height: 42px;
}

#order_table td:nth-child(1) {
  width: 243px;
}

#order_table td:nth-child(2) {
  width: 320px;
}

#order_table td:nth-child(3),
td:nth-child(4) {
  width: 200px;
}

#invoice_table td:nth-child(1) {
  width: 210px;
}

#invoice_table td:nth-child(2) {
  width: 240px;
}

#invoice_table td:nth-child(3) {
  width: 220px;
}

#invoice_table td:nth-child(4) {
  width: 150px;
}

#invoice_table td:nth-child(5) {
  width: 172px;
  color: #2072EC;
  cursor: pointer;
}

.my_center_main {
  height: calc(100% - 69px);
  overflow-x: hide;
  overflow-y: auto;
  min-width: 960px;
}

.go_home:hover {
  color: #2072EC;
}

#maskLayer {
  background-color: #000 !important;
  opacity: 0.5 !important;
}

.feedback-box {
  width: 600px !important;
}

.my_center_userinfo_right p {
  display: inline-block;
}

.user_center_avatar {
  width: 75px;
  height: 75px;
  border-radius: 100%;
}

#feedback_input {
  width: 535px;
}

.feedback-box {
  height: 390px !important;
}

#feedback_phont_input {
  width: 485px;
}

.tanchuang-box .ctb-btns {
  margin-top: 20px !important;
  margin-bottom: 0 !important;
}

.my_center_wxbind_box {
  position: relative;
}

.my_center_account_cancellation_box {
  position: relative;
}

/* #bind_wx_name {
    font-family: MicrosoftYaHei;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #333333;
    margin: 14px 0;
    text-align: center;
  } */

.bind_wx_text {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #666666;
}

.bind_wx_flex {
  display: none;
  justify-content: center;
}

.bindwx_title,
#bind_wx_name {
  text-align: center;
  font-family: SourceHanSansSC-Regular, PingFangSC-Medium;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #333;
}

#bind_wx_name {
  text-align: left;
  margin: 0 0 10px 0;
}

#bind_wx_name span {
  font-weight: 600;
  color: #000;
}

.bind_wx_flex {
  display: flex;
  justify-content: center;
}

#jb_wx_btn,
#jb_qtwx_btn {
  margin-top: 18px;
  width: 122px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  background-color: #eb5350;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
}

#jb_qtwx_btn {
  margin-left: 20px;
  background-color: #fff;
  border: 1px solid #999;
  color: #333;
}

</style>
