<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import utils from "./utils/utils";
export default {
  name: "App",
  data() {
    return {
      phoneurl: "https://m.52cad.com",
      phoneurl_team_join: "/pages/myteam/join",
      phoneurl_mshare: "/pages/mshare/mshare",
      phoneurl_sdk: "/pages/sdk/selfcloud",
    };
  },

  mounted() {
    if (process.env.NODE_ENV == "development") {
      console.log("development");
    }

    if (this.isMobile()) {
      console.log("移动端");
      // 在手机中打开PC端路由处理
      if (window.location.pathname == "/dwg") {
        console.log("这是dwg");
        let file_id = utils.getQueryVariable("file_id");
        let share_id = utils.getQueryVariable("share_id");
        let link_id = utils.getQueryVariable("link_id");
        let pos = utils.getQueryVariable("pos");
        if (share_id == "") {
          window.location.href = this.phoneurl + "/pages/dwg/dwg?file_id=" + file_id;
        } else {
          window.location.href =
            this.phoneurl +
            "/pages/dwg/dwg?share_id=" +
            share_id +
            (link_id != "" ? "&link_id=" + link_id : "") +
            (pos != "" ? "&pos=" + pos : "");
        }
      } else if (window.location.pathname == "/cdf") {
        console.log("这是cdf");
        let file_id = utils.getQueryVariable("file_id");
        let share_id = utils.getQueryVariable("share_id");
        if (share_id == "") {
          window.location.href = this.phoneurl + "/pages/cdf/cdf?file_id=" + file_id;
        } else {
          window.location.href = this.phoneurl + "/pages/cdf/cdf?share_id=" + share_id;
        }
      } else if (window.location.pathname == "/mshare") {
        // 分享文件
        var share_id = utils.getQueryVariable("share_id");
        window.location.href = this.phoneurl + this.phoneurl_mshare + "?share_id=" + share_id;
      } else if (window.location.pathname == "/teamjion") {
        //加入团队
        var t_code = utils.getQueryVariable("t_code");
        window.location.href = this.phoneurl + this.phoneurl_team_join + "?t_code=" + t_code;
      } else if (window.location.pathname == "/privatecloudsdk") {
        window.location.href = this.phoneurl + this.phoneurl_sdk;
      } else if (window.location.pathname == "/sync") {
        console.log("Invite others to collaborate on drawing");
      } else if (window.location.pathname == "/syncs") {
        console.log("Invite others to collaborate on drawing");
      } else {
        //这是其他页面
        if (
          window.location.href.indexOf("/l/") !== -1 ||
          window.location.href.indexOf("/i/") !== -1
        ) {
          // 短链接
          this.judge_address("phone");
        } else {
          window.location.href = this.phoneurl;
        }
      }
    } else {
      console.log("pc端");
      this.judge_address("pc");
    }
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    //短号url dwg,cdf识别
    judge_address(from) {
      let str = window.location.href;
      if (str.indexOf("/l/") !== -1) {
        let arr = str.split("/l/");
        let share_id = arr[1];
        if (str.indexOf("?") !== -1) {
          share_id = share_id.split("?")[0];
        }
        let link_id = utils.getQueryVariable("link_id");
        let pos = utils.getQueryVariable("pos");
        if (from == "phone") {
          window.location.href =
            this.phoneurl +
            "/pages/dwg/dwg?share_id=" +
            share_id +
            (link_id != "" ? "&link_id=" + link_id : "") +
            (pos != "" ? "&pos=" + pos : "");
        } else {
          var data = {};
          if (link_id == "" && pos == "") {
            data = { share_id: share_id };
          } else if (link_id != "" && pos != "") {
            data = { share_id: share_id, link_id: link_id, pos: pos };
          } else if (link_id != "" && pos == "") {
            data = { share_id: share_id, link_id: link_id };
          } else if (link_id == "" && pos != "") {
            data = { share_id: share_id, pos: pos };
          } else {
            data = { share_id: share_id, link_id: link_id, pos: pos };
          }
          this.$router.push({ path: "/dwg", query: data, replace: true });
        }
        return;
      } else if (str.indexOf("/i/") !== -1) {
        let arr = str.split("/i/");
        let share_id = arr[1];
        if (from == "phone") {
          window.location.href = this.phoneurl + "/pages/cdf/cdf?share_id=" + share_id;
        } else {
          this.$router.push({ path: "/cdf", query: { share_id: share_id }, replace: true });
        }
        return;
      }
    },
  },
};
</script>

<style lang="less">
#app {
  height: 100%;
}

// eleement ui 中的 this.$alert 样式修改
.el-message-box__wrapper {
  .symbols_tip {
    width: 32%;
    padding-bottom: 25px;
    border: none;
    background-color: #454f61;

    .el-message-box__title,
    .el-message-box__content {
      color: #fff;
    }

    .el-message-box__content {
      padding: 23px 15px;
      text-align: center;

      .el-message-box__container {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .el-message-box__status {
        position: static;
        font-size: 32px !important;

        &.el-icon-warning {
          transform: rotate(180deg);
        }
      }

      .el-message-box__message {
        padding-left: 16px;
      }
    }

    .el-message-box__btns {
      text-align: center;

      .el-button--small {
        padding: 8px 35px;
      }

      .el-button--primary {
        background-color: #4176d0;
        border-color: #4176d0;
      }
    }
  }
}
</style>
