<template>
  <!-- 群成员管理 -->
  <el-dialog
    title="群成员"
    :visible.sync="$store.state.is_show_member"
    width="600px"
    :modal="true"
    :append-to-body="true"
    @close="handleMemberSetClose"
    class="my_team_member_diglog"
  >
    <div
      class="tanchuang-box"
      id="dwg_add_box"
      style="display: block; height: 376px; position: relative"
      @click.stop="is_show_jion_team_url = false"
    >
      <div class="team-add-user" v-if="uid == mu_id">
        <div class="team-add-head">添加成员</div>
        <div class="team-add-share-url" @click.stop="show_jion_team_url_btn">
          <img src="@/assets/images/team/chengyuan_fenxiang.png" alt="" />
          <span>分享群链接加入群</span>
        </div>
        <div class="team-add-share-ma" @click.stop="show_jion_team_qcode_btn">
          <img src="@/assets/images/team/chengyuan_ma.png" alt="" />
          <span>分享群二维码加入群</span>
        </div>
      </div>

      <!-- 团队链接 -->
      <div
        class="team-share-box"
        @click.stop="is_show_jion_team_url = true"
        v-show="is_show_jion_team_url"
      >
        <div class="tss-url">
          <div class="tss-url-input">
            <input
              id="team_expire_url"
              type="text"
              name=""
              readonly=""
              v-model="share_box_url"
            />
            <textarea
              id="team_expire_url_copy"
              type="text"
              style="position: absolute; top: 0; left: 0; opacity: 0"
              name=""
              readonly=""
            ></textarea>
          </div>
          <div id="copy_team_url" @click.stop="jion_team_copyText">
            复制链接
          </div>
        </div>
        <div class="tss-word1">
          请将链接通过QQ、微信发送给你的好友，邀请他们加入你的群
        </div>
      </div>
      <!-- 二维码链接 -->
      <JionTeamQcode ref="jionTeamQcode"></JionTeamQcode>
      <div class="tsu-line1" v-if="uid == mu_id"></div>

      <div class="team-users">
        <div class="team-user-first">
          <span class="team-user-first-p-num"
            >已加入成员（{{ memberItems.length }}）</span
          >
        </div>
        <div
          class="team_user_list"
          :class="uid == mu_id ? 'max_height180' : 'max_height340'"
        >
          <div class="team-user-item" v-for="(item, i) in memberItems" :key="i">
            <div class="team-user-left">
              <div class="team-user-avatar">
                <img src="@/assets/images/team/avatar.png" />
              </div>
              <div class="team-user-account" v-if="item.nick_name == ''">
                {{ item.user_name != '' ?  item.user_name : item.account }}
              </div>
              <div class="team-user-nickname" v-if="item.nick_name != ''">
                {{ item.nick_name != '' ?  item.nick_name : item.account }}
              </div>
            </div>
            <div class="team-user-type-master" v-if="item.utype == 1">群主</div>
            <div class="team-user-type-member" v-if="item.utype == 0" style="font-size:12px;margin-left:32px">成员
              <!-- <el-dropdown
                class="upload_all_btn_drop"
                style="font-size: 12px; padding-left: 12px"
              >
                <span
                  class="el-dropdown-link"
                  :style="
                    'font-size:12px;padding-left:' + uid == mu_id
                      ? '32px'
                      : '0px'
                  "
                  >成员
                  <i
                    class="el-icon-arrow-down el-icon--right"
                    v-if="uid == mu_id"
                  ></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <div class="team-user-bianji" v-if="uid == mu_id">
                    <div
                      class="team-user-bianji-item"
                      id="team-user-bianji-use-all"
                      @click.stop="memberSetItem(item, 'down')"
                    >
                      <div class="team-user-bianji-item-img">
                        <img
                          src="@/assets/images/cloud/reg_gou.png"
                          v-if="item.user_type == 'down'"
                        />
                      </div>
                      所有权限（文件）
                    </div>
                    <div
                      class="team-user-bianji-item"
                      @click.stop="memberSetItem(item, 'see')"
                    >
                      <div class="team-user-bianji-item-img">
                        <img
                          src="@/assets/images/cloud/reg_gou.png"
                          v-if="item.user_type == 'see'"
                        />
                      </div>
                      仅查看（文件）
                    </div>
                    <div
                      class="team-user-bianji-item"
                      @click.stop="memberSetItem(item, 'del')"
                    >
                      <div class="team-user-bianji-item-img"></div>
                      删除此成员
                    </div>
                  </div>
                </el-dropdown-menu>
              </el-dropdown> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import {
  get_team_users,
  upd_team_user_pmsion,
  delete_team_user,
  create_team_code,
} from "@/api/get_cloud";

import JionTeamQcode from "../components/jion_team_qcode.vue";

export default {
  name: "TeamMemberView",
  components: {
    JionTeamQcode,
  },
  data() {
    return {
      create_name: "",
      autofocus: true,
      userinfo: "",
      memberItems: [],
      uid: "",
      mu_id: "",
      is_show_jion_team_url: false,
      share_box_url: "",
    };
  },

  mounted() {
    var userinfo = JSON.parse(localStorage.getItem("USER_INFO"));
    if (userinfo != null) {
      this.userinfo = userinfo;
      this.uid = userinfo.uid;
    }
  },

  methods: {
    // 获取群成员列表
    getTeamUsers() {
      var send = {
        t_id: this.$store.state.cur_checke_tid,
      };

      this.mu_id = this.$store.state.get_team_data.mu_id;
      get_team_users(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          this.memberItems = data.datas;
        } else if (data.code == 99) {
          this.$parent.login_expired_team();
        } else {
          this.$msg.whiteerr('服务器繁忙')
        }
      });
    },

    handleMemberSetClose() {
      this.$store.state.is_show_member = false;
      this.is_show_jion_team_url = false;
    },

    memberSetItem(item, user_type) {
      if (this.uid == this.mu_id) {
        //管理员
        if (user_type == "down") {
          this.set_member_setting(item, user_type);
        } else if (user_type == "see") {
          this.set_member_setting(item, user_type);
        } else if (user_type == "del") {
          this.show_del_member_box(item);
        }
      }
    },

    //设置权限
    set_member_setting(item, type) {
      if (item.user_type == type) {
        return;
      }
      var send = {
        t_id: item.t_id,
        u_id: item.u_id,
        type: type,
      };
      upd_team_user_pmsion(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          this.memberItems.forEach((it) => {
            if (it.u_id == item.u_id) {
              if (type == "down") {
                it.user_type = "down";
              }
              if (type == "see") {
                it.user_type = "see";
              }
            }
          });
        } else if (res.code == 99) {
          this.$parent.login_expired_team();
        } else if (res.code == 3) {
          this.$msg.white('已拥有该权限')
        } else {
          this.$msg.whiteerr('服务器繁忙')
        }
      });
    },

    //显示删除确定框
    show_del_member_box(item) {
      var __this = this;
      this.$confirm("请确定是否删除此成员, 是否继续？", "删除成员", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        confirmButtonClass: "alert_del_btn",
        customClass: "cover_messageBox",
      })
        .then(() => {
          __this.$nextTick(() => {
            __this.delmember(item);
          });
        })
        .catch(() => {
          // this.$message.info("已取消删除");
        });
    },

    // 删除成员
    delmember(item) {
      var send = {
        t_id: item.t_id,
        u_id: item.u_id,
      };
      var __this = this;
      delete_team_user(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 99) {
          __this.$parent.login_expired_team();
        } else if (data.code == 1) {
          var newArr = [];
          __this.memberItems.forEach((it) => {
            if (it.u_id == item.u_id) {
              it = null;
            }
            if (it != null) {
              newArr.push(it);
            }
          });
          __this.memberItems = newArr;
          __this.$msg.white('删除成功')
        }
      });
    },

    show_jion_team_url_btn() {
      this.share_box_url = "正在获取中...";
      this.is_show_jion_team_url = !this.is_show_jion_team_url;
      var send = {
        type: "forever",
        t_id: this.$store.state.get_team_data.t_id,
      };
      if (this.is_show_jion_team_url) {
        create_team_code(send).then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 1) {
            this.share_box_url = "https://www.52cad.com/teamjion?t_code=" + data.t_code;
          } else if (data.code == 99) {
            this.$parent.login_expired_team();
          } else {
            this.$msg.whiteerr('服务器繁忙')
          }
        });
      }
    },

    show_jion_team_qcode_btn() {
      this.$store.state.is_show_jion_team_qcode = true;
      this.$nextTick(()=>{
        this.$refs.jionTeamQcode.get_qocde_jion_team(this.$store.state.get_team_data)
      })
    },

    // 文本复制
    jion_team_copyText() {
      //使用textarea的原因是能进行换行，input不支持换行
      var copyTextArea = document.createElement("textarea");
      //自定义复制内容拼接
      var str = this.share_box_url + '\n' + '邀请你加入『' + this.$store.state.get_team_data.tname + '』，共同查看、管理文件';
      copyTextArea.value = str;
      document.body.appendChild(copyTextArea);
      copyTextArea.select();
      try {
        var copyed = document.execCommand("copy");
        if (copyed) {
          document.body.removeChild(copyTextArea);
          //这里是封装的提示框，可以换成自己使用的提示框
          this.$msg.white('复制成功')
        }
      } catch {
        this.$msg.whiteerr('复制失败，请检查浏览器兼容')
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-icon--right {
  margin-left: 0 !important;
}

.ctb-input-tname {
  margin-top: 10px;
}

.tsu-line1 {
  width: 560px;
  height: 1px;
  background-color: #d8d8d8;
  margin-top: 20px;
  margin-bottom: 30px;
}

.team-users {
  width: 560px;
}

.team-user-first {
  position: relative;
  display: flex;
  align-items: center;
}

.team-user-first-sel {
  float: left;
  width: 16px;
  height: 16px;
  margin: 0;
}

.team-user-first-p-num {
  margin-left: 10px;
  font-size: 14px;
}

.team_user_list {
  margin-top: 20px;
  overflow-y: auto;
}

.max_height180 {
  max-height: 180px;
}

.max_height340 {
  max-height: 340px;
}

.team-user-item {
  position: relative;
  height: 30px;
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.team-user-item:hover {
  background-color: #f0f4fc;
}

.team-user-item-sel {
  float: left;
  width: 16px;
  height: 16px;
  margin-top: 7px;
  margin: 0;
}

.team-user-left {
  float: left;
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  width: 85%;
}

.team-user-avatar {
  width: 24px;
  height: 24px;
  margin-top: 3px;
}

.team-user-avatar img {
  width: 100%;
  height: 100%;
}

.team-user-account {
  line-height: 30px;
  font-size: 14px;
  color: #333;
  margin-left: 9px;
}

.team-user-nickname {
  line-height: 30px;
  font-size: 14px;
  color: #333;
  margin-left: 9px;
}

.team-user-type-quanxian {
  margin-right: 20px;
  cursor: pointer;
  display: inline-block;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  color: #2072EC;
  border: none;
  background-color: rgba(0, 0, 0, 0);
}

.team-user-type-master {
  margin-left: 32px;
  line-height: 30px;
  font-size: 12px;
  color: #333;
}

.team-user-type-user {
  margin-left: 20px;
  line-height: 30px;
  font-size: 12px;
  color: #333;
}

.team-user-type-member {
  margin-left: 20px;
  display: flex;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
}

.tutm-word {
  line-height: 30px;
  font-size: 12px;
}

.tutm-pulldown {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
}

.team-user-bianji-item {
  height: 30px;
  background-color: #fff;
  line-height: 30px;
  text-align: center;
  font-size: 12px;
  color: #333;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.team-user-bianji-item:hover {
  background-color: #f0f4fc;
}

.team-user-bianji-item-img {
  width: 20px;
  width: 20px;
  padding: 4px;
}

.team-user-bianji-item-img img {
  width: 15px;
  height: 15px;
}

.team-user-bianji-item:last-child {
  color: #f04343;
}

.team-file-collection {
  width: 22px;
  height: 22px;
  overflow: hidden;
  margin-right: 20px;
  display: none;
  float: right;
  cursor: pointer;
}

.team-file-collection img {
  width: 20px;
  height: 20px;
}

.caozuo-box-team {
  width: 40%;
}

.team-file-item .caozuo-box .file-more .team-file-collection {
  clear: both;
}

.team-user-first-all-del {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #f04343;
  margin-left: 10px;
  display: none;
}

.team-add-share-url,
.team-add-share-ma {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.team-add-head {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
  padding-bottom: 20px;
}

.team-add-share-ma {
  padding-top: 20px;
}

.team-add-share-url img,
.team-add-share-ma img {
  width: 24px;
  height: 24px;
}

.team-add-share-url span,
.team-add-share-ma span {
  padding-left: 6px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
}

.upload_all_btn_drop {
  display: flex;
}

// 团队链接样式
.team-share-box {
  border-radius: 5px;
  box-shadow: 0px 2px 4px 0px rgb(137 136 143 / 16%),
    0px 1px 11px 0px rgb(115 115 115 / 15%);
  background-color: #fff;
  position: absolute;
  top: 70px;
  left: 280px;
  transform: translateX(-50%);
  display: block;
  z-index: 55;
  width: 100%;
}

.tss-top {
  width: 100%;
  height: 64px;
  position: relative;
}

.tss-top-word {
  font-size: 15px;
  color: #333;
  height: 64px;
  line-height: 64px;
  margin-left: 20px;
  float: left;
}

#exit_team_share {
  width: 20px;
  height: 20px;
  margin-right: 20px;
  margin-top: 22px;
  cursor: pointer;
  float: right;
}

#exit_team_share img {
  width: 100%;
  height: 100%;
}

.tss-line1 {
  width: 560px;
  height: 1px;
  background-color: #d8d8d8;
  margin-left: 20px;
}

.tss-expire {
  margin-left: 20px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  height: 21px;
  width: 560px;
}

.tss-expire-word {
  font-size: 14px;
  line-height: 21px;
  color: #333;
}

.tss-item {
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tss-item input {
  width: 16px;
  height: 16px;
  margin: 0;
}

.team-code-expire-word {
  font-size: 14px;
  line-height: 21px;
  color: #333;
  padding-left: 6px;
}

.team-code-expire-word-active {
  color: #2072EC;
}

.tss-url {
  margin-left: 20px;
  margin-top: 20px;
  border-radius: 2px;
  display: flex;
  flex-direction: row;
  width: 93%;
}

.tss-url-input {
  width: 461px;
  height: 42px;
}

#team_expire_url {
  width: 100%;
  height: 38px;
  text-indent: 11px;
  font-size: 14px;
  color: #666;
  outline: none;
  border: 1px solid #dcdcdc;
}

#copy_team_url {
  width: 100px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  background-color: #2072EC;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

.tss-word1 {
  margin-left: 20px;
  margin-top: 10px;
  font-size: 12px;
  color: #666;
  padding-bottom: 21px;
}

.item-file-active {
  background-color: #f0f4fc;
}
</style>