<template>
  <el-dialog
    :title=" this.$store.state.share_from == 'myclouds' ? '批量发送图纸' :'发送图纸'"
    :visible.sync="$store.state.is_show_share_box"
    width="600px"
    :modal="true"
    :append-to-body="true"
    @close="handleClose"
    :close-on-click-modal="false"
    :class="$store.state.share_from == 'dwg'? 'share_dwgsee_dialog' :'share_dwg_dialog'"
  >
    <div class="share_box_warp">
      <div data-v-91b125d6="" class="ctb-line"></div>
      <div class="share_box">
        <!-- <div class="share_xz_box">
          <div
            class="share_xz_url"
            :class="
              $store.state.cur_show_action == 'url' ? 'share_xz_active' : ''
            "
            @click="check_send_type('url')"
          >
            <img
              :src="
                $store.state.cur_show_action == 'url' ? url_img_active : url_img
              "
              alt=""
               v-if="$store.state.share_from != 'dwg'"
            />
            <img
              :src="
                $store.state.cur_show_action == 'url'
                  ? dwg_url_img
                  : dwg_url_img
              "
              alt=""
              v-if="$store.state.share_from == 'dwg'"
            />
            <div>链接发送</div>
          </div>
          <div
            class="share_xz_code"
            :class="
              $store.state.cur_show_action == 'code' ? 'share_xz_active' : ''
            "
            @click="check_send_type('code')"
          >
            <img
              :src="
                $store.state.cur_show_action == 'code'
                  ? code_img_active
                  : code_img
              "
              alt=""
              v-if="$store.state.share_from != 'dwg'"
            />

            <img
              :src="
                $store.state.cur_show_action == 'code'
                  ? dwg_code_img
                  : dwg_code_img
              "
              alt=""
              v-if="$store.state.share_from == 'dwg'"
            />

            <div>二维码发送</div>
          </div>
        </div> -->
        <div class="share_title">
          <img src="@/assets/images/share_page/share_pack.png" alt="">
          <span class="intro">
            <span class="name">{{ $store.state.cur_share_data.name }}</span>
          </span>
        </div>
        <!-- <div class="share_box_quanxian" v-if="$store.state.cur_share_data.type == 'file' && $store.state.share_from != 'myteam'">
          <div class="tss-expire-time">有效天数：<span>30天</span></div>
        </div> -->
        <div class="share_box_quanxian" v-if="$store.state.cur_share_data.type != 'link' && $store.state.share_from != 'myteam'">
          <div class="tss-expire">
            <div class="tss-expire-word">有效天数：</div>
            <div class="tss-item" @click="checked_quanxian_day(0)">
              <!-- <el-radio v-model="radio" label="1">备选项</el-radio> -->
              <div class="redio" :class="quanxian_redio_day == 0 ? 'redio_active': ''"></div>
              <div class="text" data-type="down">7天</div>
            </div>
            <div class="tss-item" id="show_down" @click="checked_quanxian_day(1)">
              <div class="redio" :class="quanxian_redio_day == 1 ? 'redio_active': ''"></div>
              <div class="text">永久</div>
            </div>
          </div>
        </div>
        
        <div class="share_boxquanxian" v-if="$store.state.cur_share_data.type == 'file' && $store.state.share_from != 'myteam'">
          <div class="tss-expire">
            <div class="tss-expire-word">下载权限：</div>
            <div class="tss-item" @click="checked_quanxian(1)">
              <!-- <el-radio v-model="radio" label="1">备选项</el-radio> -->
              <div class="redio" :class="quanxian_redio == 1 ? 'redio_active': ''"></div>
              <div class="text" data-type="down">可下载图纸</div>
            </div>
            <div class="tss-item" id="show_down" @click="checked_quanxian(0)">
              <div class="redio" :class="quanxian_redio == 0 ? 'redio_active': ''"></div>
              <div class="text" data-type="look">不可下载图纸</div>
            </div>
          </div>
        </div>

        <div class="share_tss-url">
          <div class="share_tss-url-box">
            <div class="tss-url-input">
              <input
                id="share_expire_url"
                type="text"
                name=""
                readonly=""
                v-model="$store.state.share_box_url"
              />
              <textarea
                id="share_expire_url_copy"
                type="text"
                style="
                  position: absolute;
                  bottom: 0;
                  left: 0;
                  opacity: 0;
                  height: 2px;
                "
                name=""
                readonly=""
              ></textarea>
            </div>
            <div id="copy_share_url" @click="copyText">复制链接</div>
          </div>
          <div class="share_tss-word1">
            已创建链接，复制后通过微信、QQ、邮件等发送给你的好友吧～
          </div>

          <div class="use_code_share">
            <div class="img_box" @click="check_send_type('code')">
              <img src="@/assets/images/share_page/qr_code_share.png" alt="">
              <div class="intro">生成二维码</div>
              </div>
            
          </div>
        </div>

        <div
          class="share_url_code"
          v-if="$store.state.cur_show_action == 'code'"
        >
          <div class="share_insert">
            <img class="close" @click="check_send_type('url')" src="@/assets/images/share_page/close.png" alt="">
            <div class="share_url_code_box">
              <div class="share_code_box" id="capture" ref="imageTofile">
                <div class="share_code_head">
                  <img src="@/assets/images/share_page/share_pack.png" alt="">
                  <div class="rt">
                    <p class="file_name">
                      <span>{{$store.state.cur_share_data.name}}</span>
                    </p>
                  </div>
                </div>
                <div id="share_qcode" ref="qrcode" v-loading="is_loading"></div>
                <div class="share_line"></div>
                <div class="share_code_foot">
                  <img src="@/assets/images/common/share_logo.png" />
                  <span>迷你CAD  易学易用的CAD软件</span>
                </div>
              </div>
            </div>
            <div class="share_qcode_down" @click="down_share_qcode">下载二维码</div>
            <div class="share_qcode_down_text">
              保存二维码发送给朋友，微信扫码可直接查看
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import QRCode from "qrcodejs2";
import { get_share_code, online_sharefile_m, get_share_linkfile_code } from "@/api/share";
import { statistic } from '@/api/dwg'
import html2canvas from 'html2canvas'
import utils from '@/utils/utils';
import Common from '@/utils/common'

export default {
  name: "ShareView",
  data() {
    return {
      headerTitle1: Common.headerTitle1,
      avatar: require("@/assets/images/common/avatar.png"),
      url_img: require("@/assets/images/common/share_url.png"),
      url_img_active: require("@/assets/images/common/share_url1.png"),
      code_img: require("@/assets/images/common/share_code.png"),
      code_img_active: require("@/assets/images/common/share_code1.png"),

      dwg_url_img:require("@/assets/images/dwg_see/dwg_share_url.png"),
      dwg_code_img: require("@/assets/images/dwg_see/dwg_share_code.png"),
      is_loading: false,
      user_name: "",
      is_get_url: false,
      quanxian_redio: 1,
      quanxian_redio_day: 0, // 0 7天， 1 永久
      who_use: '',
    };
  },

  mounted() {
    var that = this
    // 监听其他地方调用
    this.$EventBus.$off("showShareBox");
    this.$EventBus.$on("showShareBox", function (txt) {
      if (txt) {
        that.who_use = txt;
      } else {
        that.who_use = '';
      }

      that.check_send_type('url');
    });

  },

  updated(){
    // console.log(this.is_get_url)
  },

  computed: {
    // changeCurShareData() {
    //   return this.$store.state.curShareData;
    // },
  },
  methods: {
    handleClose() {
      this.$store.state.cur_show_action = "url";
      this.$store.state.share_is_tag = false
      this.is_get_url = false
      this.quanxian_redio_day = 0
      this.quanxian_redio = 1
      if (this.$store.state.batchCheckedItems.length > 0) {
        this.$store.state.batchCheckedItems = [];
      }
    },

    checked_quanxian(val) {
      this.is_get_url = false
      this.quanxian_redio = val
      this.$store.state.share_box_url = '正在努力获取中...'
      this.check_send_type(this.$store.state.cur_show_action)
    },

    checked_quanxian_day (val) {
      this.is_get_url = false
      this.quanxian_redio_day = val
      this.$store.state.share_box_url = '正在努力获取中...'
      this.check_send_type(this.$store.state.cur_show_action)
    },

    get_user_info() {
      var userinfo = this.$store.state.userinfo
      if (userinfo.is_wx == 0) {
        this.avatar = require("@/assets/images/common/avatar.png");
        this.user_name =
          userinfo.user_name == "" || userinfo.user_name == null ? userinfo.account : userinfo.user_name;
      } else {
        this.user_name = userinfo.wx_nickname;
        this.avatar = userinfo.wx_avatar;
      }
    },

    //选择发送方式
    check_send_type(type) {
      if (type == "url") {
        this.$store.state.cur_show_action = type;
      } else {
        this.is_loading = true;
        this.$store.state.cur_show_action = type;
        this.get_user_info();
      }
      // this.$store.state.share_box_url = "正在努力获取中...";
      var item = this.$store.state.cur_share_data;
      if (this.$store.state.share_from == "myclouds") {
        this.SEND_DWGS(type)
        return
      } if (this.$store.state.share_from == "myzj") {
        this.getShareQcode_zj(type,item)
        return
      } else if (this.$store.state.share_from == "myteam") {
        this.getShareQcode_team(item)
        return
      } else if (this.$store.state.share_from == "dwg") {
        this.getShareQcode_dwg(item)
        return
      } else if (this.$store.state.share_from == "cdf") {
        this.getShareQcode_cdf(item)
      } else {
        this.getShareQcode(item);
      }
    },

    getShareQcode_zj(type,item){
      // this.$store.state.share_box_url = "正在努力获取中...";
      if (item.is_share == 1) {

        if ( (item.type && item.type == 'linkfile') || (item.link_type && item.link_type == 'linkfile')) {
          if (type == 'code') {
            this.creatQrCode(this.$store.state.share_box_url);
            return
          }

          if (this.is_get_url && type == 'url') {
            return
          }
          this.getShareLinkfileCode1(item.link_id)
        } else {
          if (type == "url") {
            this.$store.state.share_box_url = item.share_url;
          } else {
            this.creatQrCode(item.share_url);
          }
          this.send_share_statistic(item)
          return
        }
        return
      }
      var share_box_p = item.cloud_path
      var send = {
        type: this.quanxian_redio,
        p: share_box_p,
        days: this.quanxian_redio_day == 0 ? 7 : 7300
      };
      if (this.is_get_url) { 
        if (type == "code") {
          this.creatQrCode(this.$store.state.share_box_url)
        }; 
        return
      }
      get_share_code(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          var url = data.url;
          this.is_get_url = true
          this.$store.state.share_box_url = url
          if (item.file_type == '2d') {
            statistic('share_file_2d', '', data.share_id)
          } else if (item.file_type == '3d') {
            statistic('share_file_3d', '', data.share_id)
          }
          if (type == "url") {
            this.$store.state.share_box_url = url
          } else {
            this.creatQrCode(url);
          }
        } else if (data.code == 4) {
          this.$msg.whiteerr('文件不存在，可能已被删除');
        } else if (data.code == 99) {
          this.login_expired_my();
        }
      });
    },

    getShareQcode_dwg(item){
      var type = this.$store.state.cur_show_action;
      if (item.is_share == 'yes') {
        var url = window.location.href
        // if (this.$parent.cloudlinkinfo != null && this.$parent.cloudlinkinfo.link_type == 'linkfile') {
          if (utils.getQueryVariable('link_id') != '') {
            
          
          if (type == 'code') {
            this.creatQrCode(this.$store.state.share_box_url);
            return
          }

          if (this.is_get_url && type == 'url') {
            return
          }

          this.getShareLinkfileCode1(utils.getQueryVariable('link_id'))
          return
        }

        url = 'https://' + window.location.hostname + '/l/' + utils.getQueryVariable('share_id')
        if (this.$store.state.share_is_tag) {
            url += '?pos=' + this.$store.state.cur_share_tag_pos_id;
          }
        if (type == "url") {
          // url
          this.$store.state.share_box_url = url
        } else {
          // qrcode
          this.creatQrCode(this.$store.state.share_box_url = url);
        }
        return
      }

      if (this.is_get_url) {
        if (this.$store.state.cur_show_action == "code") {
          this.creatQrCode(this.$store.state.share_box_url);
        }
        return
      }

      var send = {
        type: this.quanxian_redio,
        f_id: item.f_id,
        days: this.quanxian_redio_day == 0 ? 7 : 7300
      }
      
      get_share_code(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          var url = data.url;
          this.is_get_url = true
          if (this.$store.state.share_is_tag) {
            url += '?pos=' + this.$store.state.cur_share_tag_pos_id;
          }
          if (this.$store.state.cur_show_action == "url") {
            // url
            this.$store.state.share_box_url = url;
          } else {
            // qrcode
            this.$store.state.share_box_url = url
            this.creatQrCode(url);
          }
        } else if (data.code == 4) {
          this.$msg.whiteerr('文件不存在，可能已被删除');
        } else if (data.code == 99) {
          utils.del_localStorae()
          this.$EventBus.$emit("reflushUserinfo");
          this.$msg.whiteerr('登录信息已失效，请重新登录');
          if (this.$store.state.share_from == 'dwg') {
            this.$parent.showLogin()
          }
        }
      });

    },


    getShareQcode_cdf(item) {
      var type = this.$store.state.cur_show_action;
      if (item.is_share == 'yes') {
        var url = window.location.href
        url = 'https://' + window.location.hostname + '/i/' + utils.getQueryVariable('share_id')
        if (type == "url") {
          // url
          this.$store.state.share_box_url = url
        } else {
          // qrcode
          this.creatQrCode(this.$store.state.share_box_url = url);
        }
        return
      }

      if (this.is_get_url) {
        if (this.$store.state.cur_show_action == "code") {
          this.creatQrCode(this.$store.state.share_box_url);
        }
        return
      }

      var send = {
        type: this.quanxian_redio,
        f_id: item.f_id,
        days: this.quanxian_redio_day == 0 ? 7 : 7300
      }
      
      get_share_code(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          var url = data.url;
          this.is_get_url = true
          if (this.$store.state.cur_show_action == "url") {
            // url
            this.$store.state.share_box_url = url;
          } else {
            // qrcode
            this.$store.state.share_box_url = url
            this.creatQrCode(url);
          }
        } else if (data.code == 4) {
          this.$msg.whiteerr('文件不存在，可能已被删除');
        } else if (data.code == 99) {
          utils.del_localStorae()
          this.$EventBus.$emit("reflushUserinfo");
          this.$msg.whiteerr('登录信息已失效，请重新登录');
          if (this.$store.state.share_from == 'dwg') {
            this.$parent.showLogin()
          }
        }
      });
    },


    getShareLinkfileCode1 (link_id) {
      var send = {
        type: this.$parent.is_downfile ? 1 : 0,
        link_id: link_id,
        days: this.quanxian_redio_day == 0 ? 7 : 7300
      };
      get_share_linkfile_code(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          var url = data.url;
          if (this.$store.state.share_is_tag) {
            url += '?pos=' + this.$store.state.cur_share_tag_pos_id;
          }
          this.is_get_url = true
          this.$store.state.share_box_url = url;
        } else if (data.code == 4) {
          this.$msg.dwgerror("文件不存在，可能已经删除");
        } else if (data.code == 99) {
          this.$msg.dwgerror("登录信息已失效，请重新登录");
          if (this.$store.state.share_from == 'dwg') {
            utils.del_localStorae()
            this.$EventBus.$emit("reflushUserinfo");
            this.$parent.showLogin()
          }
        }
      });
    },


    //获取分享地址
    getShareQcode(item) {
      var type = this.$store.state.cur_show_action;
      var share_box_p;
      var send;
      if (item.share_id && item.share_id != '') {
        if ( (item.type && item.type == 'linkfile') || (item.link_type && item.link_type == 'linkfile')) {
          if (type == 'code') {
            this.creatQrCode(this.$store.state.share_box_url);
            return
          }

          if (this.is_get_url && type == 'url') {
            return
          }
          this.getShareLinkfileCode1(item.link_id)
        } else {
          if (type == "url") {
            // url
            this.$store.state.share_box_url = item.share_url
          } else {
            // qrcode
            this.creatQrCode(item.share_url)
          }
        }
        this.send_share_statistic(item)
        return
      }


      if (item.is_share == 1) {
        if (type == "url") {
          // url
          this.$store.state.share_box_url = item.share_url
        } else {
          // qrcode
          this.creatQrCode(item.share_url);
        }
        return
      }


      if (item.parent_dir == "/") {
        share_box_p = item.parent_dir + item.name;
      } else {
        share_box_p = item.parent_dir + "/" + item.name;
      }
      if (item.t_id == 0 || item.t_id == undefined) {
        //个人
        send = {
          type: this.quanxian_redio,
          p: share_box_p,
          days: this.quanxian_redio_day == 0 ? 7 : 7300
        };
      } else {
        // 团队
        share_box_p = item.name;
        send = {
          type: 0,
          t_id: item.t_id,
          p: share_box_p,
        };
      }
      if (this.is_get_url) { 
        if (type == "code") {
          this.creatQrCode(this.$store.state.share_box_url)
        };
        return
      }
      get_share_code(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          var url = data.url;
          this.is_get_url = true
          this.$store.state.share_box_url = url;
          if (item.file_type == '2d') {
            statistic('share_file_2d', '', data.share_id)
          } else if (item.file_type == '3d') {
            statistic('share_file_3d', '', data.share_id)
          }
          if (this.$store.state.cur_show_action == "url") {
            // url
          } else {
            // qrcode
            this.creatQrCode(url);
          }
        } else if (data.code == 4) {
          this.$msg.whiteerr('文件不存在，可能已被删除');
        
        } else if (data.code == 99) {
          this.$msg.whiteerr('登录信息已失效，请重新登录');
        }
      });
    },
    creatQrCode(url) {
      if (url == '' || url == '正在努力获取中...') {
        return
      }
      var that = this
      setTimeout(() => {
        that.$refs.qrcode.innerHTML = "";
        new QRCode(that.$refs.qrcode, {
          // text 需要转二维码的内容 可以是文本也可以是一个链接 是链接会直接跳走
          text: url,
          width: 172,
          height: 172,
          colorDark: "#333", //二维码颜色
          colorLight: "#fff", //二维码背景颜色
          correctLevel: QRCode.CorrectLevel.H, //容错率,L/M/H
        });
        that.is_loading = false;
      }, 200);
    },
    //获取分享地址 来自群文件列表
    getShareQcode_team(item) {
      var share_box_p = item.name;
      var send = {
        type: 0,
        t_id: this.$store.state.cur_checke_tid,
        p: share_box_p,
      };
      if (this.is_get_url) {
        if (this.$store.state.cur_show_action == "code") {
          this.creatQrCode(this.$store.state.share_box_url);
        }
        return
      }
      get_share_code(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          this.is_get_url = true
          var url = data.url;
          if (this.$store.state.cur_show_action == "url") {
            // url
            this.$store.state.share_box_url = url;
          } else {
            // qrcode
            this.creatQrCode(url);
          }
        } else if (data.code == 4) {
          this.$msg.whiteerr('文件不存在，可能已被删除');
        } else if (data.code == 99) {
          this.$msg.whiteerr('登录信息已失效，请重新登录');
        }
      });
    },

    // 统计已有shareid的分享
    send_share_statistic (item) {
      if (item.file_type == '2d') {
        statistic('share_file_2d', '', item.share_id)
      } else if (item.file_type == '3d') {
        statistic('share_file_3d', '', item.share_id)
      }
    },

    SEND_DWGS(type) {
      var items = this.$store.state.checkedItems;
      if (this.who_use == 'batch') {
        items = this.$store.state.batchCheckedItems;
      }

      var share_list = [];
      if (items.length == 1 && items[0].type == 'link' || items[0].type == 'linkfile') {
        if (type == "url") {
            // url
            this.$store.state.share_box_url = items[0].share_url;
          } else {
            // qrcode
            this.creatQrCode(items[0].share_url);
          }
        return
      }
      this.$store.state.is_show_share_box = true;

      items.forEach((item) => {
        if (item.type == "file") {
          var p = item.parent_dir + "/" + item.name;
          p = p.replace("//", "/");
          share_list.push(p);
        }
      });

      var send = {
        type: this.quanxian_redio,
        ps: JSON.stringify(share_list),
        days: this.quanxian_redio_day == 0 ? 7 : 7300
      };

      // console.log(this.is_get_url)
      if (this.is_get_url) { 
        if(this.$store.state.cur_show_action == 'code') {
          this.creatQrCode(this.$store.state.share_box_url)
        }
        return
      }
      // this.$store.state.share_box_url = "正在努力获取中...";
      online_sharefile_m(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          var url = data.url;
          // url = 'https://' + window.location.hostname + '/mshare?share_id=' + data.share_id
          this.is_get_url = true
          if (this.$store.state.cur_show_action == "url") {
            // url
            this.$store.state.share_box_url = url;
          } else {
            // qrcode
            this.$store.state.share_box_url = url;
            this.creatQrCode(url);
          }
        } else if (data.code == 4) {
          this.$msg.whiteerr('文件不存在，可能已被删除');
        } else if (data.code == 99) {
          this.$msg.whiteerr('登录信息已失效，请重新登录');
        }
      });
    },

    // 生成下载分享二维码
    down_share_qcode() {
      var that = this
      html2canvas(this.$refs.imageTofile).then(canvas => {
        const imgUrl = canvas.toDataURL("png"); // 获取生成的图片的url
        that.downloadIamgeTest(imgUrl,'分享文件二维码')
      })
    },

    downloadIamgeTest(imgsrc, name) {
      // 下载图片地址和图片名
      var image = new Image();
      // 解决跨域 Canvas 污染问题
      image.setAttribute("crossOrigin", "anonymous");
      image.onload = function () {
        var canvas = document.createElement("canvas");
        canvas.width = image.width;
        canvas.height = image.height;
        var context = canvas.getContext("2d");
        context.drawImage(image, 0, 0, image.width, image.height);
        var url = canvas.toDataURL("image/png"); // 得到图片的base64编码数据
        var a = document.createElement("a"); // 生成一个a元素
        var event = new MouseEvent("click"); // 创建一个单击事件
        a.download = name || "photo"; // 设置图片名称
        a.href = url; // 将生成的URL设置为a.href属性
        a.dispatchEvent(event); // 触发a的单击事件
      };
      image.src = imgsrc;
    },


    // 文本复制
    copyText() {
      //使用textarea的原因是能进行换行，input不支持换行
      var copyTextArea = document.createElement("textarea");
      //自定义复制内容拼接
      var str = Common.copyText + this.$store.state.cur_share_data.name +'\n' + this.$store.state.share_box_url;
      
      if (this.$store.state.share_from == 'myclouds') {
        str = Common.copyText + '批量分享图纸' +'\n' + this.$store.state.share_box_url;
        let items = this.$store.state.checkedItems;
        if (this.who_use == 'batch') {
          items = this.$store.state.batchCheckedItems;
        }
        if (items.length == 1 && items[0].type == 'link' || items[0].type == 'linkfile') {
         str = Common.copyText + items[0].name +'\n' + this.$store.state.share_box_url;
        }
      }
      copyTextArea.value = str;
      document.body.appendChild(copyTextArea);
      copyTextArea.select();
      try {
        var copyed = document.execCommand("copy");
        if (copyed) {
          document.body.removeChild(copyTextArea);
          //这里是封装的提示框，可以换成自己使用的提示框
          this.$msg.white('复制成功')
        }
      } catch {
        this.$msg.whiteerr('复制失败，请检查浏览器兼容')
      }
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__header {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.share_box_warp {
  overflow: auto;
  margin: 0;
  /* background-color: #999; */
}

.share_box {
  position: relative;
  background: #fff;
  border-radius: 2px;
  box-sizing: border-box;
  width: 100%;
  width: 600px;
  margin-top: 20px;
  .share_title {
    display: flex;
    align-items: center;
    font-size: 18px;
    color: #313131;
    line-height: 27px;
    margin-left: 20px;

    .intro {
      display: flex;
      align-items: center;
      width: 100%;
      font-weight: 600;

      .name {
        max-width: 450px;
        margin-left: 14px;
        margin-right: 11px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}

.share_box_warp .ctb-line {
  width: 560px;
  height: 1px;
  background-color: #e7e7e7;
  margin-left: 20px;
  transform: scaleY(0.5);
}

.share_box_hader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  width: 560px;
  border-bottom: 1px solid #e7e7e7;
}

.share_box_headerbtn {
  text-align: center;
  padding-left: 15px;
  cursor: pointer;
  margin-right: -5px;
}

.share_box_headerbtn img {
  width: 25px;
  height: 25px;
}

#share_box_title {
  max-width: 500px;
  word-break: break-all;
  line-height: 22px;
  text-overflow: ellipsis;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
}

.share_box_footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 30px 20px;
}

.share_box_footer div {
  display: inline-block;
  line-height: 1;
  white-space: nowrap;
  cursor: pointer;
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
  -webkit-appearance: none;
  text-align: center;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 0;
  margin: 0;
  -webkit-transition: 0.1s;
  transition: 0.1s;
  font-weight: 500;
  padding: 12px 20px;
  font-size: 14px;
  border-radius: 4px;
}

.share_box_footer .shar_btn_quxiao {
  color: #999;
  background-color: #fff;
  border-color: #999;
}

.share_box_footer .shar_btn_queding {
  color: #fff;
  background-color: #409eff;
  border-color: #409eff;
  margin-left: 10px;
}

.share_url_code {
  padding: 20px;
}
.share_url_code {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, .5);

  .share_insert {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 42px 35px 34px;
    border-radius: 4px;
    border: 1px solid #DCDCDC;
    background-color: #fff;
    box-shadow: 0px 6px 10px 0px rgba(146,146,146,0.4);

    .close {
      position: absolute;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
  }
}


.tss-url-input {
  width: 100%;
}

.share_tss-url {
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  padding: 20px 20px 20px 20px;
}

.share_tss-url-box {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.share_tss-word1 {
  font-size: 12px;
  color: #666;
  padding: 10px 0 30px 0;
}

#share_expire_url {
  width: 100%;
  height: 38px;
  text-indent: 11px;
  font-size: 14px;
  color: #666;
  outline: none;
  border: 1px solid #2072EC;
}

#copy_share_url {
  width: 100px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  background-color: #2072EC;
  font-size: 14px;
  color: #fff;
  cursor: pointer;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
}

#share_qcode {
  width: 172px;
  height: 172px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  overflow: hidden;
}

#share_qcode img {
  width: 172px;
  height: 172px;
}

.tss-expire-time {
  font-size: 14px;
  line-height: 21px;
  color: #333;
  padding-left: 20px;
  padding-top: 30px;
}

.tss-expire-time span {
  padding-left: 5px;
}

.share_box_quanxian {
  margin-top: 20px;
}

.tss-expire {
  margin-left: 20px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  height: 21px;
  width: 560px;
  align-items: center;
}

.tss-item {
  margin-left: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  margin-right: 24px;
  .redio {
    width: 16px;
    height: 16px;
    background: url('@/assets/images/share_page/xz_1.png');
    background-size: cover;
  }

  .redio_active {
    background: url('@/assets/images/share_page/xz_2.png');
    background-size: cover;
  }
  
  .text {
    margin-left: 5px;
    font-size: 14px;
    color: #5A5D5A;
    height: 16px;
    line-height: 16px;
  }
}

.share_dwgsee_dialog {
  .tss-expire-word {
    color: #FFF;
  }
  .tss-item {
    .text {
      color: #FFF;
    }
  }
}

.tss-item input {
  margin: 0;
}

.share_xz_box {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 20px;
  width: 240px;
  border: 1px solid #e7e7e7;
}

.share_xz_url,
.share_xz_code {
  width: 120px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.share_xz_url img,
.share_xz_code img {
  width: 16px;
  height: 16px;
}

.share_xz_code div,
.share_xz_url div {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  padding-left: 4px;
  cursor: pointer;
}

.share_xz_active {
  background-color: #f0f4fc;
  color: #2072EC;
}

.share_url_code_box {
  display: flex;
  align-items: center;
  justify-content: center;
}

.share_code_box {
  // width: 292px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.share_code_head {
  display: flex;
  align-items: center;
  width: 251px;
  margin-bottom: 30px;

  .rt {
    flex: 1;
    margin-left: 12px;
    font-size: 14px;
    line-height: 20px;
    color: #999999;
    overflow: hidden;

    .file_name {
      margin-bottom: 3px;
      color: #333333;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}

.share_code_foot {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 10px;
  padding-bottom: 20px;
}

.share_code_foot img {
  width: 24px;
  height: 24px;
}

.share_code_foot span {
  padding-left: 8px;
  color: #999;
  font-size: 14px;
}

.share_code_title {
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
  padding-bottom: 20px;
  font-weight: 500;
}

.share_qcode_down {
  width: 292px;
  height: 42px;
  background-color: #2072EC;
  border-radius: 2px;
  font-size: 14px;
  letter-spacing: 0px;
  color: #ffffff;
  text-align: center;
  line-height: 42px;
  margin: 20px auto;
  cursor: pointer;
}

.share_qcode_down_text {
  color: #666;
  font-size: 14px;
  margin: auto;
  text-align: center;
  margin-top: -10px;
  padding-bottom: 10px;
}

.share_line {
  margin-top: 20px;
  width: 251px;
  height: 1px;
  background-color: #e7e7e7;
}

.use_code_share {
  padding-top: 30px;
  padding-bottom: 10px;
  border-top: 1px solid #e7e7e7;
  display: flex;
  justify-content: center;

  .img_box {
    text-align: center;
    cursor: pointer;
    width: 100px;
  }

  .intro {
    margin-top: 8px;
    text-align: center;
    font-size: 12px;
    color: #333333;
    line-height: 17px;
  }
}

</style>