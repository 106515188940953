<template>
  <el-dialog
    title="微信绑定"
    :visible.sync="$store.state.is_show_bindwx_qcode_box"
    width="450px"
    :modal="true"
    :append-to-body="true"
    @close="handleClose"
  >
    <div class="share_box_warp">
      <div class="show_wx_login_login_center" style="margin-top: 17px">
        <div class="show_wx_login_login_center_qcode_border">
          <img
            class="show_wx_login_login_center_qcode wx_login_qcode"
            id="wx_login_qcode"
            ref="loginqrcode"
            :src="wx_qcode"
            v-if="wx_login_qocde == '408' || wx_login_qocde == '4088'"
          >
          <div class="wx_login_loading_box" v-if="wx_login_qocde == '0'">
            <div class="wx_login_loading_text1">二维码获取中</div>
            <div class="wx_login_loading_text2">请稍后...</div>
          </div>
          <div class="wx_login_loading_box" v-if="wx_login_qocde == '404'">
            <div class="wx_login_loading_text1" style="color: #3975c6">
              扫码成功
            </div>
            <div class="wx_login_loading_text2" style="color: #3975c6">
              等待用户授权
            </div>
          </div>
          <div class="wx_login_loading_box" v-if="wx_login_qocde == '405'">
            <div class="wx_login_loading_text1" style="color: #3975c6">扫码成功</div>
            <div class="wx_login_loading_text2" style="color: #3975c6">正在绑定</div>
          </div>
          <div class="wx_login_loading_box" v-if="wx_login_qocde == '403'">
            <div class="wx_login_loading_text1" style="color: #999">用户拒绝授权</div>
            <div
              class="wx_login_loading_text2"
              style="color: #3975c6; cursor: pointer"
              @click="get_wx_login_qcode"
            >点击刷新</div>
          </div>
          <div class="wx_login_loading_box" v-if="wx_login_qocde == '402'">
            <div class="wx_login_loading_text1" style="color: #999">
              二维码已过期
            </div>
            <div
              class="wx_login_loading_text2"
              style="color: #3975c6; cursor: pointer"
              @click="get_wx_login_qcode"
            >
              点击刷新
            </div>
          </div>
          <div class="wx_login_loading_box" v-if="wx_login_qocde == '500'">
            <div class="wx_login_loading_text1" style="color: #999">
              未知错误
            </div>
            <div
              class="wx_login_loading_text2"
              style="color: #3975c6; cursor: pointer"
              @click="get_wx_login_qcode"
            >
              点击刷新
            </div>
          </div>
          <div class="wx_login_loading_box" v-if="wx_login_qocde == '666'">
            <div class="wx_login_loading_text1" style="color: #999">
              二维码已失效
            </div>
            <div
              class="wx_login_loading_text2"
              style="color: #3975c6; cursor: pointer"
              @click="get_wx_login_qcode"
            >
              点击刷新
            </div>
          </div>
          <div class="wx_login_loading_box" v-if="wx_login_qocde == '10086'">
            <div class="wx_login_loading_text1" style="color: #999">
              服务器繁忙
            </div>
            <div class="wx_login_loading_text2" style="color: #999">
              请稍后再试
            </div>
          </div>
        </div>
        <div class="show_wx_login_login_center_title1" @click="get_wx_login_qcode">二维码加载失败？</div>
        <div class="show_wx_login_login_center_title2">
          请使用微信扫描二维码登录“迷你CAD”
        </div>
      </div>
    </div>
  </el-dialog>
</template>



<script>
import { login_wx, login_wx_status, bind_wx_status_back } from "@/api/user";
export default {
  data() {
    return {
      wx_qcode: "",
      wx_login_qocde: "",
      wx_login_status_timer: "",
      uuid: '',
      wx_login_status_first_404: false,
    };
  },

  mounted() {
    var that = this
    // 监听其他组件调用上传文件
    this.$EventBus.$off("getWxQcode");
    this.$EventBus.$on("getWxQcode", function () {
      that.get_wx_login_qcode();
    });
  },

  methods: {
    handleClose() {},
    //获取微信二维码
    get_wx_login_qcode () {
      this.wx_login_qocde = '0'
      var that = this
      login_wx().then(res=>{
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          // 获取扫码状态
          that.uuid = data.data.uuid
          var wx_qrcode = data.data.wx_qrcode
          this.wx_login_qocde = '4088'
          that.get_wx_login_status_one(data.data.uuid)
          that.creatLoginQrCode(wx_qrcode)
        }
      }).catch(()=>{
        that.wx_status_code_back_html(10086)
      })
    },

    get_wx_login_status_one(uuid) {
      var send = {
        uuid: uuid
      }
      login_wx_status(send).then(res=>{
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          if (data.data.code != '') {
            this.get_wx_login_status(uuid, 3000)
          }
        }
      })
    },

    get_wx_login_status (uuid,time) {
      var send = { uuid: uuid }
      var that = this
      clearInterval(that.wx_login_status_timer);
      that.wx_login_status_timer = setInterval(function () {
         login_wx_status(send).then(res=>{
          var data = JSON.parse(res.data);
          if (data.code == 1) {
            var str = data.data
            var code = str.slice(18, 21)
            if (code == "402") {
              console.log('二维码已过期')
              that.wx_status_code_back_html(code)
            } else if (code == "500") {
              that.wx_status_code_back_html(code)
            } else if (code == "403") {
              that.wx_status_code_back_html(code)
              console.log('用户拒绝授权')
            } else if (code == "404") {
              that.wx_status_code_back_html(code)
              clearInterval(that.wx_login_status_timer)
              if (!that.wx_login_status_first_404) {
                setTimeout( function() {
                  that.wx_login_status_first_404 = true
                  that.get_wx_login_status(uuid,2000)
                }, 3000);
                return
              }
              that.get_wx_login_status(uuid,2000)
              console.log('等待用户授权')
            } else if (code == "408") {
              console.log('等待扫码')
              that.wx_status_code_back_html(code)
            } else if (code == "405") {
              console.log("扫码成功")
              var wx_code = str.slice(38, 70)
              that.wx_status_code_back_html(code)
              clearInterval(that.wx_login_status_timer);
              that.get_wx_login_status_back(uuid, wx_code)
            } else if (code == '666') {
              that.wx_status_code_back_html(code)
              console.log('666')
            }

          }
        }).catch(()=>{
          that.wx_status_code_back_html('10086')
        })
      },time)
    },

    //扫码成功回调
    get_wx_login_status_back(uuid, wx_code) {
      var that = this
      var send = {
        'uuid': uuid,
        'code': wx_code,
        'send_code': that.$store.state.wxbind_code,
      }
      bind_wx_status_back(send).then(res=>{
        var data = JSON.parse(res.data)
         if (data.code == 1) {
          that.$EventBus.$emit("resetUserinfoBus", 1);
        } else if (data.code == 10) {
          //有绑定，弹窗选择 强制还是暂不绑定
          that.$nextTick(()=>{
            that.$store.state.is_show_forcebind_box = true
          })
        } else {
          console.log(data.msg)
        }
      })
    },

    // 根据不同的code显示不同的文本
    wx_status_code_back_html(code){
      if (code == "402") {
      clearInterval(this.wx_login_status_timer);
      this.wx_login_qocde = code
    } else if (code == "403") {
      clearInterval(this.wx_login_status_timer);
      this.wx_login_qocde = code
    } else if (code == "404") {
      this.wx_login_qocde = code
    } else if (code == "500") {
      clearInterval(this.wx_login_status_timer);
      this.wx_login_qocde = code
    } else if (code == "408") {
      this.wx_login_qocde = code
    } else if (code == "405") {
      this.wx_login_qocde = code
    } else if (code == '666') {
      clearInterval(this.wx_login_status_timer);
      this.wx_login_qocde = code
    } else if (code == '10086') {
      clearInterval(this.wx_login_status_timer);
      this.wx_login_qocde = code
    }
    },

    creatLoginQrCode(url) {
      this.wx_qcode = url
    },
  },
};
</script>


<style lang="less" scoped>
.tanchuang-box .ctb-top,
.ctb-top {
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tan-ctb-title {
  font-size: 15px;
  color: #000;
  height: 64px;
  line-height: 64px;
  padding-left: 30px;
}

ctb-line {
  width: 560px;
  height: 1px;
  background-color: #e7e7e7;
  margin-left: 20px;
  transform: scaleY(0.5);
}

.tan-ctb-title1 {
  height: 64px;
  padding-left: 30px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 64px;
  letter-spacing: 0px;
  color: #000000;
}
.show_wx_login_login_center_title {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  letter-spacing: 0px;
  color: rgba(57, 117, 198, 1);
  margin-top: 30px;
  margin-bottom: 40px;
  user-select: none;
}

.show_wx_login_login_center_title1 {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: rgba(57, 117, 198, 1);
  margin-top: 30px;
  user-select: none;
  cursor: pointer;
}

.show_wx_login_login_center_title2 {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: rgba(51, 51, 51, 1);
  margin-top: 14px;
  user-select: none;
}

.show_wx_login_login_box,
.show_wx_login_login_box_bind {
  display: none;
  width: 341px;
  height: 425px;
  margin-top: 16px;
}

.show_wx_login_login_box_flex {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.show_wx_login_login_center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.show_wx_login_login_center_qcode_border {
  width: 188px;
  height: 188px;
  background-image: url("@/assets/images/common/1.png");
  background-position: 0 -23px;
  overflow: hidden;
  position: relative;
}

.show_wx_login_login_center_qcode {
  width: 158px;
  height: 158px;
  margin-top: 15px;
  margin-left: 15px;
}
.wx_login_loading_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #999;
  text-align: center;
}

.wx_login_loading_text2 {
  margin-top: 5px;
}

</style>