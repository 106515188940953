// 公共变量
var logoUrl = 'https://www.52cad.com'
var headerTitle = '迷你CAD-网页版'
var headerTitle1 = '迷你CAD-网页版'
var copyText = '【迷你CAD-网页版】'
var https_1 = 'https://www.52cad.com'
var loadingwww = 'www.52cad.com'
export default {
  logoUrl,
  headerTitle,
  headerTitle1,
  https_1,
  copyText,
  loadingwww
}