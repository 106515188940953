<template>
  <!-- 群弹窗 -->
  <div class="cloud-guanggao-big" v-if="$store.state.cloud_guanggao_action">
    <div class="cloud-guanggao-big-top">
      <div class="cloud-guanggao-big-top-header">
        创建您的共享群，即刻开启高效协同管理
      </div>
      <div class="cloud-guanggao-big-top-x" @click="exit_guanggao">
        <img src="@/assets/images/common/login_x.png" />
      </div>
    </div>
    <div class="cloud-guanggao-big-main">
      <div class="cloud-guanggao-big-main-left">
        <img src="@/assets/images/cloud/add_team_gg1.png" />
      </div>
      <div class="cloud-guanggao-big-main-right">
        <div class="cloud-guanggao-big-main-right-top">创建群</div>
        <div class="cloud-guanggao-big-main-right-main">
          创建群您将成为管理员，可通过分享群链接邀请成员，可与成员共享图纸，可管理成员及群文档，实时协作编辑
        </div>
        <div
          class="cloud-guanggao-big-main-right-btn"
          @click="go_to_MyteamCreate"
        >
          免费建群
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "GroupViewer",
  data() {
    return {};
  },
  methods: {
    exit_guanggao() {
      this.$store.state.cloud_guanggao_action = false;
      this.$store.state.mask = false
    },

    go_to_MyteamCreate() {
      this.$store.state.cloud_guanggao_action = false;
      this.$store.state.mask = false
      this.$EventBus.$emit("checkLeftMenu", 'my_team');
      setTimeout(() => {
        this.$store.state.is_show_add_team = true
      }, 200);
    },
  },
};
</script>

<style lang="less" scoped>
.cloud-guanggao-big {
  width: 994px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: -5px 5px 15px 1px rgb(0 0 0 / 10%);
  z-index: 55;
  background-color: #fff;
  border-radius: 4px;
  display: block;
}

.cloud-guanggao-big-top-x {
  width: 22px;
  height: 22px;
  background-size: cover;
  cursor: pointer;
  position: relative;
}

.cloud-guanggao-big-top-x img {
  width: 30px;
  height: 30px;
  position: absolute;
  top: -20px;
  right: -8px;
}

.cloud-guanggao-big-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 30px 10px 30px;
}

.cloud-guanggao-big-top-header {
  font-size: 20px;
  color: #000;
  width: 100%;
  text-align: center;
}

.cloud-guanggao-big-main {
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 20px 40px 20px;
}

.cloud-guanggao-big-main-left {
  width: 60%;
  background-size: cover;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.cloud-guanggao-big-main-left img {
  width: 458px;
  height: 340px;
}

.cloud-guanggao-big-main-right {
  width: 40%;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding-left: 40px;
}

.cloud-guanggao-big-main-right-top {
  font-size: 16px;
  color: #000;
}

.cloud-guanggao-big-main-right-main {
  font-size: 14px;
  color: #666;
  padding-top: 20px;
  padding-bottom: 30px;
}

.cloud-guanggao-big-main-right-btn {
  font-size: 14px;
  width: 100px;
  height: 30px;
  line-height: 30px;
  color: #fff;
  /* background-image: linear-gradient(129deg, #4383f0 0%, #50acf9 100%); */
  background-color: #2072EC;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}
</style>