<template>
  <el-dialog
    title="新建图纸"
    :visible.sync="$store.state.is_show_create_dwg_box"
    width="600px"
    :modal="true"
    :append-to-body="true"
    :close-on-click-modal="false"
    @close="handleClose"
  >
    <div class="tanchuang-box" id="dwg_add_box" style="display: block">
      <div class="ctb-line"></div>
      <div class="feedback_phone">
        <div class="feedback_phone_text">图纸名称：</div>
        <input
          class="feedback_phont_input"
          id="dwg_add_name_input"
          type="text"
          maxlength="30"
          name="feedback_phont_input____"
          placeholder="请输入您要新建的图纸名称"
          autocomplete='off'
          v-model="create_filename"
          @focus="is_show_el_tree = false"
        />
      </div>
      <div class="add_dwg_name_err_text" id="add_dwg_name_err_text"></div>
      <div class="feedback_path">
        <div class="add_dwg_select_title">图纸位置：</div>
        <div
          class="add_dwg_select_path"
          id="dwg_createfile_dir_input"
          title="点击选择图纸途径"
          @click="is_show_el_tree_btn"
        >
          {{ $store.state.cur_checked_path == "/" ? "我的图纸" : '我的图纸' + $store.state.cur_checked_path }}
        </div>

        <div class="dwg-createfile-dir-switch" @click="is_show_el_tree_btn"></div>

        <div
          class="dwg-createfile-dirs"
          id="dwg_createfile_dirs"
          v-if="is_show_el_tree"
        >
          <el-tree
            :data="pathData"
            :props="defaultProps"
            accordion
            default-expand-all
            :expand-on-click-node="false"
            @node-click="handleNodeClick"
          ></el-tree>
        </div>
      </div>
      <div class="ctb-btns">
        <div class="quxiao_btn_hover btn_no" id="dwg_add_btn_no" @click="dwg_add_btn_no">取消</div>
        <div id="dwg_add_btn_yes" class="btn_yes" @click="dwg_add_btn_yes">新建</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { getDirList, createNewDwg, getFileList } from "@/api/get_cloud";
import utils from "@/utils/utils";

export default {
  data() {
    return {
      is_show_el_tree: false,
      is_one: false,
      cur_checked_path: "/",
      create_filename: '新文件1',
      pathData: [
        {
          label: "我的图纸",
          routeName: "/",
          children: [],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
    };
  },

  methods: {
    handleClose() {
      this.$store.state.is_show_create_dwg_box = false;
      this.is_show_el_tree = false
    },
    is_show_el_tree_btn() {
      this.is_show_el_tree = !this.is_show_el_tree;
      if (this.is_show_el_tree == true) {
        if (!this.is_one) {
          this.getDirLists();
        }
      }
    },

    getDirLists() {
      var send = {
        p: "/",
      };
      getDirList(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          var arr = this.pathToTree1(data.datas);
          var newArr = [
            {
              label: "我的图纸",
              routeName: "/",
              children: [],
            },
          ];

          newArr[0].children = arr;
          this.pathData = newArr;
          this.is_one = true;
        }
      });
    },

    pathToTree1(input) {
      if (input.length == 0) return;
      let root = [];
      for (let i = 0; i < input.length; i++) {
        let chain = input[i].split("/");
        let currentHierarchy = root;
        for (let j = 0; j < chain.length; j++) {
          let wantedNode = chain[j];
          if (wantedNode === "") {
            continue;
          }

          let lastHierarchy = currentHierarchy;
          // 遍历root是否已有该层级
          for (let k = 0; k < currentHierarchy.length; k++) {
            if (currentHierarchy[k].label === wantedNode) {
              currentHierarchy = currentHierarchy[k].children;
              break;
            }
          }

          if (lastHierarchy === currentHierarchy) {
            let newNode = {
              // key: key,
              routeName: input[i],
              label: wantedNode,
              children: [],
            };
            currentHierarchy.push(newNode);
            currentHierarchy = newNode.children;
          }
        }
      }

      return root;
    },

    handleNodeClick(data) {
      this.$store.state.cur_checked_path = data.routeName
      this.set_cur_mubiao_path()
    },

    set_cur_mubiao_path() {
      var text = this.$store.state.cur_checked_path.slice(1);
      if (text.length > 0) {
        text = '/' + text;
      } else {
        text = '/';
      }

      this.$nextTick(function(){ //延伸步骤做伪刷新
        this.$store.state.cur_checked_path = text
      })
      this.is_show_el_tree = false
    },

    dwg_add_btn_yes () {
      var send = {
        parent_dir: this.$store.state.cur_checked_path,
				filename: this.create_filename + '.dwg',
      }
      if (this.create_filename == '') {
        this.$msg.whiteerr('图纸名称不能为空')
        return
      }
      createNewDwg(send).then((res)=>{
        var data = JSON.parse(res.data)
        if (data.code == 1) {
          this.$msg.white('新建图纸成功')
          this.$store.state.is_show_create_dwg_box = false;
          
          var f_id = data.f_id;
          let pathInfo = this.$router.resolve({
            path: "/dwg",
            query: {
              file_id: f_id,
            },
          });

          if (this.$route.path == '/my_drawings') {
            this.$EventBus.$emit("getMyTableData");
          }

          setTimeout(() => {
            window.open(pathInfo.href, "_blank");
          }, 1000);
        } else if (data.code == 2){
          this.$msg.whiteerr('当前位置已有此文件，请重新命名')
        } else if (data.code == 3) {
          this.$msg.whiteerr('创建失败，云盘空间已超出')
        } else if (data.code == 99) {
          this.$msg.whiteerr('登录信息已失效，请重新登录')
        }
      })
    },


    //获取我的图纸
    get_files1() {
      //选择创建的路径 == 当前路径的时候才刷新
      if (this.$store.state.cur_checked_path != this.$store.state.cur_parent_dir) {
        return
      }
      var send = {
        p: this.$store.state.cur_checked_path,
      };
      this.$store.state.tableData_loading_my_drawings = true;
      getFileList(send).then((res) => {
        var data = JSON.parse(res.data);
        var newData = [];
        data.forEach((item) => {
          item.is_show_share = false;
          item.checked = false;
          item.input = false
          if (item.type == "file") {
            item.size = utils.convert_size_MB(item.size);
          } else {
            item.size = "";
          }
          newData.push(item);
        });

        this.files_sort1(newData);
      });
    },

    //对获取的列表进行排序，文件在前面
    files_sort1(items) {
      var file_items = [];
      var dir_items = [];
      dir_items = items.filter((item) => item.type == "dir");
      file_items = items.filter((item) => item.type == "file");

      var new_Arr = [...dir_items, ...file_items];
      this.$store.state.tableData_my_drawings = new_Arr;
      this.$store.state.tableData_loading_my_drawings = false;
    },

    dwg_add_btn_no () {
      this.handleClose()
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 0 !important;
}

#dwg_add_box {
  height: 250px;
  width: 600px;
  border-radius: 5px;
  background-color: #fff;
  display: block;
  z-index: 105;
}

#dwg_add_box.tanchuang-box .ctb-top {
  width: 100%;
  height: 54px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

#dwg_add_box.tanchuang-box .tan-ctb-title {
  font-size: 16px;
  color: #000;
  height: 54px;
  line-height: 54px;
  padding-left: 20px;
}

.tanchuang-box .tanchuang_exit,
#feedback_box_exit {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-right: 15px;
}

.tanchuang-box .tanchuang_exit img,
#feedback_box_exit img {
  width: 100%;
  height: 100%;
}

.tanchuang-box .ctb-line {
  width: 560px;
  height: 1px;
  background-color: #e7e7e7;
  margin-left: 20px;
  transform: scaleY(0.5);
}

.tanchuang-box .ctb-word1 {
  font-size: 14px;
  color: #333;
  line-height: 20px;
  margin-top: 30px;
  margin-left: 20px;
}

.tanchuang-box .ctb-input-tname {
  margin-top: 30px;
  margin-left: 20px;
}

.tanchuang-box .ctb-input-tname textarea {
  width: 560px;
  outline: none;
  border-radius: 2px;
  font-size: 14px;
  color: #999;
  border: solid 1px #d8d8d8;
  padding: 13px;
  height: 120px;
  resize: none;
}

.tanchuang-box .ctb-input-tname textarea:focus {
  border: solid 1px #2072EC;
}

.tanchuang-box .ctb-word2 {
  line-height: 20px;
  font-size: 13px;
  color: #333;
  font-weight: bold;
  margin-top: 60px;
  margin-left: 20px;
}

#dwg_add_box.tanchuang-box .ctb-master-user {
  display: flex;
  flex-direction: revert;
  margin-left: 20px;
  margin-top: 20px;
}

#dwg_add_box.tanchuang-box .ctb-btns {
  display: flex;
  flex-direction: row;
  margin-top: 0;
  padding: 0;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 20px 20px 0;
}

#dwg_add_box.tanchuang-box .ctb-btns div {
  width: 70px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 2px;
  font-size: 12px;
  cursor: pointer;
  border: 1px solid transparent;
}

#dwg_add_box #dwg_add_btn_no,
#dwg_add_box.tanchuang-box .btn_no {
  border: 1px solid #ced0d1;
  color: #999;
}

#dwg_add_box.tanchuang-box .btn_yes,
#dwg_add_box #dwg_add_btn_yes {
  background-color: #2072EC;
  color: #fff;
  margin-left: 10px;
}

#dwg_add_box.tanchuang-box .btn_no:hover,
#dwg_add_box #dwg_add_btn_no:hover {
  color: #2072EC;
  border: solid 1px #2072EC;
}

#dwg_add_box .feedback_phone {
  padding: 20px 0 0 20px;
  height: 90px;
  font-size: 14px;
  display: block;
}

#dwg_add_box .feedback_path {
  padding: 0 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
}

.feedback_phone_text {
  width: 550px;
  margin-left: 10px;
  margin-bottom: 10px;
}

#dwg_add_box .feedback_phone input,
#dwg_add_box .feedback_path input {
  width: 550px;
  margin-left: 10px;
  height: 30px;
  line-height: 30px;
  outline: none;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  text-indent: 13px;
  color: #333;
  font-size: 14px;
}

#dwg_add_box .feedback_phont_input:focus {
  border: 1px solid #4383f0;
}

#dwg_add_box .feedback_phont_input_err {
  border: solid 1px #f04343;
}

#dwg_add_box .feedback_phont_input_err:focus {
  border: solid 1px #f04343;
}

#feedback_input,
.feedback_phont_input {
  font-family: MicrosoftYaHei;
}

.feedback_yes_main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 30px);
}

.feedback_yes_main_title {
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0px;
  color: #333;
  margin-top: 20px;
}

.feedback_yes_main_text {
  margin-top: 15px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0px;
  color: #333;
}

#feedback_yes_no {
  margin-top: 24px;
  color: #fff;
  width: 80px;
  height: 24px;
  background-color: #454f61;
  border-radius: 2px;
  border: solid 1px #687689;
  line-height: 24px;
  text-align: center;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0px;
  color: #fefefe;
  user-select: none;
  cursor: pointer;
}

#feedback_yes {
  display: none;
  height: calc(100% - 64px);
}

#dwg_add_name_input {
  height: 40px !important;
}

/* #dwg_add_box {
    height: 260px;
  } */

.add_dwg_select_title {
  margin-left: 10px;
  font-size: 14px;
}

.add_dwg_select_path {
  margin-left: 20px;
  width: 460px;
  position: relative;
  height: 28px;
  line-height: 28px;
  user-select: none;
  cursor: pointer;
  font-size: 14px;
}

.add_dwg_select_path::after {
  content: "";
  height: 1px;
  width: 460px;
  background-color: #d8d8d8;
  position: absolute;
  left: 0;
  bottom: 0;
}

.dwg-createfile-dirs {
  position: absolute;
  bottom: -110px;
  right: 20px;
  width: 454px;
  height: 200px;
  background-color: #fff;
  padding-top: 4px;
  padding-left: 5px;
  overflow-y: auto;
  overflow-x: auto;
  font-size: 14px;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  user-select: none;
}

.dwg-createfile-dirs div {
  line-height: 26px;
  cursor: pointer;
}

.dwg-createfile-dirs-active {
  background-color: #f0f4fc;
}

#dwg_createfile_dir_input {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.dwg-createfile-name-tip {
  position: absolute;
  top: 104px;
  left: 92px;
  font-size: 12px;
  color: #f45e55;
}

.dwg-createfile-close {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 15px;
  right: 10px;
  cursor: pointer;
}

.dwg-createfile-close img {
  width: 30px;
}

#dwg_createfile_name_input_empty {
  width: 16px;
  height: 16px;
  background-image: url(@/assets/images/dwg_new/dwg_sprite.png);
  background-position: -16px -393px;
  position: absolute;
  right: 32px;
  top: 80px;
  cursor: pointer;
}

.dwg-createfile-dir img {
  width: 20px;
  height: 26px;
}

.dwg-createfile-dir {
  width: 100%;
}

.dwg-createfile-dir-switch {
  width: 15px;
  height: 15px;
  background-image: url("@/assets/images/dwg_new/dwg_sprite.png?v=1");
  background-position: 19px 286px;
  position: absolute;
  right: 20px;
  cursor: pointer;
}
</style>