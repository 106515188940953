<template>
  <!-- 群设置 -->
  <el-dialog
    title="添加云盘文件"
    :visible.sync="$store.state.is_show_team_add_cloudfile"
    width="600px"
    :modal="true"
    :append-to-body="true"
    @close="add_cloudFiles_close"
    class="team_addcloud_files"
  >
    <div class="team-jion-main">
      <div class="team-jion-main-title">请选择文件：</div>
      <div class="team-jion-main-files-box">
        <div class="team-jion-main-file-top">
          <div id="team-jion-cloud_path">
            <el-breadcrumb
              separator="/"
              style="
                font-size: 16px;
                width: 100%;
                white-space: nowrap;
                overflow: hidden;
              "
            >
              <el-breadcrumb-item
                style="height: 15px"
                v-for="(item, i) in cur_cloud_paths"
                :key="i"
                @click.native="go_to_cloud_path(item.path, i)"
                >{{ item.text }}</el-breadcrumb-item
              >
            </el-breadcrumb>
          </div>
        </div>
        <div class="team-jion-main-file-list">
          <div
            class="file-item"
            v-for="(item, i) in add_items"
            :key="i"
            @click="click_file_item(item, i)"
          >
            <div class="dir-name">
              <img :src="item.type == 'file' ? is_file : is_dir" />
              <div class="dir-name-word">{{ item.name }}</div>
            </div>
            <div class="item-gouxuan" v-if="item.type != 'dir' && item.checked">
              <img src="@/assets/images/team/success.png" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="team-jion-foot">
      <div></div>
      <!-- <div class="team-jion-foot-left team-jion-foot-item" id="team-jion-foot-addfile"></div> -->
      <div class="team-jion-foot-right">
        <div
          class="team-jion-foot-item"
          id="team-jion-foot-exit"
          @click="addCloudFile_btn_no"
        >
          取消
        </div>
        <div
          class="team-jion-foot-item"
          id="team-jion-foot-submit"
          @click="addCloudFile_btn_yes"
        >
          确定
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import { getFileList, move_team_file } from "@/api/get_cloud";
import utils from '@/utils/utils';

export default {
  name: "AddCloudFileView",
  data() {
    return {
      add_items: [],
      cur_cloud_paths: [{ text: "我的云图纸", path: "/" }],
      cur_parent_dir: "/",
      is_file: require("@/assets/images/cloud/file.png"),
      is_dir: require("@/assets/images/cloud/dir.png"),
      userinfo: "",
      uid: "",
    };
  },

  mounted() {
    var userinfo = JSON.parse(localStorage.getItem("USER_INFO"));
    if (userinfo != null) {
      this.userinfo = userinfo;
      this.uid = userinfo.uid;
    }
  },

  computed: {
    is_show_team_add_cloudfile() {
      return this.$store.state.is_show_team_add_cloudfile;
    },
  },

  methods: {
    getCloudLists() {
      var send = {
        p: "/",
      };
      this.add_cloudfile_get_files(send);
    },

    //获取我的图纸
    add_cloudfile_get_files(send) {
      getFileList(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 99) {
          this.$parent.login_expired_team();
          return;
        }
        var newData = [];
        data.forEach((item) => {
          item.checked = false;
          var lastName = utils.getExtensiona(item.name)
          if (lastName == '.dwg' || lastName == '.dxf' || item.type == 'dir') {
            newData.push(item);
          }
        });

        this.teamaddfiles_sort(newData);
      });
    },

    //对获取的列表进行排序，文件在前面
    teamaddfiles_sort(items) {
      var file_items = [];
      var dir_items = [];
      dir_items = items.filter((item) => item.type == "dir");
      file_items = items.filter((item) => item.type == "file");
      var new_Arr = [...dir_items, ...file_items];
      this.add_items = new_Arr;
    },

    //设置当前路径组
    set_cloud_path(text, path) {
      this.cur_cloud_paths.push({
        text: text,
        path: path,
      });
    },

    //点击从路径跳转
    go_to_cloud_path(path, i) {
      var data = {
        p: path,
      };
      // if (this.cur_parent_dir == path) {
      //   return;
      // }
      this.cur_parent_dir = path;
      this.add_cloudfile_get_files(data);
      this.add_cloudfile_update_cloud_path(i);
    },

    //修改路径组
    add_cloudfile_update_cloud_path(i) {
      if (i == 0) {
        this.cur_cloud_paths = [{ text: "我的云图纸", path: "/" }];
      } else {
        this.cur_cloud_paths.splice(i + 1);
      }
    },

    click_file_item(item, i) {
      if (item.type == "file") {
        this.add_items[i].checked = !this.add_items[i].checked;
      } else if (item.type == "dir") {
        var path = item.parent_dir + "/" + item.name;
        path = path.replace("//", "/");
        var data = {
          p: path,
        };
        this.set_cloud_path(item.name, path);
        this.add_cloudfile_get_files(data);
      }
    },

    add_cloudFiles_close() {
      this.$store.state.is_show_team_add_cloudfile = false;
      this.cur_cloud_paths = [{ text: "我的云图纸", path: "/" }];
      this.cur_parent_dir = "/";
    },

    addCloudFile_btn_no() {
      this.add_cloudFiles_close();
    },

    addCloudFile_btn_yes() {
      var paths = [];
      var p;
      this.add_items.forEach((item) => {
        if (item.checked) {
          if (item.parent_dir !== "/") {
            p = item.parent_dir + "/" + item.name;
          } else {
            p = item.parent_dir + item.name;
          }
          paths.push(p);
        }
      });

      if (paths.length == []) {
        this.$msg.whiteerr('未选择任何文件')
        return
      }

      var send = {
        t_id: this.$store.state.cur_checke_tid,
        uid: this.uid,
        paths: JSON.stringify(paths),
      };
      
      move_team_file(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          this.$msg.white('已成功添加文件')

          this.$parent.get_team_t_id(this.$store.state.cur_checke_tid)
          setTimeout(() => {
            this.add_cloudFiles_close()
          }, 200);
				} else if (data.code == 99) {
          this.$parent.login_expired_team()
        }
      });
    },

    click_exit_team(type) {
      this.$parent.show_exit_team_box(type);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  color: #2072EC !important;
}

/deep/ .el-breadcrumb__inner {
  cursor: pointer;
}
.team-jion-main-title {
  font-size: 15px;
  color: #333;
}

.team-jion-main-file-top span {
  font-size: 15px;
  color: #2072EC;
  user-select: none;
  cursor: pointer;
}

.team-jion-main-file-top span:hover {
  color: #2072EC;
}

.team-jion-main-file-top {
  margin-top: 10px;
  display: flex;
  align-items: center;
}

.team-jion-main-file-list {
  width: 100%;
  height: 200px;
  max-height: 200px;
  overflow: auto;
  margin-top: 10px;
}

.team-jion-main-file-list .file-item .dir-name {
  height: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
}

.team-jion-main-file-list .file-item .file-name {
  height: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
}

.team-jion-main-file-list .file-item .dir-name img {
  width: 26px;
  height: 30px;
}

.team-jion-main-file-list .file-item img {
  width: 20px;
  height: 22px;
  margin-top: 0;
}

.team-jion-foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

#team-jion-foot-exit {
  border: 1px solid #ced0d1;
  color: #999;
}

#team-jion-foot-exit:hover {
  color: #2072EC;
  border: solid 1px #2072EC;
}

#team-jion-foot-addfile {
  border: 1px solid #ced0d1;
  color: #666;
  padding: 0 20px;
}

#team-jion-foot-submit {
  background-color: #2072EC;
  color: #fff;
  margin-left: 10px;
}

#team-jion-foot-exit:hover,
#team-jion-foot-addfile:hover,
#team-jion-foot-submit:hover {
  opacity: 0.8;
}

.team-jion-foot-item {
  width: 70px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 2px;
  font-size: 14px;
  cursor: pointer;
}

.team-jion-foot-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-item {
  position: relative;
}

.item-gouxuan {
  position: absolute;
  left: 0;
  top: 0;
}
/*定义滚动条样式*/
/* ::-webkit-scrollbar {
		width: 1px;
		height: 6px;
		background-color: rgba(240, 240, 240, 1);
	} */

/*定义滚动条轨道内阴影+圆角*/
/* ::-webkit-scrollbar-track {
		box-shadow: inset000pxrgba(240, 240, 240, .5);
		border-radius: 10px;
		background-color: rgba(240, 240, 240, .5);
	} */

/*定义滑块内阴影+圆角*/
/* ::-webkit-scrollbar-thumb {
		border-radius: 10px;
		box-shadow: inset000pxrgba(240, 240, 240, .5);
		background-color: rgba(240, 240, 240, .5);
	} */
</style>