<template>
  <!-- 群设置 -->
  <el-dialog
    title="群设置"
    :visible.sync="$store.state.is_show_team_setting"
    width="456px"
    :modal="true"
    :append-to-body="true"
    @close="handleTeamSetClose"
    class="my_team_diglog"
  >
    <div class="team-set-box" style="display: block; height:316px" v-if="$store.state.get_team_data != null">
      <div class="ts-word1">群名称</div>
      <div class="ts-set-tname">
        <div id="ts_tname">{{$store.state.get_team_data.tname}}</div>
        <div id="ts_set_tname" style="display: block" @click="teamSetUpdateName">修改</div>
      </div>
      <div class="ts-word2">成员管理</div>
      <div class="ts-look-member">
        <div class="tslm-pnum-out">
          <div class="tslm-avatar">
            <img src="@/assets/images/team/avatar.png" />
          </div>
          <div class="tslm-pnum"><span id="ts_team_pnum">{{$store.state.get_team_data.p_num}}</span>名成员</div>
        </div>
        <div id="ts_look_member" @click="lookMember">查看</div>
      </div>
      <div class="ts-line2"></div>
      <div class="ts_team_break_test" v-if="mu_id == uid">解散群</div>
      <div class="ts_tip_btn_box" v-if="mu_id == uid">
        <div id="ts_team_break_tip">解散后，该群的所有内容将被删除。</div>
        <div id="ts_team_break_btn" @click="click_exit_team('break')">解散群</div>
      </div>

      <div class="ts_team_break_test" v-if="mu_id != uid">退出群</div>
      <div class="ts_tip_btn_box" v-if="mu_id != uid">
        <div id="ts_team_break_tip">退出后，该群的所有内容你将查看不到。</div>
        <div id="ts_team_break_btn" @click="click_exit_team('quit')">退出群</div>
      </div>
      
    </div>
  </el-dialog>
</template>
<script>
// import { create_team } from "@/api/get_cloud";

export default {
  name: "TeamSetView",
  data() {
    return {
      create_name: "",
      autofocus: true,
      userinfo: "",
      mu_id: '',
      uid: ''
    };
  },

  mounted() {
    var userinfo = JSON.parse(localStorage.getItem("USER_INFO"));
    if (userinfo != null) {
      this.userinfo = userinfo;
      this.uid = userinfo.uid
    }
    
  },

  methods: {
    setDataValue () {
      this.mu_id = this.$store.state.get_team_data.mu_id
    },

    handleTeamSetClose() {
      this.$store.state.is_show_team_setting = false;
    },

    cancel_create_team() {
      this.handleAddTeamClose();
    },

    yes_create_team() {},

    teamSetUpdateName () {
      this.$parent.show_updateName_box();
    },

    lookMember() {
      this.$parent.right_menus_click('add')
    },

    click_exit_team (type){
      this.$parent.show_exit_team_box(type)
    },
  },
};
</script>

<style lang="less" scoped>
.team-set-box {
  display: block;
}

.ts-top {
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.ts-top-word {
  font-size: 15px;
  color: #333;
  height: 64px;
  line-height: 64px;
  margin-left: 20px;
}

#exit_team_set {
  width: 30px;
  height: 30px;
  margin-right: 13px;
  cursor: pointer;
}

#exit_team_set img {
  width: 100%;
  height: 100%;
}

.ts-line1 {
  width: 416px;
  height: 1px;
  background-color: #d8d8d8;
  margin-left: 20px;
}

.ts-word1 {
  font-size: 14px;
  color: #333;
  line-height: 20px;
  margin-left: 20px;
}

.ts-set-tname {
  position: relative;
  height: 24px;
  margin-left: 20px;
  margin-top: 10px;
}

#ts_tname {
  font-size: 14px;
  color: #333;
  float: left;
}

#ts_set_tname {
  font-size: 14px;
  color: #2072EC;
  cursor: pointer;
  float: right;
  margin-right: 20px;
  display: none;
}

.ts-word2 {
  font-size: 14px;
  color: #333;
  line-height: 20px;
  margin-top: 30px;
  margin-left: 20px;
}

.ts-look-member {
  position: relative;
  margin-left: 20px;
  height: 24px;
  margin-top: 10px;
}

.tslm-pnum-out {
  float: left;
  display: flex;
  flex-direction: row;
}

.tslm-avatar {
  width: 24px;
  height: 24px;
}

.tslm-avatar img {
  width: 100%;
  height: 100%;
}

.tslm-pnum {
  margin-left: 10px;
  font-size: 12px;
  line-height: 24px;
  color: #666;
}

#ts_look_member {
  float: right;
  font-size: 14px;
  color: #2072EC;
  cursor: pointer;
  margin-right: 20px;
}

.ts-line2 {
  width: 416px;
  height: 1px;
  background-color: #d8d8d8;
  margin-left: 20px;
  margin-top: 30px;
}

#ts_team_break_btn {
  font-size: 14px;
  color: #f04343;
  cursor: pointer;
  margin-right: 20px;
}

.ts_team_break_test {
  font-size: 14px;
  color: #666;
  margin-left: 20px;
  margin-top: 30px;
}

#ts_team_break_tip {
  font-size: 14px;
  color: #999;
  margin-left: 20px;
}

.ts_tip_btn_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

/*定义滚动条样式*/
/* ::-webkit-scrollbar {
		width: 1px;
		height: 6px;
		background-color: rgba(240, 240, 240, 1);
	} */

/*定义滚动条轨道内阴影+圆角*/
/* ::-webkit-scrollbar-track {
		box-shadow: inset000pxrgba(240, 240, 240, .5);
		border-radius: 10px;
		background-color: rgba(240, 240, 240, .5);
	} */

/*定义滑块内阴影+圆角*/
/* ::-webkit-scrollbar-thumb {
		border-radius: 10px;
		box-shadow: inset000pxrgba(240, 240, 240, .5);
		background-color: rgba(240, 240, 240, .5);
	} */
</style>