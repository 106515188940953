<template>
  <el-container class="layout-container">
    <el-header>
      <div class="header-container">
        <div class="logo-box">
          <div class="logo-img" style="display: flex">
            <img src="@/assets/images/logo.png" />
          </div>
          <div class="logo-word" style="display: flex; align-items: center; font-weight: 600">
            <div class="nav-logo-text" style="font-family: PingFang SC, Microsoft YaHei">
              {{ headerTitle }}
            </div>
          </div>
        </div>
        <div class="header_right">
          <div class="user_info">
            <router-link to="/privatecloudsdk" id="feedback_show" class="syy_sdk" target="_blank"
              >企业专属的在线CAD-私有化部署方案 &gt;</router-link
            >
            <!-- <router-link to="/privatecloudsdk" id="feedback_show" target="_blank">私有云SDK</router-link> -->
            <div class="user-info-line">|</div>
            <el-dropdown>
              <span class="el-dropdown-link feedback_show" id="feedback_show"> 下载客户端 </span>
              <el-dropdown-menu slot="dropdown">
                <div class="header_down_item" id="down_app_btn" @click="down_kehuduan_btn">
                  <img src="@/assets/images/common/down_win.png" alt="" />
                  <span>Windows版本</span>
                </div>
                <div
                  class="header_down_item"
                  id="show_app_sao_code_box"
                  @mouseover="is_show_down_app_qocde = true"
                  @mouseout="is_show_down_app_qocde = false"
                >
                  <img src="@/assets/images/common/down_app.png" alt="" />
                  <span>App扫码下载</span>
                  <div class="app_sao_code_box" v-show="is_show_down_app_qocde">
                    <div class="app_sao_code">
                      <img src="@/assets/images/common/basic.png" alt="" />
                      <div class="app_sao_code_text">手机扫码下载</div>
                      <div class="app_sao_code_text1">支持Android / IOS</div>
                    </div>
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="user-info-line">|</div>
            <a class="qqkefu" href="https://www.52cad.com/cloud/api/jump_qq" target="_blank"
              >QQ客服</a
            >
            <!-- <span id="feedback_show" class="feedback_show" @click="feedbackVisible = true"
              >意见反馈</span
            > -->
            <div class="user-info-line">|</div>
            <Feedback
              :feedbackVisible="feedbackVisible"
              v-if="feedbackVisible"
              @change_feedback_show="change_feedback_show"
            ></Feedback>
            <el-dropdown>
              <div class="avatar-wrap">
                <div class="avatar" style="display: block">
                  <img id="avatar" :src="is_wx == 0 ? avatar : wx_avatar" />
                </div>
                <div id="user_account" style="display: block" v-if="is_wx == 0">
                  {{ user_name == "" || user_name == null ? account : user_name }}
                </div>
                <div id="user_account" style="display: block" v-if="is_wx == 1">
                  {{ wx_nickname }}
                </div>
                <div class="vip_avatar" v-show="is_vip == 1 || is_qy_vip == 1">
                  <img id="vip_avatar" src="@/assets/images/common/name_vip.png" />
                </div>
              </div>
              <el-dropdown-menu slot="dropdown">
                <div class="user-card">
                  <div class="uc-top">
                    <div class="uct-avatar">
                      <img id="uc_avatar" :src="is_wx == 0 ? avatar : wx_avatar" />
                    </div>
                    <div class="uct-info">
                      <div id="user_nickname">
                        <span v-if="is_wx == 0">{{
                          user_name == "" || user_name == null ? account : user_name
                        }}</span>
                        <span v-if="is_wx == 1"> {{ wx_nickname }}</span>
                        <img
                          id="vip_avatar_biaozhi"
                          src="@/assets/images/common/name_vip.png"
                          v-if="is_vip == 1 || is_qy_vip == 1"
                        />
                      </div>
                      <div id="vip_endtime">{{ vip_endtime }}</div>
                      <div id="vip_adword">{{ vip_title }}</div>
                    </div>
                    <div id="immediately_buy_vip" @click="is_show_pay_btn()">{{ by_vip_btn }}</div>
                  </div>
                  <div class="uc-cloud-progress">
                    <div class="uc-cloud-size-word">
                      <div class="uc-csw-l">
                        <span class="cloud-usage">{{ $store.state.usage }}</span
                        >/<span class="cloud-total">{{ $store.state.total }}</span>
                      </div>
                      <!-- <div id="uc_extend_cloud">扩充</div> -->
                    </div>
                    <!-- <div id="uc_cloud_progress">
                      <div id="uc_cur_cloud_progress" style="width: 0%"></div>
                    </div> -->
                    <el-progress
                      :show-text="false"
                      :percentage="$store.state.percentage > 100 ? 100 : $store.state.percentage"
                      :color="$store.state.customColors"
                    ></el-progress>
                  </div>
                  <div>
                    <div id="to_userCenter" @click="goToUserCenter">个人中心</div>
                    <!-- <router-link to="/bip" id="to_userCenter">个人中心</router-link> -->
                    <div id="login_out" @click="onLogout">退出登录</div>
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <!-- <div id="bug_vip" style="display: block" @click="is_show_pay_btn()">{{ by_vip_btn }}</div> -->
          </div>
        </div>
      </div>
    </el-header>
    <div class="container">
      <ShareView></ShareView>
      <CreateDwgView />
      <GuideViewer></GuideViewer>
      <GroupViewer></GroupViewer>
      <PayView v-show="$store.state.is_show_pay"></PayView>
      <el-aside width="230px">
        <app-aside :is-collapse="isCollapse" :user_uid="uid" />
      </el-aside>
      <UploadHistory v-if="$store.state.is_show_up_history" />
      <router-view :user_uid="uid" />
    </div>
    <MaskLayer v-show="$store.state.mask"></MaskLayer>
  </el-container>
</template>

<script>
import AppAside from "./aside";
import Feedback from "@/components/feedback.vue";
import UploadHistory from "@/components/upload_history.vue";
import PayView from "@/components/pay.vue";
import MaskLayer from "@/components/mask.vue";
import ShareView from "@/components/share_box.vue";
import CreateDwgView from "@/components/new_dwg_box.vue";
import GuideViewer from "./components/guide.vue";
import GroupViewer from "./components/group_gg.vue";

import { login_out, getUsage, usertoken_login } from "@/api/user";
import utils from "@/utils/utils";
import Common from "@/utils/common";

export default {
  name: "LayoutIndex",
  data() {
    return {
      headerTitle: Common.headerTitle,
      account: "",
      user_name: "",
      avatar: require("@/assets/images/avatar.png"),
      wx_avatar:
        "https://thirdwx.qlogo.cn/mmopen/vi_32/Q3auHgzwzM4dlicHVVPfGuPIga21ovvhtKCxRQHOeLXBicUFJsd4TZDUHrp6QBLL7VZgC1V1yxdr0nEuoF76kpkg/132",
      vip_endtime: "",
      is_vip: 0,
      is_qy_vip: 0,
      is_wx: 0,
      qy_vip_endtime: "",
      uid: "",
      user_token: "",
      usage: "0M",
      total: "100M",
      wx_nickname: "",
      vip_title: "",
      cur_show_name: "",
      by_vip_btn: "升级账户",
      isCollapse: false, // 侧边栏控制
      feedbackVisible: false, //控制意见反馈
      feedback_value: "", //意见反馈输入框
      feedback_phont_value: "", //意见反馈手机号
      is_show_history: false, //显示隐藏上传记录
      is_mshare: false,
      is_show_down_app_qocde: false, //显示下载APP二维码
    };
  },
  props: {},
  components: {
    AppAside,
    Feedback,
    UploadHistory,
    PayView,
    MaskLayer,
    ShareView,
    CreateDwgView,
    GroupViewer,
    GuideViewer,
  },
  computed: {},
  watch: {},
  created() {
    // this.loadUserProfuile()
    this.GetLocalStorageUserinfo();

    // var path = this.$route.path

    // if (path == '/recently_opened') {

    // }

    window.vue = this;
  },
  mounted() {
    this.$nextTick(() => {
      // 禁止右键
      document.oncontextmenu = new Function("event.returnValue=false");
      // 禁止F12
      // document.onkeydown = new Function("event.returnValue=false");
    });
    //单个页面   想要所有页面试试 app.vue
    var that = this;
    this.$EventBus.$off("reflushUserinfoIndex");
    this.$EventBus.$on("reflushUserinfoIndex", function () {
      // 开始调用方法
      that.GetLocalStorageUserinfo();
    });
  },
  methods: {
    // loadUserProfuile() {
    //   getUserProfile().then((res) => {
    //     console.log(res);
    //     this.user = res.data.data;
    //   });
    // },

    goToUserCenter() {
      this.$nextTick(() => {
        this.$router.push("/usercenter");
      });
    },

    sumbit_feedback() {
      this.feedbackVisible = false;
      console.log(this.feedback_value, this.feedback_phont_value);
    },

    show_feedback_box() {
      this.feedbackVisible = true;
    },

    //接收自组建改变变量
    change_feedback_show(data) {
      this.feedbackVisible = data;
    },

    //下载客户端
    down_kehuduan_btn() {
      utils.down_kehuduan();
    },

    onLogout() {
      this.$confirm("确认退出吗？", "退出提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.login_out_funtion();
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    login_out_funtion() {
      login_out().then(() => {
        this.$message({
          type: "success",
          message: "退出成功!",
        });
        utils.del_localStorae();

        sessionStorage.clear();
        this.$router.push("/");
      });
    },

    GetLocalStorageUserinfo() {
      var user_info = JSON.parse(window.localStorage.getItem("USER_INFO"));
      if (user_info != null) {
        this.account = user_info.account;
        this.user_name = user_info.user_name;
        this.vip_endtime = user_info.vip_endtime;
        this.is_wx = user_info.is_wx;
        this.is_vip = user_info.is_vip;
        this.is_qy_vip = user_info.is_qy_vip;
        this.qy_vip_endtime = user_info.qy_vip_endtime;
        this.uid = user_info.uid;
        this.wx_avatar = user_info.wx_avatar;
        this.wx_nickname = user_info.wx_nickname;
        this.user_token = user_info.user_token;
        if (user_info.is_qy_vip == "1" || user_info.is_vip == "1") {
          var show_endtime =
            user_info.is_qy_vip == "1" ? user_info.qy_vip_endtime : user_info.vip_endtime;
          if (user_info.vip_endtime) {
            show_endtime = user_info.vip_endtime;
          }
          this.vip_endtime = "到期时间：" + show_endtime;
          this.vip_title = "您已享受所有会员功能";
          this.by_vip_btn = "立即续费";
        } else {
          this.vip_endtime = "暂未购买服务";
          this.vip_title = "购买服务享受所有功能";
          this.by_vip_btn = "升级账户";
        }

        this.get_Usage(user_info);
      } else {
        this.auto_login();
      }
    },

    get_Usage(user_info) {
      getUsage().then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 99) {
          // this.auto_login();
          this.login_expired_index();
        } else {
          this.$store.state.userinfo = user_info;
          this.$store.state.usage = utils.convert_size_MB(data.usage);
          this.$store.state.total = utils.convert_size_MB(data.total);
          this.$store.state.percentage = ((data.usage / data.total) * 100).toFixed(0) - 0;
        }
      });
    },

    // 自动登录
    auto_login() {
      var USER_INFO_TIME = parseInt(localStorage.getItem("USER_INFO_TIME"));
      var cur_time = new Date().getTime();
      if (cur_time < 7 * 86400 * 1000 + USER_INFO_TIME || USER_INFO_TIME == null) {
        // 7天内登录过
        var USER_TOKEN = JSON.parse(localStorage.getItem("USERTOKEN"));
        if (USER_TOKEN != null) {
          //token存在
          this.auto_usertoken_login(USER_TOKEN);
        } else {
          //token不存在
          this.login_expired_index();
        }
      } else {
        this.login_expired_index();
      }
    },

    //登录过期，回到首页
    login_expired_index() {
      this.$msg.whiteerr("登录信息已过期，请重新登陆");
      utils.del_localStorae();
      this.$router.push("/");
    },

    auto_usertoken_login(user_token) {
      var data = {
        user_token,
      };
      usertoken_login(data).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          var __userinfor = data.user_info;
          localStorage.setItem("USER_INFO_TIME", new Date().getTime());
          localStorage.setItem("USER_INFO", JSON.stringify(__userinfor));
          localStorage.setItem("USERTOKEN", JSON.stringify(__userinfor.user_token));
          this.$store.state.userinfo = __userinfor;
          if (this.$route.path == "/dwgsee" || this.$route.path == "/dwg") {
            this.$EventBus.$emit("reflushUserinfo");
          } else if (this.$route.path == "/usercenter") {
            this.$EventBus.$emit("reflushUserinfoCenter");
          } else if (this.$route.path == "/my_drawings") {
            this.$EventBus.$emit("reflushUserinfoIndex");
            this.$EventBus.$emit("getMyTableData");
          } else if (this.$route.path == "/my_team") {
            this.$EventBus.$emit("reflushUserinfoIndex");
            this.$EventBus.$emit("getTeams");
          } else {
            this.$EventBus.$emit("reflushUserinfoIndex");
          }
        } else {
          this.login_expired_index();
        }
      });
    },

    is_show_pay_btn() {
      this.$store.state.is_show_pay = true;
      this.$store.state.mask = true;
    },
  },
};
</script>

<style scoped lang="less">
.el-progress {
  width: 296px;
  margin: 0;
}

/deep/ .el-progress .el-progress-bar__outer {
  background: #ced0d1;
}

.el-header {
  padding: 0;
}

.vip_avatar {
  margin: 0 5px 0 5px;
  display: flex;
}

#vip_avatar,
#vip_avatar_biaozhi {
  width: 16px;
  height: 16px;
}

#vip_avatar_biaozhi {
  margin: 0 5px;
}

/deep/ .el-header {
  height: 56px !important;
}

.layout-container {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.container {
  display: flex;
  height: calc(100% - 57px);
}

.aside {
  background-color: #d3dce6;
}

.header {
  background-color: #b3c0d1;
}

.main {
  background-color: #e9eef3;
}

.header-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 1180px;

  .avatar-wrap {
    display: flex;
    align-items: center;

    .avatar {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 5px;
    }
  }

  .header_right {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
  }

  .user_info {
    float: right;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  #feedback_show {
    font-size: 15px;
    color: #333;
    cursor: pointer;
  }

  #feedback_show:hover {
    color: #2072ec;
  }

  .user-info-line {
    font-size: 20px;
    color: #979797;
    margin-left: 13px;
    margin-right: 13px;
    /* margin-top: 28px; */
  }

  .avatar {
    width: 30px;
    height: 30px;
    display: none;
    padding: 13px 0;
  }

  .avatar img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  #user_account {
    height: 56px;
    line-height: 56px;
    display: none;
    color: #333;
    font-size: 14px;
    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  #bug_vip {
    width: 84px;
    height: 30px;
    background-color: #2072ec;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    text-align: center;
    line-height: 30px;
    font-size: 12px;
    margin-left: 11px;
  }
}

/deep/ .el-header {
  min-width: 1080px;
  background: #f3f6fb;
}

.user-card {
  width: 316px;
  height: 236px;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(137, 136, 143, 0.16), 0px 1px 11px 0px rgba(115, 115, 115, 0.15);
  border: 1px solid #e7e7e7;
  position: absolute;
  top: -15px;
  right: 0px;
}

.uc-top {
  width: 296px;
  height: 102px;
  margin-top: 10px;
  margin-left: 11px;
  border-radius: 4px;
  background-image: url("@/assets/images/user_info_bg.png");
  position: relative;
}

.uct-avatar {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 21px;
  left: 11px;
}

.uct-avatar img {
  width: 100%;
  border-radius: 100%;
}

#user_nickname {
  position: absolute;
  top: 18px;
  left: 57px;
  font-size: 14px;
  color: #fff;
  max-width: 130px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

#user_nickname span {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}

#vip_endtime {
  position: absolute;
  top: 41px;
  left: 57px;
  font-size: 12px;
  color: #fff;
}

#vip_adword {
  position: absolute;
  top: 65px;
  left: 57px;
  font-size: 12px;
  color: #fff;
}

#immediately_buy_vip {
  position: absolute;
  top: 22px;
  right: 13px;
  width: 77px;
  height: 27px;
  background-image: linear-gradient(122deg, #f3d6a3 0%, #f8e9bf 100%);
  border-radius: 6px;
  font-size: 14px;
  color: #765d43;
  font-weight: bold;
  text-align: center;
  line-height: 27px;
  cursor: pointer;
}

.uc-cloud-progress {
  margin-left: 11px;
  margin-top: 20px;
}

#uc_cloud_progress {
  width: 296px;
  height: 6px;
  background-color: #e7e7e7;
  border-radius: 4px;
  margin-top: 10px;
}

#uc_cur_cloud_progress {
  width: 12%;
  height: 6px;
  background-color: #2072ec;
  border-radius: 3px;
}

.uc-cloud-size-word {
  width: 296px;
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  color: #666;
}

.uc-csw-l {
  float: left;
}

#uc_extend_cloud {
  float: right;
  cursor: pointer;
  font-size: 14px;
  color: #2072ec;
}

#to_userCenter {
  width: 64px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  color: #666;
  position: absolute;
  left: 9px;
  bottom: 20px;
  cursor: pointer;
}

#login_out {
  width: 64px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  font-size: 14px;
  color: #666;
  position: absolute;
  right: 9px;
  bottom: 20px;
  cursor: pointer;
}

#to_userCenter:hover,
#login_out:hover {
  color: #2072ec;
}

.logo-box {
  /* width: 180px; */
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  float: left;
  cursor: pointer;
  margin-left: 20px;

  .logo-word {
    font-size: 20px;
    color: #333;
    font-weight: bold;
    margin-left: 8px;
    display: flex;
    align-items: center;
    font-weight: 600;
  }
}

.feedback-box,
.tanchuang-box {
  width: 600px;
  border-radius: 5px;
  /* box-shadow: -5px 5px 15px 1px rgb(0 0 0 / 10%); */
  background-color: #fff;
  z-index: 105;
}

.feedback-box {
  height: 361px;
}

.tanchuang-box .ctb-top {
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tanchuang-box .tan-ctb-title {
  font-size: 15px;
  color: #333;
  height: 64px;
  line-height: 64px;
  padding-left: 20px;
}

.tanchuang-box .tanchuang_exit,
#feedback_box_exit {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-right: 15px;
}

.tanchuang-box .tanchuang_exit img,
#feedback_box_exit img {
  width: 100%;
  height: 100%;
}

.tanchuang-box .ctb-line {
  width: 560px;
  height: 1px;
  background-color: #e7e7e7;
  margin-left: 20px;
  transform: scaleY(0.5);
}

.tanchuang-box .ctb-word1 {
  font-size: 13px;
  color: #333;
  line-height: 20px;
  margin-top: 30px;
  margin-left: 20px;
}

.tanchuang-box .ctb-input-tname {
  margin-top: 30px;
  margin-left: 20px;
}

.tanchuang-box .ctb-input-tname textarea {
  width: 530px;
  outline: none;
  border-radius: 2px;
  font-size: 14px;
  color: #999;
  border: solid 1px #d8d8d8;
  padding: 13px;
  height: 120px;
  resize: none;
}

.tanchuang-box .ctb-input-tname textarea:focus {
  border: solid 1px #2072ec;
}

.tanchuang-box .ctb-word2 {
  line-height: 20px;
  font-size: 13px;
  color: #333;
  font-weight: bold;
  margin-top: 60px;
  margin-left: 20px;
}

.tanchuang-box .ctb-master-user {
  display: flex;
  flex-direction: revert;
  margin-left: 20px;
  margin-top: 20px;
}

.tanchuang-box .ctb-btns {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  padding-bottom: 40px;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20px;
}

.tanchuang-box .ctb-btns div {
  width: 70px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 2px;
  font-size: 14px;
  cursor: pointer;
}

#feedback_btn_no,
.tanchuang-box .btn_no {
  border: 1px solid #ced0d1;
  color: #999;
}

.tanchuang-box .btn_yes,
#feedback_btn_yes {
  background-color: #2072ec;
  color: #fff;
  margin-left: 10px;
}

.tanchuang-box .btn_no:hover,
#feedback_btn_no:hover {
  color: #2072ec;
  border: solid 1px #2072ec;
}

.feedback_phone {
  display: flex;
  align-items: center;
  padding: 20px 0 0 20px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
}

.feedback_phone input {
  width: 485px;
  margin-left: 20px;
  height: 30px;
  line-height: 30px;
  outline: none;
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  text-indent: 13px;
  color: #333;
  font-size: 14px;
}

.feedback_phone input:focus {
  border: 1px solid #2072ec;
}

#feedback_qq {
  width: 408px;
  cursor: default;
  display: flex;
  align-items: center;
}

#feedback_qq a {
  color: #2072ec;
  text-decoration: none;
}

#feedback_qq a:hover {
  color: #2072ec;
}

#feedback_qq span {
  display: inline-block;
  width: 1px;
  height: 17px;
  background-color: #9fa7b2;
  margin: 0 8px;
}

#feedback_input,
#feedback_phont_input {
  font-family: MicrosoftYaHei;
}

.feedback_yes_main {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 30px);
}

.feedback_yes_main_title {
  font-family: MicrosoftYaHei-Bold;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0px;
  color: #333;
  margin-top: 20px;
}

.feedback_yes_main_text {
  margin-top: 15px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0px;
  color: #333;
}

#feedback_yes_no {
  margin-top: 24px;
  color: #fff;
  width: 80px;
  height: 24px;
  background-color: #454f61;
  border-radius: 2px;
  border: solid 1px #687689;
  line-height: 24px;
  text-align: center;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0px;
  color: #fefefe;
  user-select: none;
  cursor: pointer;
}

#feedback_yes {
  display: none;
  height: calc(100% - 64px);
}

/deep/ .el-dialog__body {
  padding: 0 !important;
}

.syy_sdk::after {
  content: "私有云SDK";
  font-size: 12px;
  color: #fff;
  position: absolute;
  right: -67px;
  top: -15px;
  width: 80px;
  height: 20px;
  line-height: 20px;
  background: #ec6860;
  border-radius: 8px 8px 8px 0px;
}

.syy_sdk {
  height: 21px;
  overflow-wrap: break-word;
  color: #333;
  font-size: 15px;
  text-align: center;
  white-space: nowrap;
  line-height: 21px;
  cursor: pointer;
  position: relative;
  margin-right: 60px;
}

.syy_sdk:hover {
  color: #2072ec !important;
}

.syy_sdk a:hover {
  color: #2072ec !important;
}

.syy_sdk a:link {
  color: #333;
}

.syy_sdk a:visited {
  color: #333;
}

.header_line {
  width: 1px;
  height: 22px;
  background: #687689;
  margin-right: 15px;
}

.header_down_item {
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 20px;
}

.header_down_item:hover {
  background-color: #f2f5fa;
}

.header_down_item span {
  padding-left: 5px;
}

.app_sao_code_box {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  box-shadow: 0 2px 12px 0 rgb(56 56 56 / 20%);
  top: 0;
  left: -197px;
}

.app_sao_code {
  margin: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.app_sao_code img {
  width: 135px;
  height: 135px;
  margin-left: unset;
}

.app_sao_code_text {
  margin-top: 15px;
  font-size: 16px;
  color: #000;
}
</style>
