<template>
  <div class="save_cloud">
    <el-dialog
      title="保存到云盘"
      :visible.sync="$store.state.is_show_save_cloud_box"
      width="780px"
      :modal="true"
      :append-to-body="true"
      :close-on-click-modal="false"
      @close="handleClose"
    >
      <div class="move_file_box">
        <div class="move_file_main">
          <div class="move_file_main_title" style="margin-bottom: 15px">
            请选择文件夹：<span>{{cur_checked_move_path}}</span>
          </div>
          <el-tree
            :data="cloud_data"
            :props="defaultProps"
            accordion
            :default-expand-all="true"
            @node-click="handleCloudClick"
          >
          </el-tree>
          <div class="team-jion-foot" id="move_file_foot">
            <div
              class="team-jion-foot-left team-jion-foot-item"
              id="move_file_addfile"
              @click="show_addDir_box"
            >
              新建文件夹
            </div>
            <div class="team-jion-foot-right">
              <div
                class="team-jion-foot-item quxiao_btn_hover"
                id="move_file_exit"
                @click="dwg_add_btn_no"
              >
                取消
              </div>
              <div
                class="team-jion-foot-item move_file_isShow"
                id="move_file_exit_submit"
                @click="dwg_add_btn_yes"
                v-loading.fullscreen.lock="fullscreenLoading"
              >
                确定
              </div>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 保存成功 -->
    <el-dialog class="save_success"
      @close="successTipClose"
      :visible.sync="save_success">
      <div class="img_icon">
        <img src="@/assets/images/share_page/success_icon.png" alt="">
      </div>
      <div class="main_title">保存成功！</div>
      <p class="intro">图纸已成功保存至CAD云盘，可进入云盘查看</p>
      <div class="btn_box">
        <router-link class="item" :to="{ path: '/my_drawings', query: { link: save_cloud_path } }" target="_blank">点击查看</router-link>
        <div class="item cancel" @click="(save_success = false)">取消</div>
      </div>
      <div class="not_tip">
        <el-checkbox v-model="not_tip">不再提示</el-checkbox>
      </div>
    </el-dialog>
  </div>
</template>

<script>

import { getDirList, createDirectory } from "@/api/get_cloud";
import { save_to_mycloud, share_save_lf } from '@/api/share' 
import utils from "@/utils/utils";

export default {
  name: "SaveCloudView",
  data() {
    return {
      cloud_data: [
        {
          label: "我的云图纸",
          routeName: "/",
          children: [],
        },
      ],
      defaultProps: {
        children: "children",
        label: "label",
      },
      cur_checked_move_path: 'CAD云盘 「我的云图纸」',
      cur_checked_item: '',
      is_dir_pass: false,
      cur_save_files: '',
      save_cloud_path: '/',

      fullscreenLoading: false,

      save_success: false, // 保存成功？
      not_tip: false,
    };
  },
  mounted() {
    var that = this;
    this.$EventBus.$off("saveCloudGetDirLists");
    this.$EventBus.$on("saveCloudGetDirLists", function (data) {
      // 开始调用方法
      that.cur_save_files = JSON.parse(data)
      that.getDirLists();
    });
  },

  methods: {
    getDirLists() {
      var send = {
        p: "/",
      };
      var newArr = [
        {
          label: "我的云图纸",
          routeName: "/",
          children: [],
          t_id: undefined,
        },
      ];
      getDirList(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          var arr = utils.pathToTree(data.datas);
          newArr[0].children = arr;
          this.cloud_data = newArr;
        }
      });

    },

    handleClose() {
      var not_tip = localStorage.getItem('saveDwg_notTip');
      if (not_tip) {
        this.cur_checked_move_path = 'CAD云盘 「我的云图纸」'
        this.save_cloud_path = '/'
        this.is_dir_pass = false
        this.cur_checked_item = ''
        this.$store.state.is_show_save_cloud_box = false
      }
    },

    handleCloudClick(data) {
      if (data.tid == undefined) {
        this.is_dir_pass = false
      } else {
        this.is_dir_pass = true
      }
      this.cur_checked_move_path = data.routeName
      this.cur_checked_item = data
      this.set_cur_mubiao_movepath(data)
    },


    set_cur_mubiao_movepath(data) {
      var text = ''
      if (data.tid != undefined) {
        text = '群组 「' + data.label + '」'
        if (data.label == '我的群') {
          text = ''
        }
      } else {
        text = this.cur_checked_move_path.slice(1);
        if (text.length > 0) {
          this.save_cloud_path = '/' + text
          text = 'CAD云盘 「' + '/' + text + '」';
        } else {
          this.save_cloud_path = '/'
          text = 'CAD云盘 「' + '我的云图纸' + '」';
        }
      }
      
      this.cur_checked_move_path = text;
    },
    

    dwg_add_btn_no() {
      this.cur_checked_move_path = 'CAD云盘 「我的云图纸」'
      this.save_cloud_path = '/'
      this.is_dir_pass = false
      this.$store.state.is_show_save_cloud_box = false
    },

    dwg_add_btn_yes() {
      var send;
      var cur_save_files = this.cur_save_files

      if (this.save_cloud_path == '') {
        // this.fullscreenLoading = false
        this.$msg.whiteerr('您还未选择文件夹')
        return
      }
      
      const loading = this.$loading({
        lock: true,
        text: 'Loading',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });

      var ids = []
      cur_save_files.forEach(item => {
        ids.push(item.share_id)
      });

      if (this.$parent.share_type == 'see') {
        //云盘
        send = {
          usertoken: this.$parent.userinfo.user_token,
          parent_dir: this.save_cloud_path,
          share_ids: JSON.stringify(ids),
          force: 1,
        }
        share_save_lf(send).then((res)=>{
          var res = JSON.parse(res.data)
          if (res.code == 1) {
            this.reset_move_file_action()
            // this.fullscreenLoading = false
            setTimeout(() => {this.success_msg()}, 600);
            setTimeout(() => {loading.close();}, 500);
          } else if (res.code == 4) {
            setTimeout(() => {this.$msg.whiteerr('该文件夹已存在同名文件，请换一个或者新建一个文件夹')}, 600);
            setTimeout(() => {loading.close();}, 500);
          } else if (res.code == 3) {
            setTimeout(() => {this.$msg.whiteerr('云盘空间已满')}, 600);
            setTimeout(() => {loading.close();}, 500);
          } else if (res.code == 5) {
            setTimeout(() => {this.$msg.whiteerr('分享用户不存在')}, 600);
            setTimeout(() => {loading.close();}, 500);
          } else {
            setTimeout(() => {this.$msg.whiteerr('保存到云盘失败')}, 600);
            setTimeout(() => {loading.close();}, 500);
          }
        }).catch(()=>{
          setTimeout(() => {this.$msg.whiteerr('服务器繁忙,请稍后再试')}, 600);
          setTimeout(() => {loading.close();}, 500);
        })
      } else if (this.$parent.share_type == 'down') {
        //云盘
        send = {
          parent_dir: this.save_cloud_path,
          share_ids: JSON.stringify(ids),
          force: 1,
        }
        save_to_mycloud(send).then((res)=>{
          var res = JSON.parse(res.data)
          if (res.code == 1) {
            this.reset_move_file_action()
            // this.fullscreenLoading = false
            setTimeout(() => {this.success_msg()}, 600);
            setTimeout(() => {loading.close();}, 500);
          } else if (res.code == 4) {
            setTimeout(() => {this.$msg.whiteerr('该文件夹已存在同名文件，请换一个或者新建一个文件夹')}, 600);
            setTimeout(() => {loading.close();}, 500);
          } else if (res.code == 3) {
            setTimeout(() => {this.$msg.whiteerr('云盘空间已满')}, 600);
            setTimeout(() => {loading.close();}, 500);
          } else {
            setTimeout(() => {this.$msg.whiteerr('保存到云盘失败')}, 600);
            setTimeout(() => {loading.close();}, 500);
          }
        }).catch(()=>{
          setTimeout(() => {this.$msg.whiteerr('服务器繁忙,请稍后再试')}, 600);
          setTimeout(() => {loading.close();}, 500);
        })
      }
    },

    success_msg () {
      var not_tip = localStorage.getItem('saveDwg_notTip');
      if (not_tip) {
        this.$message({
          dangerouslyUseHTMLString: true,
          message: '<p style="color:#333;height:10px">保存成功,可直接打开<a href="https://www.52cad.com/my_drawings" style="color:#4383f0;cursor: pointer;">52cad.com</a>查看图纸</i></p>',
          customClass: 'save_cloud_msg',
          type: 'success',
          iconClass: 'save_cloud_icon',
          duration: 3000,
        });
      } else {
        this.save_success = true;
      }
    },

    //重置组件状态
    reset_move_file_action () {
      this.cur_checked_move_path = 'CAD云盘 「我的云图纸」'
      this.is_dir_pass = false
      this.$store.state.is_show_save_cloud_box = false;
    },
    successTipClose() {
      this.save_cloud_path = '/';
    },

    show_addDir_box() {
      if (this.is_dir_pass) {
        this.$msg.whiteerr('必须在我的云图纸中创建新的文件夹')
        return
      }

      this.$prompt("", "新建文件夹", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValue: '新建文件夹',
        closeOnClickModal: false,
        inputPattern: /^((?![\\|\\ \\"\\?\\>\\<\\:\\.\\'\\/\\*\\]).)*$/,
        inputErrorMessage: "不能使用特殊字符或空格",
      })
        .then(({ value }) => {
          if (value == "" || value == null) {
            this.$msg.whiteerr('文件夹名称不能为空')

            return;
          }
          this.show_addDir(value);
        })
        .catch(() => {
          
        });
    },

    show_addDir(new_dir_name) {
      
      var cur_checked_item = this.cur_checked_item
      var send;
      if (cur_checked_item.t_id == undefined) {
        send = {
          p: cur_checked_item.routeName == '/' ? '/' + new_dir_name : cur_checked_item.routeName + '/' + new_dir_name
        }
      }

      if (cur_checked_item == '') {
        send = {
          p: '/' + new_dir_name
        }
      }

      createDirectory(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          this.$msg.white('新建成功')
          this.getDirLists();
        } else if (data.code == 2) {
          this.$msg.whiteerr('已有该名称~')
        } else if (data.code == 99) {
          this.$msg.whiteerr('登录信息已失效，请重新登录')
        } else {
          this.$msg.whiteerr('创建失败，请稍后在试')
        }
      });
    },
  },

  watch: {
    not_tip: {
      handler(newVal) {
        if (newVal) {
          localStorage.setItem('saveDwg_notTip', true);
        } else {
          localStorage.setItem('saveDwg_notTip', false);
        }
      }
    },
    save_cloud_path: {
      handler(newVal) {
        console.log(newVal);
      }
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .el-dialog__body {
  padding: 0 !important;
}

/deep/ .el-dialog .el-dialog__header {
  position: relative;
  padding: 20px;
}

/deep/ .el-dialog .el-dialog__header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 20px;
  width: 740px;
  height: 1px;
  background-color: #e7e7e7;
}

/deep/ .save_success {

  .el-dialog {
    width: 334px;
    border-radius: 8px;
    margin-top: 25vh !important;
    text-align: center;
    background: #FFFFFF;
  }

  .el-dialog__header {
    padding: 0;
    padding-top: 32px;

    &::after {
      display: none;
    }
  }

  .main_title {
    margin-bottom: 21px;
    padding-left: 10px;
    font-size: 16px;
    color: #2072EC;
    line-height: 17px;
  }

  .intro {
    font-size: 12px;
    color: #333333;
    line-height: 14px; 
  }

  .btn_box {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 14px;
    font-size: 12px;
    color: #FFFFFF;
    line-height: 14px;
    text-align: center;

    .item {
      width: 82px;
      padding: 8px 0 6px;
      border-radius: 2px;
      margin-bottom: 32px;
      border: 1px solid #2072EC;
      color: #fff;
      background: #2072EC;
      box-sizing: border-box;

      &.cancel {
        margin-left: 14px;
        border-color:#C8CBD2;
        color: #989898;
        background-color: transparent;
        cursor: pointer;
      }
    }
  }

  .not_tip {
    padding-bottom: 20px;
    
    .el-checkbox {

      .el-checkbox__input {
        &.is-checked {
          .el-checkbox__inner {
            border-color: #2072EC;
            background-color: #2072EC;
          }
        }
      }
      .el-checkbox__inner {
        border-color: #999;
      }
      .el-checkbox__label {
        color: #999999;
        font-size: 12px;
      }
    }
  }
}

.move_file_box {
  position: relative;
  display: block;
  border-radius: 0;
  box-shadow: none;
}

.move_file_main {
  margin-top: 20px;
  width: 740px;
  margin: auto;
}

.move_file_main_title {
  font-size: 15px;
  color: #333;
  padding-top: 30px;
  user-select: none;
}

.move_file_main_title span {
  color: blue;
}

.move_file_main_first {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 10px;
  height: 32px;
  line-height: 32px;
  /* background-color: #f0f4fc; */
  margin-top: 14px;
}

.move_file_main_first span {
  padding-left: 8px;
  font-size: 15px;
  color: #999;
  user-select: none;
}

.move_file_lists {
  max-height: 150px;
  overflow: auto;
}

.move_file_item {
  font-size: 14px;
  color: #999;
  height: 32px;
  line-height: 32px;
  display: flex;
  align-items: center;
  padding-left: 18px;
}

.move_file_item:hover {
  background-color: #f5f5f5;
}

.move_file_item_checked {
  background-color: #f0f4fc;
}

.move_file_item span {
  padding-left: 8px;
  user-select: none;
  max-width: 300px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.move_file_team_item img {
  width: 20px;
  height: 20px;
  opacity: 0;
}

.move_file_team_item {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.move_file_team_item span {
  padding-left: 6px;
}

#move_file_addfile {
  border: 1px solid #ced0d1;
  color: #999;
  padding: 0 20px;
}

#move_file_addfile:hover {
  color: #4383f0;
  border: solid 1px #4383f0;
}

.team-jion-foot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.team-jion-foot-right {
  display: flex;
  align-items: center;
  justify-content: center;
}

#move_file_foot {
  width: auto;
  margin: unset;
  padding: 30px 20px 20px 20px;
  user-select: none;
}

.team-jion-foot-item {
  width: 70px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 2px;
  font-size: 14px;
  cursor: pointer;
}

#move_file_exit {
  border: 1px solid #ced0d1;
  color: #999;
  cursor: pointer;
}

#move_file_exit_submit {
  background-color: #4383f0;
  color: #fff;
  margin-left: 10px;
}

#move_file_team_lists {
  display: none;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

#move_file_exit:hover {
  color: #4383f0;
  border: solid 1px #4383f0;
}

.move_file_isShow {
  cursor: pointer;
  opacity: 1;
}

.move_file_isShow:hover {
  opacity: 0.8;
}
</style>