<template>
  <el-dialog
    title="提示"
    :visible.sync="$store.state.is_show_forcebind_box"
    width="450px"
    :modal="true"
    :append-to-body="true"
    @close="handleClose"
  >
    <div class="share_box_warp">
      <div class="tan-ctb-title1">当前账号已被绑定，是否强制绑定？</div>
      <div class="ctb-btns" style="margin-top: 0">
        <div class="tanchu_btn_qx" @click="tanchu_btn_bind">强制绑定</div>
        <div class="tanchu_btn_sumbit" @click="tanchu_btn_sumbit">更换账号</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { forcebind } from '@/api/user'

export default {
  data() {
    return {

    }
  },
  mounted () {},
  methods: {

    handleClose() {
      this.$store.state.is_show_forcebind_box = false
      this.$store.state.is_show_bindwx_qcode_box = false
    },

    tanchu_btn_bind() {
      
      forcebind().then((res)=>{
        var data = JSON.parse(res.data)
        if (data.code == 1) {
          this.$store.state.is_show_forcebind_box = false
          this.$store.state.is_show_bindwx_qcode_box = false
          this.$EventBus.$emit("resetUserinfoBus", 1);
        } else {
          alert(data.msg)
        }
      })
    },

    tanchu_btn_sumbit() {
      this.$store.state.is_show_forcebind_box = false
      this.$store.state.is_show_bindwx_qcode_box = false
    },
  },
}
</script>

<style lang="less" scoped>
.tan-ctb-title1 {
  height: 64px;
  padding-left: 30px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 64px;
  letter-spacing: 0px;
  color: #000000;
}

.ctb-btns {
  margin-top: 40px;
  margin-bottom: 30px;
  display: flex;
  width: 420px;
  justify-content: flex-end;
  user-select: none;
}

.ctb-btns div {
  width: 70px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 2px;
  font-size: 14px;
  cursor: pointer;
}

.tanchu_btn_qx {
  border: 1px solid #ced0d1;
  color: #999;
}

.tanchu_btn_sumbit {
  background-color: #3975c6;
  color: #fff;
  margin-left: 10px;
}
</style>