<template>
  <div id="appA">
    <div id="main_header_bg">
      <div class="main_header_bg_flex">
        <div class="main_header_left">
          <a href="javascript:;">
            <img src="@/assets/images/common/logo.png" :alt="headerTitle1" />
            <div class="main_header_title">{{ headerTitle }}</div>
          </a>
        </div>
        <div id="show_logo_shoucang">
          <div class="show_logo_shoucang_postion">
            <a :href="https_1">
              <div style="opacity: 0">
                {{ headerTitle1 }}
              </div>
              <img src="@/assets/images/main/shoucang.png" :alt="headerTitle1" />
              <div>喜欢我，就拖动logo到标签进行收藏吧~</div>
            </a>
          </div>
        </div>
        <div class="main_header_right">
          <div class="main_header_bg_flex main_header_bg_flex1">
            <div class="syy_sdk" id="go_to_syy">
              <a href="/privatecloudsdk">企业专属的在线CAD-私有化部署方案 &gt;</a>
            </div>
            <div class="main_header_line"></div>
            <el-dropdown>
              <span
                class="el-dropdown-link feedback_show"
                id="feedback_show"
                style="color: #fff; font-size: 16px"
              >
                下载客户端
              </span>
              <el-dropdown-menu slot="dropdown">
                <div class="header_down_item" id="down_app_btn" @click="down_kehuduan_btn">
                  <img src="@/assets/images/common/down_win.png" alt="" />
                  <span>Windows版本</span>
                </div>
                <div
                  class="header_down_item"
                  id="show_app_sao_code_box"
                  @mouseover="is_show_down_app_qocde = true"
                  @mouseout="is_show_down_app_qocde = false"
                >
                  <img src="@/assets/images/common/down_app.png" alt="" />
                  <span>App扫码下载</span>
                  <div class="app_sao_code_box" v-show="is_show_down_app_qocde">
                    <div class="app_sao_code">
                      <img src="@/assets/images/common/basic.png" alt="" />
                      <div class="app_sao_code_text">手机扫码下载</div>
                      <div class="app_sao_code_text1">支持Android / IOS</div>
                    </div>
                  </div>
                </div>
              </el-dropdown-menu>
            </el-dropdown>
            <div class="main_header_line"></div>
            <div
              class="go_to_login"
              @click="
                is_show_login = true;
                cur_show_box = 'login';
                $store.state.isClickXieyi = false;
              "
              v-if="userinfo == null"
            >
              登录
            </div>
            <div
              class="go_to_reg"
              @click="
                is_show_login = true;
                cur_show_box = 'reg';
                $store.state.isClickXieyi = false;
              "
              v-if="userinfo == null"
            >
              注册
            </div>

            <div class="go_to_mycloud" @click="go_cloud1" v-if="userinfo != null">进入我的云图</div>
          </div>
        </div>
      </div>
    </div>
    <div class="top_bg_banner_box">
      <div id="header_imgUrl"></div>
      <div class="header_imgUrl_title_box">
        <div class="header_imgUrl_title1">迷你CAD-网页版</div>
        <div class="header_imgUrl_title2">无需下载软件，随时随地看图纸，快速发送图纸给好友</div>
        <div class="header_imgUrl_title3">打开图纸丨测量查看丨发送图纸</div>
        <div class="header_imgUrl_title_open_dwg" @click="go_cloud">打开图纸</div>
      </div>

      <div class="shili_box">
        <div class="shili_flex swiper-wrapper">
          <!-- <div
            class="shili_item swiper-slide"
            style="margin-left: 20px"
            id="go_to_cloud"
            @click="go_cloud"
          >
            <div class="go_to_cloud_bgimg"></div>
            <div class="go_to_cloud_text">打开我的图纸 ></div>
            <div class="shili_item_size">222.88KB</div>
          </div> -->

          <div class="shili_item swiper-slide" style="margin-left: 20px" @click="go_shilitu(1)">
            <div class="shili_item_img">
              <img src="https://ft.appol.com/imgs/sample/img01.png" />
            </div>
            <div class="shili_item_name">东明花苑平面布置图.dwg</div>
            <div class="shili_item_size">示例图</div>
          </div>
          <div class="shili_item swiper-slide" style="margin-left: 30px" @click="go_shilitu(2)">
            <div class="shili_item_img">
              <img src="https://ft.appol.com/imgs/sample/img02.png" />
            </div>
            <div class="shili_item_name">建筑结构图.dwg</div>
            <div class="shili_item_size">示例图</div>
          </div>
          <div class="shili_item swiper-slide" style="margin-left: 30px" @click="go_shilitu(3)">
            <div class="shili_item_img">
              <img src="https://ft.appol.com/imgs/sample/img03.png" />
            </div>
            <div class="shili_item_name">视频监控系统图.dwg</div>
            <div class="shili_item_size">示例图</div>
          </div>
          <div class="shili_item swiper-slide" style="margin-left: 30px" @click="go_shilitu(4)">
            <div class="shili_item_img">
              <img src="https://ft.appol.com/imgs/sample/img04.png" />
            </div>
            <div class="shili_item_name">设备用房给排水设计.dwg</div>
            <div class="shili_item_size">示例图</div>
          </div>
          <div class="shili_item swiper-slide" style="margin-left: 30px" @click="go_shilitu(5)">
            <div class="shili_item_img">
              <img src="https://ft.appol.com/imgs/sample/img05.png" />
            </div>
            <div class="shili_item_name">减速机-平面.dwg</div>
            <div class="shili_item_size">示例图</div>
          </div>
          <!-- <div
            class="shili_item swiper-slide"
            style="margin-left: 30px; margin-right: 20px"
            @click="go_shilitu(6)"
          >
            <div class="shili_item_img">
              <img src="https://open.appol.com/imgs/sample/img06.png" />
            </div>
            <div class="shili_item_name">带联立式风机.dwg</div>
            <div class="shili_item_size">1.31MB</div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="main_2">
      <div class="main_2_title1">跨平台使用，<span>随时随地查看图纸</span></div>
      <div class="main_2_title2">专注设计本身，高效工作新方式，未来已来</div>
      <div class="main_2_flex">
        <div class="main_2_flex_left">
          <div class="main_2_flex_left_1">
            无论是电脑端、移动设备，或是微信聊天中，您与您的工作伙伴，都可以打开浏览CAD图纸，测量、批注、沟通。
          </div>
          <div
            class="main_2_flex_left_2"
            :class="is_show_phone_or_pc ? 'main_2_flex_left_active' : ''"
            @click="checkout_shitu('phone')"
            @mouseover="checkout_shitu('phone')"
          >
            <img src="@/assets/images/main/shoujiduan.png" alt="" />
          </div>
          <div
            class="main_2_flex_left_2 main_2_flex_left_3"
            :class="!is_show_phone_or_pc ? 'main_2_flex_left_active' : ''"
            @click="checkout_shitu('pc')"
            @mouseover="checkout_shitu('pc')"
          >
            <img src="@/assets/images/main/diannaoduan.png" alt="" />
          </div>
        </div>
        <div class="main_2_flex_right">
          <div class="main_2_flex_right_title" v-if="is_show_phone_or_pc">手机端</div>
          <div class="main_2_flex_right_flex" v-if="is_show_phone_or_pc">
            <img src="@/assets/images/main/phone_url.png" alt="" />
            <img src="@/assets/images/main/phone_open_dwg.jpg" alt="" style="margin-left: 50px" />
          </div>

          <div class="main_2_flex_right_title" v-if="!is_show_phone_or_pc">电脑端</div>
          <div class="main_2_flex_right_flex" style="height: 580px" v-if="!is_show_phone_or_pc">
            <img src="@/assets/images/main/diannaoduan_bg.jpg" />
          </div>
        </div>
      </div>
    </div>
    <div class="main_3" style="display: none">
      <div class="main_2_title1">权限设置，<span>安全可靠</span></div>
      <div class="main_2_title2">自由设置权限，真正的加密只读新格式</div>
      <div class="main_2_flex main_3_flex">
        <div class="main_3_flex_left">
          <img src="@/assets/images/main/main3_share.png" alt="" />
        </div>
        <div class="main_3_flex_right">
          <div class="main_3_flex_right_title">
            告别繁冗且有安全隐患的转PDF、加密等旧方式，在这里，有灵活的访问权限可供设置，真正的加密只读新格式等您体验。
          </div>
          <img src="@/assets/images/main/main3_share1.png" alt="" />
        </div>
      </div>
    </div>
    <div class="main_4">
      <div class="main_2_title1" style="text-align: center; padding-top: 120px">
        企业解决方案<span>-在线CAD私有云SDK</span>
      </div>
      <div class="main_4_items">
        <div class="main_4_item">
          <img src="@/assets/images/main/main4_1.png" alt="" />
          <div class="main_4_item_text1">图纸云服务</div>
          <div class="main_4_item_text2">
            公有云图纸托管服务与私有云服务部署双重方案，满足企业更好的安全与使用需求。
          </div>
        </div>
        <div class="main_4_item">
          <img src="@/assets/images/main/main4_2.png" alt="" />
          <div class="main_4_item_text1">企业品牌服务</div>
          <div class="main_4_item_text2">
            企业水印，企业名称、logo等专属定制，助力企业文化与品牌的提升与渗透能力。
          </div>
        </div>
        <div class="main_4_item">
          <img src="@/assets/images/main/main4_3.png" alt="" />
          <div class="main_4_item_text1">正版授权</div>
          <div class="main_4_item_text2">
            摆脱国外软件版权束缚，国产自主研发，提供正版CAD软件授权。
          </div>
        </div>
      </div>
      <div class="go_to_call_we" @click="is_show_consult = true">
        <a>联系我们</a>
      </div>
    </div>

    <div class="main_5">
      <div class="main_5_flex">
        <div class="main_4_title main_5_title">研发团队</div>
        <div class="main_2_title2 main_5_title2">由迷你CAD研发团队倾力打造</div>
        <div class="main_5_title3">
          客户端软件CAD迷你看图、CAD迷你画图系列产品得到近<span>6000万</span>行业用户的信赖与使用。
        </div>
        <div class="main_5_title4">
          十年技术积累，十年磨一剑，为中国工程行业带来新一代web端CAD产品。
        </div>

        <div class="main_5_6000_flex">
          <img class="main_5_6000_left" src="@/assets/images/main/main_logo_right.png" />
          <div class="main_5_6000_center">6000万</div>
          <img class="main_5_6000_right" src="@/assets/images/main/main_logo_left.png" />
        </div>
        <div class="bg_6000">6000<span>万</span></div>
        <div class="footer_beian">
          版权所有&nbsp;&nbsp;开发者：上海晓材科技有限公司&nbsp;&nbsp; &nbsp;&nbsp;<a
            href="http://beian.miit.gov.cn/"
            target="_blank"
            title="备案号"
            rel="external nofollow noopener noreferrer"
            >沪ICP备15023605号-6</a
          >
        </div>
      </div>
    </div>

    <div class="cando_consult_box" v-if="is_show_consult" @keydown.esc="hideConsultBox">
      <div class="cando_consult">
        <div class="title">咨询专属顾问</div>
        <div class="close" @click="is_show_consult = false">
          <div class="close_left"></div>
          <div class="close_right"></div>
        </div>
        <ul class="consult_members">
          <li>
            <div class="name">
              <div class="line"></div>
              <span>揣小姐</span>
            </div>
            <div class="telephone">电话：13162771001</div>
            <div class="mailbox">邮箱：chuaiyunning@52cad.com</div>
          </li>
        </ul>
      </div>
    </div>

    <div class="xieyibox" v-if="$store.state.is_show_xieyi" @keydown.esc="hideConsultBox">
      <div class="box">
        <div class="close" @click="$store.state.is_show_xieyi = false">
          <div class="close_left"></div>
          <div class="close_right"></div>
        </div>

        <div class="checked">
          <div class="left" :class="isChecked ? 'left_active' : ''" @click="onChangeChecked">
            <img :src="isChecked ? img1 : img2" alt="" srcset="" />
          </div>
          <div class="right" @click="isShowXieyiText = true">迷你CAD打开图纸使用协议</div>
        </div>

        <div class="upBtn" :class="isChecked ? 'upBtn_yes' : ''" @click="uploadFileMain">
          选择本地文件
        </div>
      </div>
    </div>

    <div class="xieyiTextBox" v-if="isShowXieyiText">
      <div class="box">
        <div class="head">
          <div class="left"></div>
          <div class="title">上海晓材隐私保护政策</div>
          <div class="close" @click="isShowXieyiText = false">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <rect
                x="4"
                y="13.8995"
                width="14"
                height="2"
                rx="1"
                transform="rotate(-45 4 13.8995)"
                fill="#979797"
              />
              <rect
                x="5.41418"
                y="4"
                width="14"
                height="2"
                rx="1"
                transform="rotate(45 5.41418 4)"
                fill="#979797"
              />
            </svg>
          </div>
        </div>
        <div class="time">更新时间：2023年01月13日</div>
        <div class="linea"></div>
        <div class="texts">
          <div class="docs">
            <p>
              感谢您选择使用上海晓材科技有限公司（下称“上海晓材”）在线服务（包括但不限于上海晓材会员中心<a
                href="https://app.olcad.com/cad/index"
                class="link"
                >https://app.olcad.com/cad/index</a
              >提供的服务及后续可能推出的并纳入上海晓材在线服务范畴内的其他各项服务，上海晓材或其关联公司及/或与上海晓材及其关联公司存在合作关系的第三方合作伙伴已经或即将推出的其他服务，下同）及由上海晓材或其关联公司开发、运营的软件（以下简称为“授权软件”，包括但不限于免费或收费版本的授权软件、不同语言版本的授权软件以及不同客户端的授权软件）！
            </p>
            <p>如果您对本隐私政策有任何疑问、意见或建议，通过以下方式与我们联系：</p>
            <p>电子邮件：help@52cad.com</p>
            <p>电 话：4006002063</p>
            <h4><strong>特别提示</strong></h4>
            <p>
              <strong
                >在您使用授权软件、上海晓材在线服务前，请您务必仔细阅读本政策，上海晓材将根据中华人民共和国（仅为本政策之目的，不包括香港、澳门特别行政区及台湾地区，以下称为“中国大陆”）相关法律法规的规定保护在您使用授权软件、上海晓材在线服务的任何部分或全部的过程中上海晓材收集的个人信息。除非您同意并接受本政策的所有条款，否则您无权登录或使用授权软件、上海晓材在线服务的任何部分或全部。您对授权软件、上海晓材在线服务的任何部分或全部下载、安装、注册、开启、登录、浏览、购买、定制、使用等任何行为，意味着您同意上海晓材按照本政策收集、使用、保存、共享您的个人信息，且认可本政策全部条款并同意受其约束。</strong
              >
            </p>
            <p>本政策将帮助您了解以下内容：</p>
            <p>● 如何收集和使用您的个人信息</p>
            <p>● 如何使用Cookie和同类技术</p>
            <p>● 如何共享、转让、公开披露您的个人信息</p>
            <p>● 如何保护和保存您的个人信息</p>
            <p>● 查询、更正信息的渠道及删除账号</p>
            <p>● 对未成年人信息的保护</p>
            <p>● 您的个人信息如何在全球范围转移</p>
            <p>● 本政策如何更新</p>
            <p>● 本政策的适用范围</p>
            <h3>一、定义</h3>
            <p>
              <strong>1.个人信息：</strong>
              是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息，包括个人身份信息（姓名、手机号、身份证号）、网络身份识别信息（包括账号、头像、IP地址、邮箱地址、密码）、软件信息（包括软件版本号、序列号、激活码、渠道号、授权类型、邮箱域名、邮箱登录端口、登录协议或其他用于连接上海晓材在线服务的软件相关数据）、设备信息（设备属性信息（例如您的设备型号、操作系统版本、设备配置、唯一设备标识符、设备地理位置、存储空间、国际移动设备身份码IMEI、网络设备硬件地址MAC、广告标识符IDFA等）、设备连接信息（浏览器的类型、电信运营商、网络环境、使用的语言）以及设备状态信息（例如设备传感器数据，设备应用安装列表））、文档信息、日志信息（包括您对授权软件、上海晓材在线服务、相关网站的使用情况、IP地址、所访问服务的URL、浏览器的类型和使用的语言、下载、安装或使用移动应用和软件的信息、与通讯软件通讯的信息以及访问上海晓材在线服务、相关网站的日期、时间、时长等）等。
            </p>
            <p>
              <strong>2.个人敏感信息：</strong>
              是指一旦泄露、非法提供或滥用可能危害人身和财产安全，极易导致个人名誉、身心健康受到损害或歧视性待遇等的个人信息。本政策中涉及的个人敏感信息，将采用加粗字体突出显示。
            </p>
            <p>
              <strong>3.关联公司：</strong>
              均指不时（直接或间接）控制（或与其他方共同控制）该方，受该方控制（或受该方与其他第三方的共同控制），或与该方同受控制的公司，无论如何，（直接或间接）享有该公司50%以上的管理或决策权利（不论是通过表决权、合同或其他方式）均应视为控制。
            </p>
            <h3>二、如何收集和使用您的个人信息</h3>
            <p>上海晓材出于本政策所述的以下目的，收集和使用您的个人信息：</p>
            <p>
              <strong
                >（一）核心业务功能收集和使用您个人信息的情形：授权您使用授权软件及/或向您提供上海晓材在线服务</strong
              >
            </p>
            <p>
              我们可能会收集、保存和使用下列与您有关的信息才能实现下述功能。如果您不提供相关信息，您将无法享受相关服务。这些功能包括：
            </p>

            <p>
              <strong>1.</strong
              >CAD图纸浏览、编辑和存储功能在您使用CAD迷你看图应用时，我们获取以下权限，在经过同意后，我们会合法使用。
            </p>
            <p>
              （1）.设备信息：包括唯一设备识别码、登录IP地址、设备型号、设备名称、设备标识、操作系统和应用程序版本、接入网络的方式、网络质量数据、移动网络信息（包括运营商名称）、产品版本号、设备所在位置相关信息（包括您授权我们获取的地理位置信息）、网络设备硬件地址MAC。为了收集上述基本的个人设备信息，我们将会申请访问您的设备信息的权限并根据您的授权获取相关信息。
            </p>
            <p>
              （2）.存储信息：当您想访问浏览、编辑或删除存储在设备本地或SD卡(外置存储设备)的图纸文件时，我们需要获取您的设备存储信息以及SD卡(外置存储设备)的读写权限。
            </p>
            <p>
              （3）.位置信息：指您开启设备定位功能并使用我们基于位置提供的相关服务时，收集的有关您位置的信息，包括：您通过具有定位功能的移动设备使用我们的服务时，通过GPS或WiFi等方式收集的您的地理位置信息。
            </p>
            <p>（4）.为了用户方便看图，我们会防止您的设备进入休眠状态；</p>
            <p>
              我们收集这些信息是为了向您提供我们最核心的CAD图纸浏览编辑和存储功能，如您拒绝提供上述信息和/或权限将可能导致您无法使用我们的产品与服务。请您知悉，单独的设备信息、日志信息无法识别您的身份信息。
            </p>
            <p><strong>2.</strong>图纸分享和交流互动功能</p>
            <p>
              （1）.当您使用我们产品过程中，需要分享您的图纸文件给他人时，有两种操作场景：第一种：通过系统三方分享平台进行。我们会调用系统中可用的三方应用，在您授权的前提下，完成分享功能。第二种：可以通过迷你看图内置功能进行文件发送，在此过程中需要您授权设备的存储功能、SD卡(外置存储设备)的读取权限。
            </p>
            <p>
              （2）.为了促进用户间的交流互动，我们也提供了上传图片、发送语音功能，当您使用录音功能时，我们会使用您的麦克风权限；上传图片功能需要您授权相机和相册访问权限。
            </p>
            <p>
              （3）.华为手机使用华为畅连功能，与好友联系时，此时需要获取您的通讯录访问权限。另外迷你看图与您之间会通过消息推送、短信、邮件方式进行互动。您知悉并同意，对于您在使用产品或服务的过程中提供的您的一种或多种联系方式（电子邮箱、联系电话），我们在运营中可能会向其中的一种或多种发送多类通知，用于用户消息告知、身份验证、安全验证用途。对于手机推送消息，您可以在设备系统设置里控制是否接收，设置路径如：“系统设置—通知—迷你看图—允许通知”将开关设为关闭状态后，则不会收到我们发送的系统推送消息。
            </p>

            <p>
              <strong>3.</strong
              >注册账号：如您需注册上海晓材系列软件的账号，则为完成账号创建，您需提供您的<strong>邮箱地址、手机号</strong>及<strong>密码</strong>；
            </p>
            <p>
              <strong>4.</strong
              >授权第三方账号登录、使用上海晓材在线服务：可以登录、使用上海晓材在线服务的第三方账号包括微信账号、QQ账号、新浪微博账号、小米账号、校园邮账号、钉钉账号以及后续可能增加的其他账号，为使该等第三方账号登录、使用上海晓材在线服务，您需提供<strong>第三方账号/昵称、账号密码</strong>，上传您的第三方账号头像（如有）。前述第三方账号由上海晓材之外的其他方提供，您在注册该等第三方账号时需要完全根据该账号提供方（例如您授权登录、使用上海晓材在线服务的账号系QQ账号，则该账号的提供方系腾讯，以此类推）的相关要求进行，第三方账号提供方对您的个人信息（包括个人敏感信息）的收集、使用、共享、转让等一切行为均适用于第三方账号提供方的隐私保护政策，您授权第三方账号登录、使用上海晓材在线服务则视为您已同意第三方账号提供方的隐私保护政策，同意第三方按照其隐私保护政策对您的个人信息（包括个人敏感信息）进行收集、使用、共享、转让等一切行为。
            </p>
            <p>
              <strong>5.</strong
              >向您提供授权软件、上海晓材在线服务。通过收集序列号、激活码来验证授权软件的授权类型、授权期限、授权范围等相关授权的合法性、有效性并提供授权软件服务及相关技术支持。通过收集您的<strong>账号信息（账号、手机号、邮箱地址）、订单信息</strong>（如有）来核实您的上海晓材在线服务的合法性、有效性并提供上海晓材在线服务相关服务及相关技术支持。
            </p>
            <p>
              <strong>6.</strong
              >相关云服务的使用。上海晓材在线服务包含的云服务（包括但不限于云空间服务、文档自动同步服务、文档漫游服务、上海晓材PDF转换服务中的增强转换功能等需要在云端实现的功能及后续推出依托于云的服务）均以您在联网状态下、登录账号后将相关文档、图片、照片、语音等材料上传（包括手动上传和自动漫游）至云端为前提，上海晓材收集您上传至云端的文档名称、内容、文档属性（包括但不限于文档类型、大小、页数等）、上传、启动云文档的频率、使用云文档的时长等信息是为您提供相关云服务所必需的。同时，您所上传的相关文档、图片、照片、语音等材料在脱敏后会作为AI
              训练的基础材料读取、使用，以便为您提供相关的AI服务。
            </p>
            <p>
              <strong>7.</strong
              >帮助上海晓材研发、设计新产品、新服务，改善、提升上海晓材现有的产品及服务、优化用户体验：上海晓材会通过您的软件信息、常用设备信息、日志信息、云服务使用情况（云文档的上传频率、使用时长、上传文档的名称、类型、属性等）进行数据分析，了解授权软件、上海晓材在线服务的使用情况、适配情况，为新产品、新服务的研发、已有功能、服务的完善提供数据支撑。例如，上海晓材会通过您的软件信息了解授权软件、上海晓材在线服务的使用、适配情况，发现需要优化的功能、服务，提升用户体验；会通过分析您的日志信息，进一步挖掘授权软件、上海晓材在线服务的新功能、新服务；有针对性地回应您的个性化需求，例如语言设定、位置设定、个性化的帮助服务和指示，或对您和其他用户作出其他方面的回应。对于从您的各种设备上收集到的信息，上海晓材可能会将它们进行关联，以便能在这些设备上为您提供一致的服务。
            </p>
            <p>
              <strong>8.</strong
              >提供相关AI服务。上海晓材及其关联公司通过授权软件、上海晓材在线服务提供诸如文档智能美化等部分AI服务，您同意数据、资料进行上传，即表明您同意上海晓材有权自该等数据、资料中收集您的个人信息；同时，为了使该服务更加符合您的个人偏好、需求，上海晓材会基于其所查阅、读取的内容依照上海晓材的理解为您设置特征标签。但上海晓材会采取安全措施和技术手段保护您的个人信息，并将通过技术手段对该等数据、资料进行去标识化处理，以降低其他组织或个人通过去标识化处理后的个人信息识别到您的风险。
            </p>
            <p>
              <strong>8.</strong
              >保证安全。为提高您使用授权软件、上海晓材在线服务时系统的安全性，更准确地预防钓鱼网站欺诈和保护账户安全，上海晓材可能会通过了解您的浏览信息、<strong>订单信息</strong>、软件信息、设备信息等手段来判断您的账号风险，并可能会记录一些上海晓材认为有风险的链接（“URL”）。上海晓材也会收集您的设备信息、日志信息对于您在使用授权软件、上海晓材在线服务时的问题进行分析、统计流量并排查可能存在的风险、在您选择向上海晓材发送异常信息时予以排查。
            </p>
            <p>
              <strong>10.</strong
              >客服与售后。如您在使用授权软件、上海晓材在线服务寻求服务支持时，上海晓材会根据您的<strong>账号信息、订单信息</strong>核对您的身份及购买的相关产品或服务（如有），并根据您的软件信息、设备信息以及您发送的邮件内容、截图、文档、或与上海晓材客服人员的电话对话内容帮您查找原因、解决问题；上海晓材可能会不时使用您的<strong>账号信息（如手机号、邮箱地址）</strong>来发送重要通知，例如有关兑换物的通知和相关协议条款、条件和政策变更；如果您通过上海晓材在线服务的相关网站页面、微信公众号等参与了摸奖、比赛或类似的促销，上海晓材可能会要求您提供<strong>身份证号</strong>来核实您的真实身份；上海晓材还会收集您参与问卷调查时向上海晓材提交的问卷答复信息、您通过拨打客服热线、发送电子邮件、论坛发帖、公众号留言等各种方式提交咨询时所提供的联系人姓名、公司名称、<strong>联系电话、邮箱地址</strong>及您的咨询内容。
            </p>
            <p>
              <strong>11.</strong
              >支付结算：在您下单购买上海晓材在线服务的任意服务后，您可以选择与上海晓材合作的第三方支付机构（如支付宝、微信支付等支付通道，以下称“支付机构”）所提供的支付服务，上海晓材需要将您的<strong>订单号、交易金额、交易内容</strong>的信息提供给支付机构以确认您的支付指令并完成支付。如您申请发票，还需提供以下信息：发票抬头、纳税人识别号、<strong>收票人邮箱地址</strong>，以及您根据您的开票需求提供的更多信息，如购买方信息。
            </p>
            <p><strong>12.</strong>大数据分析。上海晓材会将所收集的您的个人信息用于大数据分析。</p>
            <p><strong>13.</strong>遵从和执行适用的法律要求及相关的行业标准。</p>
            <p>
              <strong>14.</strong
              >在您使用软件过程中，我们可能会在后台收集您的设备信息(比如:Mac地址等)，用于及时向您推送信息和通知。
            </p>
            <p>
              <strong>15.</strong
              >在使用我们服务时搜索或浏览的信息，例如您使用的网页搜索词语、访问的社交媒体页面url地址，以及您在使用我们服务时浏览或要求提供的其他信息和内容详情。
            </p>
            <p>
              <strong>16.</strong
              >当您使用安卓或lOS版本平台APP时，我们为您提供分享和充值的应用服务。我们需从您的终端读取已安装应用列表，当您分享时用于在分享文件时判断第三方应用是否已安装在本机。
            </p>
            <p>
              <strong>17.</strong
              >当您分享活动口令与链接时，我们将收集您的昵称，并调用您的剪切板功能以判断是否有相关口令码信息，我们收集该信息在服务器做校验后向您反馈您所需的内容或绑定相关权益。我们不会存储您剪切板的其他信息。
            </p>
            <p><strong>18.</strong>其他业务功能</p>
            <p>
              为了更好的提升产品体验，我们需要向您发送推送消息、和对您的部分操作数据进行采集分析，因此在CAD迷你看图中，我们集成了极光SDK，极光SDK需要收集采集设备标识符(IMEI/Mac/Android
              ID/IDFA/OPENUDID/GUID/SIM
              卡IMSI/地理位置)，用于唯一标识设备，以便向目标设备推送消息。采集地理位置甄别推送通道，提高消息推送的区域覆盖率。
              另外其他相关三方SDK的使用，本协议已在条目二“间接获取、使用个人信息的情形”中详细的列举出来，请您使用产品和服务前仔细阅读并同意相关条文（极光SDK隐私权政策链接：<a
                href="https://www.jiguang.cn/license/privacy"
                target="_blank"
                class="link"
                >https://www.jiguang.cn/license/privacy</a
              >）。
            </p>
            <p><strong>(二) 间接获取、使用个人信息的情形</strong></p>
            <p>
              <strong>1.</strong
              >当您使用上海晓材关联公司服务时，或您的上海晓材系列软件账号与上海晓材关联公司的服务账号绑定后，您同意上海晓材根据实际业务及合作需要，如为了验证用户身份、向用户更有效的推送上海晓材关联公司的营销信息，而从上海晓材的关联公司处接收、使用、汇总、分析经您授权同意向上海晓材提供的您的账号信息、设备信息、软件信息以及日志信息。
            </p>
            <p>
              <strong>2.</strong
              >我们可能从第三方账号提供方获取您授权共享的账户信息（头像、昵称），并在您同意本政策后将您的第三方账户与您的上海晓材账号绑定，使您可以通过第三方账户直接登录并使用上海晓材的服务。
            </p>
            <p>
              <strong>3.</strong
              >当您通过授权使用您的上海晓材在线服务账号（包括您用于登录上海晓材在线服务的上海晓材系列软件账号、QQ账号、微信账号、新浪微博账号、小米账号及其他账号（如有））绑定使用与上海晓材有合作关系的第三方的服务时，您同意上海晓材为了为了验证用户身份、确保该等第三方服务与上海晓材在线服务、授权软件的兼容性、监督第三方服务质量，而从该等有合作关系的第三方处接收、共享、使用、汇总、分析您的账号信息、设备信息、软件信息以及日志信息。
            </p>
            <p>
              <strong>4.</strong
              >当与上海晓材有合作关系的第三方将与上海晓材在线服务相关的业务、服务、产品转让、出售给上海晓材时，您同意上海晓材为了保证前述业务、服务、产品的稳定接续性，为了给您继续提供相关服务、产品，而从前述有合作关系的第三方处受让、共享、使用、汇总您的账号信息、设备信息、软件信息以及日志信息。
            </p>
            <p>
              <strong>5.</strong
              >如您从上海晓材的经销商、代理商渠道下单购买授权软件、上海晓材在线服务，则上海晓材将从该等第三方渠道获取您的订单信息，包括您的姓名、公司名称、电话号码、收货或联系地址、所购买的具体授权软件、上海晓材在线服务的具体内容信息。
            </p>
            <p><strong>(三) 其他用途</strong></p>
            <p>
              <strong>1.</strong
              >上海晓材可能使用您的个人信息，通过电子邮件、手机短信或其他方式向您发送营销信息，向您提供与您更加相关的广告以替代普遍投放的广告，例如向您展现或推荐相关程度更高（而非普遍推送）的搜索结果、信息流或者广告/推广信息结果。如您不希望上海晓材将您的个人信息用作接收营销信息的用途，您可以通过上海晓材在广告中提供的相关指示，要求上海晓材停止为上述用途使用您的个人信息。上海晓材可能使用您的个人信息向您的设备推送新闻或其他通知。如您不希望收到这些信息，您可以按照上海晓材向您发出的相关指示，在设备上选择取消订阅。同时，上海晓材可能在必需时通过电子邮件、手机短信或其他方式发出与授权软件、上海晓材在线服务有关的公告，您可能无法取消这些与授权软件、上海晓材在线服务有关的、性质不属于推广信息的公告。您知悉并认可，您对营销信息、广告信息、推送信息的点击行为可能会导致上海晓材、上海晓材关联公司或第三方合作伙伴对您的点击行为进行收集；如您拒绝上海晓材、上海晓材关联公司或第三方合作伙伴收集您对营销信息、广告信息、推送信息的点击行为，请您不要点击任何上海晓材向您发送的营销信息、广告信息、推送信息。
            </p>
            <p>
              <strong>2.</strong
              >上海晓材会通过技术手段对收集的您的个人信息进行去标识化处理，去标识化处理的信息将无法识别主体，不会侵害您的利益，您知悉并同意，上海晓材有权使用已经去标识化的信息。在不透露您个人信息的前提下，上海晓材有权对用户数据库进行分析以获得用户行为分析及用户画像等数据，供产品设计参考以及以商业化的利用。
            </p>
            <p>
              <strong>3.</strong
              >上海晓材会用您的个人信息对授权软件、上海晓材在线服务的使用进行总体性及匿名的数据统计及分析，所得数据可供上海晓材及其认为有必要知悉该等信息的主体为实现本政策约定的目的使用。同时，上海晓材可能会与公众共享这些统计信息，以展示授权软件、上海晓材在线服务的整体使用趋势，但这些统计信息将不会包含您的任何身份识别信息。
            </p>
            <p>
              <strong>4.</strong
              >当上海晓材展示您的个人信息时，会采用包括内容替换、匿名处理方式对您的信息进行脱敏，以保护您的信息安全。
            </p>
            <p>
              <strong>5.</strong
              >在上海晓材认为有必要的前提下，将您的个人信息提交上海晓材认可的第三方机构进行包括但不限于恶意刷单、恶意刷量等的风险检测评估。
            </p>
            <p><strong>(四) 征得同意的例外</strong></p>
            <p>您充分知悉，在以下情形中，我们收集、使用个人信息无须征得您的授权同意：</p>
            <p><strong>1.</strong>与国家安全、国防安全有关的；</p>
            <p><strong>2.</strong>与公共安全、公共卫生、重大公共利益有关的；</p>
            <p><strong>3.</strong>与犯罪侦查、起诉、审判和判决执行等有关的；</p>
            <p>
              <strong>4.</strong
              >出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；
            </p>
            <p><strong>5.</strong>所收集的个人信息是个人信息主体自行向社会公众公开的；</p>
            <p>
              <strong>6.</strong
              >从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；
            </p>
            <p><strong>7.</strong>根据您的要求签订合同所必需的；</p>
            <p>
              <strong>8.</strong
              >用于维护所提供的产品或服务的安全稳定运行所必需的，例如发现、处置产品或服务的故障；
            </p>
            <p>
              <strong>9.</strong
              >学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的；
            </p>
            <p><strong>10.</strong>法律法规规定的其他情形。</p>
            <p>
              您知悉并认可，共享、转让经去标识化处理的个人信息，且确保数据接收方无法复原并重新识别个人信息主体的，不属于个人信息的对外共享、转让及公开披露行为，对此类数据的保存及处理将无需另行向您通知并征得您的同意。
            </p>
            <p>
              上海晓材收集您的个人信息的内容、来源及方式会因您所使用的授权软件的不同（包括但不限于不同的授权软件、同一授权软件的不同收费方式的版本、不同语言版本、不同客户端等）或者上海晓材在线服务的不同而不同，如您想要了解更多信息，请发送邮件至<a
                href="javascript:void(0);"
                class="link"
                >help@52cad.com</a
              >，上海晓材将对您的来信予以积极回应。
            </p>
            <p><strong>(五) 自启动和关联启动说明</strong></p>
            <p>
              <strong>1.</strong
              >为确保本应用处于关闭或后台运行状态下可正常接收到客户端推送的广播信息，本应用须使用（自启动）能力，将存在一定频率通过系统发送广播唤醒本应用自启动或关联启动行为，是因实现功能及服务所必要的。
            </p>
            <p>
              <strong>2.</strong
              >当您打开CAD迷你看图APP内容类推送消息，在征得您的明确同意后，会跳转CAD迷你看图APP打开相关内容。在未征得您同意的情况下，则不会有关联启动。
            </p>
            <p><strong>3.</strong>当您打开CAD迷你看图APP内部下载的文件后，会关联启动第三方APP。</p>
            <h3>三、如何使用Cookie和同类技术</h3>
            <p>
              （一）Cookie:为确保网站正常运转，上海晓材会在您的计算机或移动设备上存储名为Cookie
              的小数据文件。Cookie 通常包含标识符、站点名称以及一些号码和字符。借助于
              Cookie，网站能够存储互联网协议 (IP) 地址、浏览器类型、互联网服务供应商
              (ISP)、引用/退出页面、操作系统、日期/时间戳和/或点击流数据、您的偏好等数据。上海晓材不会将
              Cookie 用于本政策所述目的之外的任何用途。您可根据自己的偏好管理或删除
              Cookie。您可以清除计算机上保存的所有 Cookie，大部分网络浏览器都设有阻止Cookie
              的功能，但如此，则您需要在每一次访问上海晓材在线服务相关网站时亲自更改用户设置日志文件。您可以通过更改您的浏览器设置限制上海晓材对Cookie的使用。
            </p>
            <p>
              （二）网站信标和像素标签。除 Cookie
              外，上海晓材还会在网站上使用网站信标和像素标签等其他同类技术。例如，上海晓材向您发送的电子邮件可能含有链接至上海晓材在线服务相关网站内容的点击
              URL。如果您点击该链接，上海晓材则会跟踪此次点击，帮助上海晓材了解您对授权软件、上海晓材在线服务偏好并改善。网站信标通常是一种嵌入到网站或电子邮件中的透明图像。借助于电子邮件中的像素标签，上海晓材能够获知电子邮件是否被打开。如果您不希望自己的活动以这种方式被追踪，则可以随时从上海晓材的寄信名单中退订。
            </p>
            <p>
              （三）Do Not Track（请勿追踪）。很多网络浏览器均设有 Do Not Track
              功能，该功能可向网站发布 Do Not Track
              请求。目前，主要互联网标准组织尚未设立相关政策来规定网站应如何应对此类请求。但如果您的浏览器启用了
              Do Not Track，那么上海晓材的所有网站都会尊重您的选择。
            </p>
            <p>
              （四）移动分析软件：在授权软件的移动客户端中，上海晓材使用移动分析软件，以更好地了解授权软件在您的手机中的功能。此款软件可能记录以下信息，如您使用该应用程序的频率、该应用程序内发生的事件、累计使用、性能数据及应用程序崩溃发生的位置。上海晓材不会将存储于分析软件内的信息链接到您在授权软件移动客户端中提交的任何个人信息。
            </p>
            <p>
              （五）SDK的使用：为保障授权软件客户端的稳定运行、功能实现，使您能够享受和使用更多的服务及功能，授权软件中会嵌入授权合作伙伴的SDK或其他类似的应用程序，授权软件接入授权合作伙伴的SDK情况详见网址为<a
                href="https://www.olcad.com/privacy_sdk.html"
                class="link"
                >https://www.olcad.com/privacy_sdk.html</a
              >的网页。
            </p>
            <h3>四、如何共享、转让、公开披露您的个人信息</h3>
            <p>(一) 共享</p>
            <p>
              上海晓材不会与上海晓材以外的任何公司、组织和个人分享您的个人信息，但以下情况除外：
            </p>
            <p>
              <strong>1.</strong
              >在获取明确同意的情况下共享：获得您的明确同意后，上海晓材可能会与第三方共享您的个人信息。
            </p>
            <p>
              <strong>2.</strong
              >上海晓材可能会按照法律法规的要求或强制性的政府要求或司法裁定等，与相关的第三方共享您的个人信息。
            </p>
            <p>
              <strong>3.</strong
              >与关联公司共享：为了提升上海晓材向您所推送的关联公司的产品、服务，您的个人信息可能会与上海晓材有合作关系的关联公司共享，用于其产品、服务的身份验证、客户服务、安全防范、诈骗监测、信贷分析等，上海晓材会将收集到的您的个人信息进行大数据分析。上海晓材只会共享必要的个人信息，关联公司如果要改变个人信息的处理目的，将再次征求您的授权同意。
            </p>
            <p>
              <strong>4.</strong>与授权合作伙伴共享：
              仅为实现本政策中声明的若干目的，上海晓材的某些服务将由第三方合作伙伴提供。上海晓材可能会第三方与合作伙伴共享您的某些个人信息，以提供更好的客户服务和用户体验。上海晓材仅会出于合法、正当、必要、特定、明确的目的共享您的个人信息，并且只会共享与提供服务所必要的个人信息。上海晓材的第三方合作伙伴无权将共享的个人信息用于任何其他用途。您同意上海晓材与如下第三方共享个人信息：
            </p>
            <p>
              4.1.广告、分析服务类的合作伙伴。除非得到您的许可，否则上海晓材不会将您的个人身份信息与提供广告、分析服务的合作伙伴分享。上海晓材会向这些合作伙伴提供有关其广告覆盖面和有效性的信息，而不会提供您的个人身份信息，或者上海晓材将这些信息进行汇总，以便它不会识别您个人。例如，上海晓材可能会告诉广告主他们广告的效果如何，或者有多少人看了他们广告或在看到广告后安装了应用，或者向这些合作伙伴提供不能识别个人身份的人口统计信息（例如“位于北京的25岁男性，喜欢软件开发”），
              帮助他们了解其受众或顾客。上海晓材会向这些合作伙伴提供您的设备识别码，帮助他们了解您是否为其受众或顾客。
            </p>
            <p>
              4.2.供应商、服务提供商和其他合作伙伴。上海晓材将信息发送给在全球范围内支持上海晓材业务的供应商、服务提供商和其他合作伙伴，这些支持包括提供技术基础设施服务、软硬件产品服务、系统产品服务、分析服务的使用方式、衡量广告和服务的有效性、提供客户服务、支付便利或进行学术研究和调查以及为需要通过上海晓材系列软件账号登录、使用授权软件、上海晓材在线服务中接入的第三方产品、服务共享您的账号、头像、昵称、性别。对上海晓材与之共享您个人信息的公司、组织和个人，上海晓材会要求他们按照上海晓材的说明、本隐私政策以及其他任何相关的保密和安全措施来处理个人信息。
            </p>
            <p>
              4.3.如您通过上海晓材在线服务的相关网站页面、微信公众号等参与了摸奖、比赛或类似的促销或者使用积分兑换商品，上海晓材或者相关奖品、商品的供货商需要向您寄送相关奖品、商品时，您作为收件人，您的信息（姓名、电话号码（包括手机号）、联系地址）将被提供给快递服务合作伙伴顺丰、圆通（具体以网页上显示的为准）或者相关供货商。
            </p>
            <p>(二) 转让</p>
            <p>
              上海晓材不会将您的个人信息转让给关联公司外的任何公司、组织和个人，但以下情形除外：
            </p>
            <p><strong>1.</strong>事先获得您的明确授权或同意；</p>
            <p><strong>2.</strong>满足法律法规、法律程序的要求或强制性的政府要求或司法裁定；</p>
            <p>
              <strong>3.</strong
              >如果上海晓材或上海晓材的关联公司涉及合并、分立、清算、资产或业务的收购或出售等交易，您的个人信息有可能作为此类交易的一部分而被转移，上海晓材将确保该等信息在转移时的机密性，并要求新的持有您个人信息的公司、组织继续受此隐私政策的约束，否则上海晓材将要求该公司、组织重新向您征求授权同意。
            </p>
            <p>(三) 公开披露</p>
            <p>上海晓材仅会在以下情形下，公开披露您的个人信息：</p>
            <p><strong>1.</strong>获得您的明确同意；</p>
            <p><strong>2.</strong>在公布中奖活动名单时会脱敏展示中奖者手机号或账户登录名；</p>
            <p>
              <strong>3.</strong
              >上海晓材可能对外公开并与有合作关系的第三方分享经统计加工后不含身份识别内容的大数据分析信息。
            </p>
            <p>
              <strong>4.</strong
              >如果上海晓材接到第三方对您的投诉或举报，上海晓材有权向投诉方披露您的必要信息（包括但不限于注册名称、身份证明、联系人、联系电话等），并督促您与投诉方进行协商，以便及时解决投诉、纠纷，保护各方合法权益。
            </p>
            <p><strong>5.</strong>基于法律法规、法律程序、诉讼或政府主管部门强制性要求下。</p>
            <h3>五、如何保护和保存您的个人信息</h3>
            <p>
              （一）上海晓材将努力使用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。上海晓材会采取一切合理可行的措施，确保未收集无关的个人信息。上海晓材只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。
            </p>
            <p>
              （二）由于互联网并非绝对安全的环境，且电子邮件、即时通讯及与其他第三方的线上交流方式可能并未加密，上海晓材强烈建议您不要通过此类方式发送个人信息。同时，在注册使用上海晓材在线服务账户时，请注意使用复杂密码，协助上海晓材保证您的账号安全。
            </p>
            <p>
              （三）上海晓材经授权软件、上海晓材在线服务收集的您的个人信息可能会在上海晓材及其关联公司所在地进行存储和处理，也有可能会在上海晓材认为有必要知悉该等信息的主体处进行保存和处理，但前述地点均限于中国大陆范围内。上海晓材仅在本政策所述目的所必需的期间和法律法规要求的时限内保留您的个人信息。上海晓材会严格遵守中国大陆关于收集、使用、保存、共享用户个人信息的相关法律法规，使用各种安全技术和程序，以防信息的丢失、不当使用、未经授权阅览或披露。
            </p>
            <h3>六、查询、更正信息的渠道及删除账号</h3>
            <p>
              （一）如果您需要查询、修改或更正您的个人信息，或对您的个人信息保护问题有任何疑问或投诉举报建议，您可以通过发送邮件至上海晓材电子邮箱【<a
                href="javascript:void(0);"
                class="link"
                >help@52cad.com</a
              >】，上海晓材将及时有效地处理您的问题，或者在相关App内查询、修改您的账号。
            </p>
            <p>
              （二）如果您需删除您的上海晓材系列软件帐号或将您授权的登录、使用上海晓材在线服务的第三方账号与上海晓材系列软件账户解除绑定，则您需通过拨打客服电话【<a
                href="javascript:void(0);"
                class="link"
                >400-600-2063</a
              >】或发送邮件至上海晓材电子邮箱【<a href="javascript:void(0);" class="link"
                >help@52cad.com</a
              >】，上海晓材会帮助您删除上海晓材系列软件帐号或将您授权的登录、使用上海晓材在线服务的第三方账号与上海晓材系列软件的账户解除绑定，或者在相关App内操作。删除上海晓材系列软件账号或将第三方账号解除绑定后，您账户内的所有文档、帐号信息等都将被清空，且无法恢复。
            </p>
            <h3>七、对未成年人信息的保护</h3>
            <p>
              上海晓材十分重视对未成年人信息的保护，上海晓材不会有意向18周岁以下的未成年人收集或请求个人信息。若您是18周岁以下的未成年人，请您不要上海晓材发送您的个人信息；同时，您在使用授权软件、上海晓材在线服务前，应事先取得您的家长或法定监护人的同意，建议您的父母或监护人仔细阅读本政策，同时建议您在您的父母或监护人的指导下使用授权软件、上海晓材在线服务。一旦您以任何方式下载、安装、使用授权软件、登录、注册、使用上海晓材在线服务的任何部分或全部，即表明您的家长或法定监护人同意您使用授权软件、上海晓材在线服务并同意本政策的全部内容，同时允许上海晓材依据本政策收集、使用、保存、共享您的个人信息。如果父母或监护人有理由相信未成年人未经他们事先同意而向上海晓材提交了未成年人的个人信息，请联系上海晓材以确保删除此类个人信息。
            </p>
            <h3>八、您的个人信息如何在全球范围转移</h3>
            <p>
              原则上，上海晓材将在中华人民共和国境内收集和产生的个人信息存储于中华人民共和国境内。您理解并同意，由于上海晓材通过遍布全球的资源和服务器提供产品或服务，这意味着，上海晓材可能根据公司运营的实际需求将根据本政策搜集的信息存储于中国大陆以外的其他国家/地区的服务器上、可能将您的个人信息的部分/全部提供给上海晓材境外的关联公司、与上海晓材及/或上海晓材关联公司有合作关系的中国大陆以外的第三方。此类国家/地区可能设有不同的数据保护法，甚至未设立相关数据保护法律，如果您不同意信息出境或者您想要了解更多信息，请发送邮件至<a
                href="javascript:void(0);"
                class="link"
                >help@52cad.com</a
              >，上海晓材将根据您的来信妥善处理，上海晓材提供的授权软件和在线服务可能会因为您不同意信息出境而无法使用。
            </p>
            <h3>九、本政策如何更新</h3>
            <p>
              本政策之规定可能会不定期地修改，本政策一旦发生修改，将会在网址为【<a
                href="https://www.olcad.com/privacy.html"
                class="link"
                >https://www.olcad.com/privacy.html</a
              >】的网站上根据修改时间相应调整本政策载明的更新时间，如果您不同意上海晓材修改的内容，请您在本政策修改后（即修改后的本政策载明的更新时间后）不要再使用授权软件、上海晓材在线服务的任何部分或全部，一旦您在本政策修改后出现任何使用授权软件、上海晓材在线服务的任何部分或全部子服务的行为，则表明您已阅读、知悉并同意修改后的本政策全部内容。上海晓材在此建议您登录网址为【<a
                href="https://www.olcad.com/privacy.html"
                class="link"
                >https://www.olcad.com/privacy.html</a
              >】的网页定期查阅本政策，以随时了解本政策是否发生修改以及修改后的内容。
            </p>
            <h3>十、本政策的适用范围</h3>
            <p>
              <strong>1.</strong
              >本政策适用于全部版本的授权软件及上海晓材在线服务所包括的全部子服务。如某一版本授权软件或某一特定的子服务对收集信息事项有特殊的规定，需同时适用该特殊规定及本政策；若该特殊规定与本政策有不一致之处，则适用该特殊规定。
            </p>
            <p>
              <strong
                >2.请您注意，本政策不适用于第三方提供的产品和服务。授权软件、上海晓材在线服务可能包括第三方的产品和服务，以及第三方网站的链接。当您使用这些产品或服务时，也可能收集您的信息。因此，强烈建议您花时间阅读该第三方的隐私政策，就像阅读本政策一样。上海晓材不对第三方如何使用他们向您收集的个人信息负责，也不能控制其使用。本政策不适用通过授权软件、上海晓材在线服务链接的其他网站。</strong
              >
            </p>
            <h3>十一、特别提示</h3>
            <p>
              本政策所述的上海晓材认为有必要知悉该等信息的主体包括上海晓材的员工及其他上海晓材有必要知悉该等信息的个人、机构或组织，您知悉并同意上海晓材通过授权软件、上海晓材在线服务收集您的个人信息，上海晓材可以向其认为有必要知悉该等信息的主体披露，但是上海晓材保证其认为有必要知悉该等信息的主体都只能在为实现本政策约定的目的使用该等信息。
            </p>
            <p>
              本政策各条款的标题仅为方便用户阅读列出，各条款表达的具体信息仍以各条款载明的实际内容为准。
            </p>
            <p>
              <strong
                >上海晓材认为，且您亦同意，您在使用授权软件、上海晓材在线服务前已经仔细阅读并完全理解本政策的全部内容，一旦您使用授权软件、上海晓材在线服务，则表明您同意本政策的全部内容且承诺受本政策之约束。</strong
              >
            </p>
          </div>
        </div>
      </div>
    </div>

    <input
      id="input_upload"
      style="position: absolute; opacity: 0; width: 1px; height: 1px; cursor: pointer"
      type="file"
      multiple="false"
      name="input_upload"
      accept=".dwg, .dxf"
      ref="mainfileRef"
      @change="main_upload"
    />

    <Login
      :cur_show_box="cur_show_box"
      :is_show_login="is_show_login"
      v-if="is_show_login"
      @change_child_value="change_child_value"
      @updateUserinfo="updateUserinfo"
    ></Login>
  </div>
</template>

<script>
import Login from "@/components/login";

import { login_check, usertoken_login } from "@/api/user";
import utils from "@/utils/utils";
import Common from "@/utils/common";
import { upload_dwg } from "@/api/get_cloud";

export default {
  name: "Home_page",
  components: { Login },
  data() {
    return {
      headerTitle: Common.headerTitle,
      headerTitle1: Common.headerTitle1,
      https_1: Common.https_1,
      is_show_login: false,
      cur_show_box: "login",
      userinfo: null,
      is_show_phone_or_pc: true,
      is_show_down_app_qocde: false,
      is_show_consult: false,
      isChecked: false,
      img1: require("@/assets/images/main/checked1.png"),
      img2: require("@/assets/images/main/checked.png"),
      isShowXieyiText: false,
    };
  },
  beforeCreate() {
    // utils.isIE(this)
    // utils.getBrowser(this)
  },
  mounted() {
    var usertoken = encodeURIComponent(utils.getQueryVariable("usertoken"));
    if (usertoken == "") {
      this.loginCheck();
    } else {
      this.auto_usertoken_login(usertoken, "token");
    }

    //监听键盘按键事件
    let self = this;
    this.$nextTick(() => {
      document.addEventListener("keyup", function (e) {
        //此处填写你的业务逻辑即可
        if (e.keyCode == 27) {
          self.hideConsultBox();
        }
      });
    });
  },

  methods: {
    hideConsultBox() {
      this.is_show_consult = false;
      this.$store.state.is_show_xieyi = false;
      this.isShowXieyiText = false;
    },

    onChangeChecked() {
      this.isChecked = !this.isChecked;
    },

    // 打开图纸
    uploadFileMain() {
      if (!this.$store.state.userinfo) {
        utils.del_localStorae();
        this.is_show_login = true;
        return;
      }
      if (!this.isChecked) return;
      this.$refs.mainfileRef.dispatchEvent(new MouseEvent("click"));
    },

    main_upload(e) {
      if (e.target.files.length != 1) {
        this.$refs.mainfileRef.value = "";
        this.$msg.whiteerr("目前仅支持单文件上传~");
        return;
      }
      var file = e.target.files[0];
      this.uploadDwg(file);
    },

    uploadDwg(file) {
      let that = this;
      const fd = new FormData();
      fd.set("file", file);
      fd.set("uid", this.$store.state.userinfo.uid);
      fd.set("parent_dir", "/");
      upload_dwg(fd).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          that.$msg.white("正在打开图纸...");
          setTimeout(() => {
            that.$router.push({ path: "/dwg", query: { file_id: data.file_id } });
          }, 200);
          that.hideConsultBox();
        } else if (data.code == 3) {
          that.$msg.whiteerr("内存已满，请清理云盘空间");
        } else if (data.code == 99) {
          utils.del_localStorae();
          that.is_show_login = true;
          that.$msg.whiteerr("登录信息已过期，请重新登录");
        }
        that.$refs.mainfileRef.value = "";
      });
    },

    loginCheck() {
      var that = this;
      login_check().then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          that.userinfo = data.data;
          localStorage.setItem("USERTOKEN", JSON.stringify(data.data.user_token));
          localStorage.setItem("USER_INFO", JSON.stringify(data.data));
          that.$store.state.userinfo = data.data;
        } else {
          that.userinfo = null;
          that.$store.state.userinfo = null;
          // utils.del_localStorae()
          that.auto_login();
        }
      });
    },

    // 自动登录
    auto_login() {
      var USER_INFO_TIME = parseInt(localStorage.getItem("USER_INFO_TIME"));
      var cur_time = new Date().getTime();
      if (cur_time < 7 * 86400 * 1000 + USER_INFO_TIME || USER_INFO_TIME == null) {
        // 7天内登录过
        var USER_TOKEN = JSON.parse(localStorage.getItem("USERTOKEN"));
        if (USER_TOKEN != null) {
          //token存在
          this.auto_usertoken_login(USER_TOKEN, "auto");
        } else {
          //token不存在
          utils.del_localStorae();
          this.userinfo = null;
        }
      } else {
        utils.del_localStorae();
        this.userinfo = null;
      }
    },

    auto_usertoken_login(user_token, from) {
      var that = this;
      var send = {
        user_token: user_token,
      };
      usertoken_login(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          var __userinfor = data.user_info;
          that.userinfo = data.user_info;
          localStorage.setItem("USER_INFO_TIME", new Date().getTime());
          localStorage.setItem("USER_INFO", JSON.stringify(__userinfor));
          localStorage.setItem("USERTOKEN", JSON.stringify(__userinfor.user_token));
          that.$store.state.userinfo = __userinfor;
          if (from == "token") {
            this.$router.push({
              name: "my_drawings",
            });
          }
        } else {
          // that.$message.error('登录信息已过期，请重新登陆')
          if (from == "token") {
            that.$msg.whiteerr("登录信息已过期，请重新登陆");
            that.$router.push({
              name: "home",
            });
          }
          utils.del_localStorae();
          that.userinfo = null;
        }
      });
    },

    //下载客户端
    down_kehuduan_btn() {
      utils.down_kehuduan();
    },

    go_cloud() {
      var user_token = JSON.parse(localStorage.getItem("USERTOKEN"));
      if (user_token != null) {
        this.$store.state.isClickXieyi = true;
        this.$store.state.is_show_xieyi = true;
      } else {
        this.$store.state.isClickXieyi = true;
        this.is_show_login = true;
      }
    },

    go_cloud1() {
      this.$router.push("/recently_opened");
    },

    go_shilitu(index) {
      var share_id;
      switch (index) {
        case 1:
          share_id = "hRBQGQU4FCuP";
          break;
        case 2:
          share_id = "eW9h4FPgKRAy";
          break;
        case 3:
          share_id = "3mawGiFrGDgt";
          break;
        case 4:
          share_id = "cIx9QHx0BTdJ";
          break;
        case 5:
          share_id = "dioGav3ajynZ";
          break;
        default:
          break;
      }
      let pathInfo = this.$router.resolve({
        path: "/dwg",
        query: {
          share_id: share_id,
        },
      });
      window.open(pathInfo.href, "_blank");
    },

    change_child_value(data) {
      this.is_show_login = data;
    },

    updateUserinfo(data) {
      this.userinfo = data;
    },

    checkout_shitu(data) {
      if (data == "phone") {
        this.is_show_phone_or_pc = true;
      } else {
        this.is_show_phone_or_pc = false;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

/deep/ body {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "STHeitiSC-Light",
    "Microsoft YaHei", "SimSun", sans-serif;
  height: auto !important;
}

::-webkit-scrollbar {
  /* 滚动条整体部分 */
  width: 5px;
  margin-right: 2px;
}

::-webkit-scrollbar-button {
  /* 滚动条两端的按钮 */
  width: 10px;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
  margin-bottom: 2px;
}

::-webkit-scrollbar-track {
  /* 外层轨道 */
  border-radius: 5px;
}

::-webkit-scrollbar-track-piece {
  /*内层轨道，滚动条中间部分 */
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  /* 滑块 */
  width: 5px;
  border-radius: 5px;
  background: #cbcbcb;
}

::-webkit-scrollbar-corner {
  /* 边角 */
  width: 5px;
}

::-webkit-scrollbar-thumb:hover {
  /* 鼠标移入滑块 */
  background: #cbcbcb;
}

#main_header_bg {
  background-color: #3568c0;
  top: 0;
  width: 100%;
  border: none;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  z-index: 1;
  position: fixed;
  min-width: 1250px;
}

#header_imgUrl {
  background-size: auto 100%;
  width: 100%;
  height: 100%;
  background-image: url("@/assets/images/main/banner.png");
  background-position: center 100%;
  background-repeat: no-repeat;
}

.main_header_bg_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1300px;
  min-width: 600px;
}

.main_header_left {
  width: 35%;
  display: flex;
  align-items: center;
  z-index: 1;
}

.main_header_left a {
  display: flex;
  align-items: center;
  cursor: default;
}

.main_header_right {
  width: 65%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.main_header_title {
  font-family: SourceHanSansSC-Bold;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-left: 10px;
}

.main_header_title span {
  font-family: SourceHanSansSC-Bold;
  font-size: 18px;
}

.main_header_bg_flex1 {
  justify-content: flex-end;
}

.go_to_login,
.go_to_reg,
.go_to_mycloud {
  width: 100px;
  height: 34px;
  border-radius: 4px;
  border: solid 1px #fff;
  text-align: center;
  line-height: 34px;
  color: #ffffff;
  cursor: pointer;
  user-select: none;
  font-size: 16px;
}

.go_to_mycloud {
  width: 120px;
}

.go_to_mycloud:hover {
  opacity: 0.8;
}

.go_to_reg {
  border: none;
  margin-left: 10px;
}

.el-header {
  padding: 0;
  height: 80px !important;
}

.top_bg_banner_box {
  position: relative;
  width: 100%;
  height: 712px;
  background: #f0f4fc;
}

.header_imgUrl_title_box {
  width: 1300px;
  height: 281px;
  position: absolute;
  top: calc(8% + 80px);
  left: 50%;
  margin-left: -650px;
  overflow: hidden;
}

.header_imgUrl_title1 {
  font-family: SourceHanSansSC-Bold;
  font-size: 50px;
  font-weight: bold;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
}

.header_imgUrl_title2 {
  font-family: SourceHanSansSC-Medium;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 18px;
  margin-bottom: 40px;
}

.header_imgUrl_title3 {
  font-family: SourceHanSansSC-Regular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-bottom: 28px;
}

.header_imgUrl_title_open_dwg {
  width: 220px;
  height: 70px;
  background-color: #ffffff;
  border-radius: 8px;
  font-family: SourceHanSansSC-Medium;
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #2072ec;
  text-align: center;
  line-height: 70px;
  user-select: none;
  cursor: pointer;
}

.shili_flex {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0px 0 30px 0;
  /* overflow: auto; */
}

.shili_item {
  padding: 8px;
  width: 184px;
  height: 165px;
  background: #fff;
  border-radius: 6px;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  color: #333333;
}

.shili_item:hover {
  box-shadow: -5px 5px 15px 1px rgb(0 0 0 / 10%);
  color: #2072ec;
}

.shili_item_img img {
  width: 182px;
  height: 118px;
  border-radius: 6px;
}

.shili_item_name {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  width: 100%;
}

.shili_item_size {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #fff;
  position: absolute;
  right: 0;
  bottom: 0;
  background-image: url("@/assets/images/main/shilitu_biaoji.png");
  width: 64px;
  height: 24px;
  line-height: 24px;
  text-align: center;
  background-color: #2072ec;
  border-radius: 5px 0px 5px 0px;
}

.shili_head img {
  width: 30px;
  height: 30px;
  margin-right: 15px;
  cursor: pointer;
}

.shili_box {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -80px;
}

.main_2 {
  background-color: #f0f4fc;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 50px;
  height: 1220px;
}

#appA {
  width: 100%;
  height: 100%;
  min-width: 1280px;
}

.main_2_title1 {
  font-family: SourceHanSansSC-Bold;
  font-size: 48px;
  font-weight: 700;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #313131;
}

.main_2_title1 span {
  font-family: SourceHanSansSC-Bold;
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #2072ec;
}

.main_2_title2 {
  font-family: SourceHanSansSC-Regular;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #5b687c;
  margin-top: 20px;
}

.main_2_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1300px;
  height: 780px;
  background-color: #ffffff;
  box-shadow: 0px 8px 10px 0px rgba(154, 169, 188, 0.24);
  border-radius: 30px;
  border: solid 1px #e7e7e7;
  margin-top: 80px;
}

.main_2_flex_left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 520px;
}

.main_2_flex_right {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 780px;
  height: 780px;
  background-image: linear-gradient(136deg, #458aff 0%, #50acf9 100%);
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.main_2_flex_right_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 780px;
  height: 780px;
}

.main_2_flex_right_title {
  width: 84px;
  height: 40px;
  font-family: SourceHanSansSC-Regular;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  line-height: 40px;
  margin-top: 30px;
}

.main_2_flex_left_1,
.main_2_flex_left_2,
.main_2_flex_left_3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_2_flex_left_1 {
  width: 368px;
  height: 87px;
  font-family: SourceHanSansSC-Regular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #5b687c;
}

.main_2_flex_left_2 {
  width: 368px;
  height: 182px;
  background-color: #ffffff;
  margin-top: 60px;
  cursor: pointer;
}

.main_2_flex_left_active {
  box-shadow: 0px 2px 9px 0px rgba(91, 110, 133, 0.31);
}

.main_2_flex_left_3 {
  width: 368px;
  height: 182px;
  margin-top: 60px;
}

.main_3 {
  width: 1300px;
  height: 998px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 30px;
}

.main_3_flex {
  width: 1300px;
  height: 568px;
  justify-content: flex-start;
}

.main_3_flex_right_title {
  width: 401px;
  height: 87px;
  font-family: SourceHanSansSC-Regular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #5b687c;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.main_3_flex_left {
  width: 740px;
  height: 480px;
  background-image: linear-gradient(134deg, #4e8cf6 0%, #50acf9 100%);
  margin-left: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 17px;
}

.main_3_flex_right {
  margin-left: 120px;
  position: relative;
}

.main_4 {
  background-size: 100% 100%;
  width: 100%;
  height: 650px;
  background-image: url("@/assets/images/main/main4_bg.png");
  background-position: center 100%;
  background-repeat: no-repeat;
}

.main_4_title {
  width: 288px;
  height: 70px;
  font-family: SourceHanSansSC-Bold;
  font-size: 48px;
  font-weight: 700;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin: 0 auto;
  padding-top: 84px;
}

.main_4_items {
  width: 100%;
  height: 203px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
}

.main_4_item {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 452px;
}

.main_4_item img {
  margin: 7px;
}

.main_4_item_text1 {
  font-family: SourceHanSansSC-Medium;
  font-size: 28px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: rgba(38, 42, 55, 1);
  margin-top: 10px;
  margin-bottom: 30px;
}

.main_4_item_text2 {
  width: 400px;
  height: 58px;
  font-family: SourceHanSansSC-Regular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: rgba(38, 42, 55, 1);
}

.go_to_call_we {
  width: 159px;
  height: 52px;
  background-color: #2072ec;
  border-radius: 4px;
  font-family: PingFangSC-Semibold;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #fff;
  line-height: 52px;
  text-align: center;
  margin: 60px auto;
  user-select: none;
  cursor: pointer;
}

.go_to_call_we a {
  color: #fff;
}

.main_5 {
  width: 100%;
  height: 840px;
  background-color: #f0f4fc;
  position: relative;
  z-index: 0;
}

.main_5_flex {
  width: 1300px;
  margin: 0 auto;
}

.main_5_title {
  color: #040404;
  margin: 0 auto;
  text-align: center;
}

.main_5_title2 {
  margin: 0 auto;
  text-align: center;
  width: 100%;
  padding-top: 10px;
}

.main_5_title3 {
  /* width: px; */
  font-family: SourceHanSansSC-Regular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #5b687c;
  margin: 0 auto;
  padding-top: 40px;
  text-align: center;
}

.main_5_title4 {
  font-family: SourceHanSansSC-Regular;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #5b687c;
  margin: 0 auto;
  text-align: center;
  padding-top: 30px;
}

.main_5_title3 span {
  color: #2072ec;
}

.main_5_6000_flex {
  margin-top: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.main_5_6000_center {
  width: 152px;
  height: 58px;
  font-family: Arial-BoldMT;
  font-size: 40px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #313131;
  text-align: center;
  margin: 0 20px;
  font-weight: 700;
}

.bg_6000 {
  height: 336px;
  text-align: center;
  font-size: 300px;
  color: #e7eefc;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 200px;
  z-index: -1;
  font-weight: 700;
  width: 100%;
  user-select: none;
}

.bg_6000 span {
  font-size: 100px;
}

.footer_beian {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: #7a7a7a;
  font-size: 12px;
}

.footer_beian a {
  color: #2072ec;
}

.main_new_year {
  position: fixed;
  left: 0;
  bottom: 0;
  background-image: url("@/assets/images/main/main_new_year.png");
  background-size: auto 100%;
  width: 100%;
  height: 120px;
  background-position: center 100%;
  background-repeat: no-repeat;
}

.main_new_year_exit {
  position: absolute;
  right: 5px;
  bottom: 65px;
  width: 50px;
  height: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main_new_year_box {
  position: relative;
  width: 100%;
  height: 120px;
  cursor: pointer;
}

.main_header_title,
.go_to_login,
.go_to_reg,
.header_imgUrl_title1,
.header_imgUrl_title2,
.header_imgUrl_title3,
.header_imgUrl_title_open_dwg,
.shili_item_name,
.main_2_title1,
.main_2_title2,
.main_2_flex_left_1,
.main_2_flex_right_title,
.main_2_title1 span,
.main_3_flex_right_title,
.main_4_title,
.main_4_item_text1,
.main_4_item_text2,
.main_5_title3,
.main_5_title4 {
  font-family: "SourceHanSansSC-Bold", "PingFang SC";
}

@media (min-width: 1024px) {
  body {
    font-size: 18px;
  }

  // .shili_item {
  //   width: 134px;
  //   height: 112px;
  // }

  // .shili_item img {
  //   width: 122px;
  //   height: 68px;
  // }

  .shili_item_name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
  }
}

// /*>=1024的设备*/

@media (min-width: 1100px) {
  body {
    font-size: 20px;
  }

  // .shili_item {
  //   width: 154px;
  //   height: 144px;
  // }

  // .shili_item img {
  //   width: 154px;
  //   height: 108px;
  // }

  .shili_item_name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
  }
}

/*>=1100的设备*/
@media (min-width: 1280px) {
  body {
    font-size: 22px;
  }

  // .shili_item {
  //   width: 154px;
  //   height: 144px;
  // }

  // .shili_item img {
  //   width: 154px;
  //   height: 108px;
  // }

  .shili_item_name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
  }
}

/*>=1280的设备*/

@media (min-width: 1366px) {
  body {
    font-size: 24px;
  }

  .shili_item {
    width: 164px;
    height: 154px;
  }

  .shili_item img {
    width: 164px;
    height: 110px;
  }

  .shili_item_name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
  }
}

@media (min-width: 1440px) {
  body {
    font-size: 25px !important;
  }

  .shili_item {
    width: 174px;
    height: 154px;
  }

  .shili_item img {
    width: 172px;
    height: 108px;
  }

  .shili_item_name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
  }
}

@media (min-width: 1680px) {
  body {
    font-size: 28px;
  }

  .shili_item {
    width: 204px;
    height: 195px;
  }

  .shili_flex {
    padding: 0;
  }

  .shili_item img {
    width: 202px;
    height: 138px;
  }

  .shili_item_name {
    padding-top: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 18px;
  }
}

@media (min-width: 1920px) {
  body {
    font-size: 33px;
  }

  .shili_flex {
    padding: 0;
  }

  .shili_item {
    width: 224px;
    height: 215px;
  }

  .shili_item img {
    width: 222px;
    height: 158px;
  }

  .shili_item_name {
    padding-top: 6px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 20px;
  }
}

#show_logo_shoucang {
  position: absolute;
  left: 12.5%;
  top: 0;
  display: none;
  width: 400px;
  height: 100px;
}

.show_logo_shoucang_postion {
  position: relative;
}

.show_logo_shoucang_postion img {
  position: absolute;
}

.show_logo_shoucang_postion div {
  position: absolute;
  top: 67px;
  left: 0;
  font-size: 14px;
  text-align: center;
  width: 330px;
  opacity: 1;
}

.main_header_bg_flex a {
  color: #8a4023;
}

#go_to_cloud {
  background-color: #3568c0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.go_to_cloud_bgimg {
  width: 60px;
  height: 60px;
  background-image: url("@/assets/images/main/go_dwg.png");
  background-repeat: no-repeat;
  background-size: 100%;
}

.go_to_cloud_text {
  font-family: "SourceHanSansSC-Bold", "PingFang SC";
  font-size: 16px;
  font-weight: 700;
  font-stretch: normal;
  line-height: 28px;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 24px;
}

#maskLayer {
  background-color: #000 !important;
  opacity: 0.5 !important;
}

.main_header_line {
  width: 1px;
  height: 25px;
  background-color: #fff;
  margin: 0 30px;
}

.syy_sdk {
  margin-right: 45px;
  height: 24px;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 16px;
  font-family: SourceHanSansSC-Medium;
  text-align: center;
  white-space: nowrap;
  line-height: 24px;
  cursor: pointer;
  position: relative;
}

.syy_sdk:hover {
  opacity: 0.8;
}

.syy_sdk a {
  color: #fff;
}

.go_to_login:hover,
.go_to_reg:hover {
  opacity: 0.8;
}

.syy_sdk::after {
  content: "私有云SDK";
  font-size: 12px;
  color: #fff;
  position: absolute;
  right: -62px;
  top: -15px;
  width: 80px;
  height: 20px;
  line-height: 20px;
  background: #ec6860;
  border-radius: 8px 8px 8px 0px;
}

.header_down_item {
  height: 40px;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
  padding: 0 20px;
}

.header_down_item:hover {
  background-color: #f2f5fa;
}

.header_down_item span {
  padding-left: 5px;
}

.app_sao_code_box {
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: 1px solid #d3d3d3;
  box-shadow: 0 2px 12px 0 rgb(56 56 56 / 20%);
  top: 0;
  left: -197px;
}

.app_sao_code {
  margin: 30px;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.app_sao_code img {
  width: 135px;
  height: 135px;
  margin-left: unset;
}

.app_sao_code_text {
  margin-top: 15px;
  font-size: 16px;
  color: #000;
}

#feedback_show:hover {
  opacity: 0.8;
}

.cando_consult_box {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;
}

.cando_consult_box .cando_consult {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 31px 32px 60px 29px;
  border: 1px solid #cccccc;
  border-radius: 20px;
  background: #fbfbfb;
}

.cando_consult_box .cando_consult .title {
  margin-bottom: 33px;
  font-size: 24px;
}

.cando_consult_box .cando_consult .close {
  position: absolute;
  top: 35px;
  right: 19px;
  transform: translateY(-50%);
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.cando_consult_box .cando_consult .close .close_left,
.cando_consult_box .cando_consult .close .close_right {
  position: absolute;
  top: 50%;
  width: 18px;
  height: 1px;
  background-color: #333;
  transform: rotate(45deg);
}

.cando_consult_box .cando_consult .close .close_right {
  transform: rotate(-45deg);
}

.cando_consult_box .cando_consult .consult_members {
  font-weight: bold;
  color: #000000;
}

.cando_consult_box .cando_consult .consult_members li {
  margin-bottom: 28px;
}

.cando_consult_box .cando_consult .consult_members li:last-of-type {
  margin-bottom: 0;
}

.cando_consult_box .cando_consult .consult_members li .name {
  display: flex;
  align-items: center;
  font-size: 19px;
}

.cando_consult_box .cando_consult .consult_members li .name .line {
  width: 7px;
  height: 18px;
  margin-top: 2px;
  margin-right: 6px;
  background: #007cea;
}

.cando_consult_box .cando_consult .consult_members li .telephone,
.cando_consult_box .cando_consult .consult_members li .mailbox {
  margin-top: 14px;
  font-size: 16px;
}

.cando_consult_box .cando_consult .consult_members li .mailbox {
  margin-top: 11px;
}

.xieyibox {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 99;

  .box {
    display: flex;
    width: 350px;
    height: 149px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #cccccc;
    border-radius: 4px;
    background: #fbfbfb;

    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      transform: translateY(-50%);
      width: 18px;
      height: 18px;
      cursor: pointer;
      .close_left,
      .close_right {
        position: absolute;
        top: 50%;
        width: 18px;
        height: 1px;
        background-color: #333;
        transform: rotate(45deg);
      }
      .close_right {
        transform: rotate(-45deg);
      }
    }

    .checked {
      margin-top: 10px;
      display: flex;
      align-items: center;
      gap: 6px;
      cursor: pointer;
      .left {
        width: 16px;
        height: 16px;
        img {
          width: 16px;
          height: 16px;
        }
      }
      .left_active {
        border-radius: 2px;
        background: linear-gradient(135deg, #00c3ee 0%, #0377ff 100%);
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 10px;
          height: 10px;
        }
      }
      .right {
        user-select: none;
        color: #000;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-decoration-line: underline;
      }
    }

    .upBtn {
      display: flex;
      width: 190px;
      height: 26px;
      padding: 5px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      color: #fff;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      cursor: not-allowed;
      border-radius: 4px;
      background: #dbdada;
      margin-top: 16px;
    }

    .upBtn_yes {
      border-radius: 4px;
      background: #4383f0;
      cursor: pointer;
    }
  }
}

.xieyiTextBox {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  .box {
    display: flex;
    width: 650px;
    height: 438px;
    padding: 15px 30px 30px 30px;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    flex-shrink: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: 1px solid #cccccc;
    border-radius: 4px;
    background: #fbfbfb;

    .head {
      position: relative;
      display: flex;
      width: 100%;
      height: 50px;
      justify-content: space-between;
      align-items: center;
      .left {
        display: flex;
        align-items: center;
        gap: 8px;
        width: 69px;
      }
      .title {
        color: rgba(33, 32, 32, 0.85);
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 16px; /* 100% */
      }
      .close {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
    }

    .linea {
      width: 100%;
      height: 2px;
      background: #e8e8e8;
    }

    .time {
      color: #3b3b3f;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px; /* 133.333% */
      width: 100%;
      text-align: right;
    }

    .texts {
      color: #3b3b3f;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 25px;
      height: 100%;
      overflow-y: auto;
      .docs {
        height: 100%;
      }
    }
  }
}
</style>
