<template>
  <div>
    <div class="mask"></div>
    <!-- 登录 -->
    <div class="login-box" v-if="cur_show_box1 == 'login'">
      <div class="login_box_flex">
        <div class="login_left_bg">
          <div class="login_logo_box">
            <img src="@/assets/images/common/login_logo.png" alt="" />
            <span>与迷你CAD账号互通</span>
          </div>
          <div class="login_left_flex">
            <div class="login_left_title_a">{{ headerTitle1 }}</div>
            <div class="login_left_title_b">2D/3D都能用</div>

            <div class="login_left_title2_flex">
              <div class="login_left_title2_item">
                <img src="@/assets/images/main/login_left_img.png" alt="" />
                <div>无需安装，在线使用</div>
              </div>
              <div class="login_left_title2_item">
                <img src="@/assets/images/main/login_left_img.png" alt="" />
                <div>自由查看，轻松编辑</div>
              </div>
              <div class="login_left_title2_item">
                <img src="@/assets/images/main/login_left_img.png" alt="" />
                <div>快速分享，在线只读</div>
              </div>
              <div class="login_left_title2_item">
                <img src="@/assets/images/main/login_left_img.png" alt="" />
                <div>轻松建群，协作办公</div>
              </div>
            </div>
          </div>
        </div>

        <div class="login_right">
          <div
            class="show_phone_login_login_box"
            v-if="cur_login_type != 'wx_login' && cur_login_type != 'wx_bind_account'"
          >
            <div
              class="up_time_login_from up_time_login_from_phone"
              v-if="LOGIN_FORM == 'phone'"
            ></div>
            <div
              class="up_time_login_from up_time_login_from_account"
              v-if="LOGIN_FORM == 'account'"
            ></div>
            <div class="up_time_login_from up_time_login_from_wx" v-if="LOGIN_FORM == 'wx'"></div>

            <div class="title">
              <div class="titile_center">
                <div
                  class="word show_id_login"
                  :class="cur_login_type == 'account' ? 'word_active' : ''"
                  @click="cur_login_type = 'account'"
                >
                  账号登录
                </div>
                <div
                  class="word show_phone_login"
                  :class="cur_login_type == 'phone' ? 'word_active' : ''"
                  @click="(cur_login_type = 'phone'), (show_id_login_err_code = 0)"
                >
                  短信验证登录
                </div>
              </div>
            </div>

            <div class="show_id_login_box" v-if="cur_login_type == 'account'">
              <div class="login_box_err_text show_id_login_err" v-if="show_id_login_err_code == 2">
                账号错误
              </div>
              <div class="login_box_err_text show_id_login_err" v-if="show_id_login_err_code == 3">
                密码错误
              </div>
              <div class="login_box_err_text show_id_login_err" v-if="show_id_login_err_code == 7">
                该账号暂无密码，<span @click="cur_show_box1 = 'resetpwd'">点击设置</span>
              </div>

              <form>
                <div class="account">
                  <div class="account_left_img"></div>
                  <input
                    id="account"
                    type="text"
                    name="username"
                    placeholder="输入手机号或者邮箱"
                    :value="login_account"
                    @input="onChange_login_account"
                    @keydown.enter="login_btn"
                  />
                </div>
                <div class="password">
                  <div class="account_left_img password_left_img"></div>
                  <input
                    id="pwd"
                    type="password"
                    name="password"
                    placeholder="密码"
                    :value="login_pwd"
                    @input="onChange_login_pwd"
                    @keydown.enter="login_btn"
                  />
                  <div
                    class="password_show hide_show_password_btn"
                    id="login_password_show"
                    title="显示密码"
                  >
                    <div class="password_show_img"></div>
                  </div>
                </div>
              </form>
              <div class="toreg">
                <div class="right">
                  <div class="toreg_right_flex" id="toreg_right_jzw_btn" data-active="0">
                    <!-- <div class="toreg_right_jzw"></div>
                    <span>记住我</span> -->
                    <!-- <el-checkbox v-model="IS_JIZHUWO" class="jizhuwo">记住我</el-checkbox> -->
                  </div>
                  <span
                    id="to_reset"
                    @click="(cur_show_box1 = 'resetpwd'), (show_id_login_err_code = 0)"
                    >忘记密码</span
                  >
                </div>
              </div>
              <div class="login_btn_box">
                <div id="login_btn" @click="login_btn">登录</div>
              </div>
            </div>

            <div class="show_phone_login_box" v-if="cur_login_type == 'phone'">
              <div class="login_box_err_text show_phone_login_err"></div>
              <div class="account">
                <div class="account_left_img"></div>
                <input
                  id="phone_login_account"
                  type="text"
                  name="phone_login_account"
                  placeholder="输入手机号"
                  :value="phone_account"
                  @input="onChange_phone_account"
                />
              </div>
              <div class="login_code">
                <div class="account_left_img code_left_img"></div>
                <input
                  class="text_input"
                  style="margin-top: 16px"
                  id="login_phone_code"
                  type="text"
                  name="login_phone_code"
                  placeholder="请输入验证码"
                  autocomplete="off"
                  :value="phone_code"
                  @input="onChange_phone_code"
                  @keydown.enter="login_btn_phone"
                />
                <div
                  class="get_phone_login_code"
                  style="margin-top: 16px; right: 0"
                  data-can-click="yes"
                  v-show="phone_code_get_btn_show"
                  @click="phone_login_getCode"
                >
                  获取验证码
                </div>
                <div
                  class="get_phone_login_code count"
                  style="margin-top: 16px; right: 0"
                  data-can-click="yes"
                  v-show="!phone_code_get_btn_show"
                >
                  {{ phone_login_code_count }}s后重新获取
                </div>
                <div class="err_msg code_err_msg"></div>
              </div>
              <div class="toreg">
                <div class="right">
                  <div class="toreg_right_flex"></div>

                  <div>
                    收不到验证码？<a
                      class="go_to_kefu"
                      href="https://www.52cad.com/cloud/api/jump_qq"
                      >联系客服</a
                    >
                  </div>
                </div>
              </div>
              <div class="login_btn_box">
                <div id="login_btn_phone" @click="login_btn_phone">登录</div>
              </div>
            </div>

            <div class="show_wx_login_box" @click="is_show_wx_login">
              <div class="show_wx_login_img_box">
                <div class="show_wx_login_img"></div>
              </div>
              <div class="show_wx_login_text">微信登录</div>
            </div>

            <div class="toreg toreg_bottom">
              <div class="right">
                <div style="text-align: center; width: 100%">
                  没有账号？<span
                    id="reg_to_login"
                    @click="(cur_show_box1 = 'reg'), (show_id_login_err_code = 0)"
                    >免费注册</span
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="show_wx_login_login_box" v-if="cur_login_type == 'wx_login'">
            <div class="show_wx_login_login_box_flex">
              <div class="show_wx_login_login_top">
                <span @click="return_account_login_box"> ＜ 返回 </span>
              </div>
              <div class="show_wx_login_login_center">
                <div class="show_wx_login_login_center_title">微信登录</div>
                <div class="show_wx_login_login_center_qcode_border">
                  <img
                    class="show_wx_login_login_center_qcode wx_login_qcode"
                    id="wx_login_qcode"
                    ref="loginqrcode"
                    :src="wx_qcode"
                    v-if="wx_login_qocde == '408' || wx_login_qocde == '4088'"
                  />
                  <div class="wx_login_loading_box" v-if="wx_login_qocde == '0'">
                    <div class="wx_login_loading_text1">二维码获取中</div>
                    <div class="wx_login_loading_text2">请稍后...</div>
                  </div>
                  <div class="wx_login_loading_box" v-if="wx_login_qocde == '404'">
                    <div class="wx_login_loading_text1" style="color: #3975c6">扫码成功</div>
                    <div class="wx_login_loading_text2" style="color: #3975c6">等待用户授权</div>
                  </div>
                  <div class="wx_login_loading_box" v-if="wx_login_qocde == '405'">
                    <div class="wx_login_loading_text1" style="color: #3975c6">扫码成功</div>
                    <div class="wx_login_loading_text2" style="color: #3975c6">正在登录</div>
                  </div>
                  <div class="wx_login_loading_box" v-if="wx_login_qocde == '403'">
                    <div class="wx_login_loading_text1" style="color: #999">用户拒绝授权</div>
                    <div class="wx_login_loading_text2" style="color: #3975c6; cursor: pointer">
                      点击刷新
                    </div>
                  </div>
                  <div class="wx_login_loading_box" v-if="wx_login_qocde == '402'">
                    <div class="wx_login_loading_text1" style="color: #999">二维码已过期</div>
                    <div class="wx_login_loading_text2" style="color: #3975c6; cursor: pointer">
                      点击刷新
                    </div>
                  </div>
                  <div class="wx_login_loading_box" v-if="wx_login_qocde == '500'">
                    <div class="wx_login_loading_text1" style="color: #999">未知错误</div>
                    <div class="wx_login_loading_text2" style="color: #3975c6; cursor: pointer">
                      点击刷新
                    </div>
                  </div>
                  <div class="wx_login_loading_box" v-if="wx_login_qocde == '666'">
                    <div class="wx_login_loading_text1" style="color: #999">二维码已失效</div>
                    <div class="wx_login_loading_text2" style="color: #3975c6; cursor: pointer">
                      点击刷新
                    </div>
                  </div>
                  <div class="wx_login_loading_box" v-if="wx_login_qocde == '10086'">
                    <div class="wx_login_loading_text1" style="color: #999">服务器繁忙</div>
                    <div class="wx_login_loading_text2" style="color: #999">请稍后再试</div>
                  </div>
                </div>
                <div class="show_wx_login_login_center_title1">二维码加载失败？</div>
                <div class="show_wx_login_login_center_title2">
                  请使用微信扫描二维码登录“{{ headerTitle1 }}”
                </div>
              </div>
              <div class="toreg toreg_bottom" style="margin-top: 25px">
                <div class="right">
                  <div style="text-align: center; width: 100%">
                    没有账号？<span id="wx_reg_to_login" @click="wx_go_reg_box">免费注册</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="show_wx_login_login_box_bind" v-if="cur_login_type == 'wx_bind_account'">
            <div class="show_wx_login_login_box_flex">
              <div class="show_wx_login_login_top" style="margin-left: -10px">
                <span @click="return_account_login_box"> ＜ 返回</span>
              </div>
              <div class="login_box_flex">
                <div class="login_right" style="margin-left: -10px">
                  <div class="title" style="margin-top: 13px">
                    <div class="titile_center">
                      <div class="word" style="color: rgba(57, 117, 198, 1)">绑定账号</div>
                    </div>
                  </div>
                  <div class="account">
                    <div class="login_box_err_text login_bind_phone_err">
                      {{ login_bind_phone_err }}
                    </div>
                    <div class="account_left_img"></div>
                    <input
                      id="login_bind_account"
                      type="text"
                      name="login_bind_account"
                      placeholder="请输入手机号/邮箱"
                      :value="wx_bind_account"
                      @input="onChange_wx_bind_account"
                    />
                  </div>

                  <div class="login_code">
                    <div class="account_left_img code_left_img" style="top: 32px"></div>
                    <input
                      class="text_input"
                      style="margin-top: 20px"
                      id="login_bind_code"
                      type="text"
                      name="login_bind_code"
                      placeholder="请输入验证码/邮箱验证码"
                      autocomplete="off"
                      :value="wx_bind_code"
                      @input="onChange_wx_bind_code"
                    />

                    <div
                      id="get_login_bind_code"
                      style="margin-top: 20px; right: 0"
                      data-can-click="yes"
                      v-show="wx_bind_code_get_btn_show"
                      @click="wx_bind_login_getCode"
                    >
                      获取验证码
                    </div>
                    <div
                      class="get_phone_login_code count"
                      style="margin-top: 16px; right: 0"
                      data-can-click="yes"
                      v-show="!wx_bind_code_get_btn_show"
                    >
                      {{ wx_bind_code_count }}s后重新获取
                    </div>
                  </div>

                  <div class="no_shou_code" style="padding-bottom: 16px">
                    收不到验证码？<a
                      class="go_to_kefu"
                      href="https://www.52cad.com/cloud/api/jump_qq"
                      >联系客服</a
                    >
                  </div>

                  <div class="login_btn_box">
                    <div id="login_bind_btn" @click="go_to_wx_bind_btn(0)">去绑定</div>
                  </div>
                  <div class="show_wx_login_login_center_title2" style="color: #999">
                    未注册的账号，绑定手机号后自动创建账号
                  </div>
                </div>
              </div>

              <div class="toreg toreg_bottom" style="margin-top: 25px">
                <div class="right">
                  <div style="text-align: center; width: 100%">
                    没有账号？<span id="wx_bind_to_reg">免费注册</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div id="login_close" class="close close_login_box" @click="close_login_box">
            <img src="@/assets/images/main/login_x.png" alt="" />
          </div>

          <div class="login_bottom_text">
            <span>注册/登录即视为同意《</span>
            <a href="http://www.aec188.com/agreement.php" target="_blank" style="font-size: 14px"
              >服务协议</a
            >》和《
            <a href="https://www.aec188.com/privacy.php" target="_blank" style="font-size: 14px">
              隐私协议</a
            >》
          </div>
        </div>
      </div>
    </div>

    <!-- 注册 -->
    <div class="login-reg-box" v-if="cur_show_box1 == 'reg'">
      <div class="login_box_flex">
        <div class="login_left_bg">
          <div class="login_logo_box">
            <img src="@/assets/images/common/login_logo.png" alt="" />
            <span>与迷你CAD账号互通</span>
          </div>
          <div class="login_left_flex">
            <div class="login_left_title_a">{{ headerTitle1 }}</div>
            <div class="login_left_title_b">2D/3D都能用</div>

            <div class="login_left_title2_flex">
              <div class="login_left_title2_item">
                <img src="@/assets/images/main/login_left_img.png" alt="" />
                <div>无需安装，在线使用</div>
              </div>
              <div class="login_left_title2_item">
                <img src="@/assets/images/main/login_left_img.png" alt="" />
                <div>自由查看，轻松编辑</div>
              </div>
              <div class="login_left_title2_item">
                <img src="@/assets/images/main/login_left_img.png" alt="" />
                <div>快速分享，在线只读</div>
              </div>
              <div class="login_left_title2_item">
                <img src="@/assets/images/main/login_left_img.png" alt="" />
                <div>轻松建群，协作办公</div>
              </div>
            </div>
          </div>
        </div>

        <div class="login_right">
          <div class="title">
            <div class="titile_center">
              <div class="word" style="color: rgba(57, 117, 198, 1); font-weight: 600">
                注册账户
              </div>
            </div>
          </div>
          <div class="login_box_err_text show_phone_login_err">{{ show_reg_err }}</div>
          <div class="account">
            <div class="account_left_img"></div>
            <input
              id="login_reg_account"
              type="text"
              name="login_reg_account"
              placeholder="请输入手机号"
              v-model="reg_account"
            />
          </div>

          <div class="login_code">
            <div class="account_left_img code_left_img"></div>
            <input
              class="text_input"
              style="margin-top: 20px"
              id="login_reg_code"
              type="text"
              name="login_reg_code"
              placeholder="请输入验证码"
              v-model="reg_code"
            />
            <div
              id="get_login_reg_code"
              style="margin-top: 20px"
              data-can-click="yes"
              v-show="reg_code_get_btn_show"
              @click="reg_login_getCode"
            >
              获取验证码
            </div>
            <div
              class="get_phone_login_code count"
              style="margin-top: 16px"
              data-can-click="yes"
              v-show="!reg_code_get_btn_show"
            >
              {{ reg_code_count }}s后重新获取
            </div>
            <div class="err_msg code_err_msg"></div>
          </div>

          <div class="password" style="postion: relative">
            <div class="account_left_img password_left_img"></div>
            <input
              style="margin-top: 20px"
              type="text"
              autocomplete="new-password"
              placeholder="请输入6-16位密码"
              v-model="reg_pwd1"
              @input="changeRegPwdInput"
            />

            <div
              class="password_show hide_show_password_btn"
              id="reg_password_show"
              style="top: 20px"
              title="显示密码"
            >
              <img src="@/assets/images/main/login_hidepassword.png" alt="" />
            </div>
          </div>
          <div class="login_btn_box" style="margin-top: 30px">
            <div id="login_reg_btn" @click="reg_btn">注册</div>
          </div>
          <div class="toreg toreg_bottom">
            <div class="right">
              <div style="text-align: center; width: 100%">
                已有账号？<span
                  id="to_login"
                  @click="(cur_show_box1 = 'login'), (cur_login_type = 'account')"
                  >立即登录</span
                >
              </div>
            </div>
          </div>
          <div id="login-reg-close" class="close close_login_box" @click="close_login_box">
            <img src="@/assets/images/main/login_x.png" alt="" />
          </div>

          <div class="login_bottom_text">
            <span>注册/登录即视为同意《</span>
            <a href="http://www.aec188.com/agreement.php" target="_blank" style="font-size: 14px"
              >服务协议</a
            >》和《
            <a href="https://www.aec188.com/privacy.php" target="_blank" style="font-size: 14px">
              隐私协议</a
            >》
          </div>
        </div>
      </div>
    </div>

    <!-- 重置密码 -->
    <div class="login-reset-pwd-box" v-if="cur_show_box1 == 'resetpwd'">
      <div class="login_box_flex">
        <div class="login_left_bg">
          <div class="login_logo_box">
            <img src="@/assets/images/common/login_logo.png" alt="" />
            <span>与迷你CAD账号互通</span>
          </div>
          <div class="login_left_flex">
            <div class="login_left_title_a">{{ headerTitle1 }}</div>
            <div class="login_left_title_b">2D/3D都能用</div>

            <div class="login_left_title2_flex">
              <div class="login_left_title2_item">
                <img src="@/assets/images/main/login_left_img.png" alt="" />
                <div>无需安装，在线使用</div>
              </div>
              <div class="login_left_title2_item">
                <img src="@/assets/images/main/login_left_img.png" alt="" />
                <div>自由查看，轻松编辑</div>
              </div>
              <div class="login_left_title2_item">
                <img src="@/assets/images/main/login_left_img.png" alt="" />
                <div>快速分享，在线只读</div>
              </div>
              <div class="login_left_title2_item">
                <img src="@/assets/images/main/login_left_img.png" alt="" />
                <div>轻松建群，协作办公</div>
              </div>
            </div>
          </div>
        </div>

        <div class="login_right">
          <div class="title">
            <div class="titile_center">
              <div class="word" style="color: rgba(57, 117, 198, 1); font-weight: 600">
                重置密码
              </div>
            </div>
          </div>
          <div class="login_box_err_text show_phone_login_err">{{ show_reset_pwd_err }}</div>
          <div class="account">
            <div class="account_left_img"></div>
            <input
              id="login_reset_pwd_account"
              type="text"
              name="login_reset_pwd_account"
              placeholder="请输入手机号"
              v-model="reset_pwd_account"
            />
          </div>
          <div class="login_code">
            <div class="account_left_img code_left_img"></div>
            <input
              class="text_input"
              style="margin-top: 20px"
              id="login_reset_pwd_code"
              type="text"
              name="login_reset_pwd_code"
              placeholder="请输入验证码"
              v-model="reset_pwd_code"
            />
            <div
              id="get_login_bind_code"
              style="margin-top: 20px"
              data-can-click="yes"
              v-show="reset_pwd_code_get_btn_show"
              @click="reset_pwd_login_getCode"
            >
              获取验证码
            </div>
            <div
              class="get_phone_login_code count"
              style="margin-top: 16px"
              data-can-click="yes"
              v-show="!reset_pwd_code_get_btn_show"
            >
              {{ reset_pwd_code_count }}s后重新获取
            </div>
            <div class="err_msg code_err_msg"></div>
          </div>

          <div class="no_shou_code">
            收不到验证码？<a class="go_to_kefu" href="https://www.52cad.com/cloud/api/jump_qq"
              >联系客服</a
            >
          </div>

          <div class="password">
            <div class="account_left_img password_left_img"></div>
            <input
              style="margin-top: 20px"
              id="login_reset_pwd"
              type="text"
              name="login_reg_pwd"
              autocomplete="new-password"
              placeholder="请输入6-16位新密码"
              v-model="reset_pwd1"
              @input="changeResetPwdInput"
            />
            <div
              class="password_show hide_show_password_btn"
              id="reset_password_show"
              style="top: 20px"
              title="显示密码"
            >
              <img src="@/assets/images/main/login_hidepassword.png" alt="" />
            </div>
          </div>
          <div class="login_btn_box">
            <div id="login_reset_pwd_btn" @click="reset_pwd_btn">重置密码</div>
          </div>
          <div class="toreg toreg_bottom">
            <div class="right">
              <div style="text-align: center; width: 100%">
                想起密码？<span id="reset_pwd_to_login" @click="cur_show_box1 = 'login'"
                  >去登录</span
                >
              </div>
            </div>
          </div>
          <div id="login-reset-pwd-close" class="close close_login_box" @click="close_login_box">
            <img src="@/assets/images/main/login_x.png" alt="" />
          </div>

          <div class="login_bottom_text">
            <span>注册/登录即视为同意《</span>
            <a href="http://www.aec188.com/agreement.php" target="_blank" style="font-size: 14px"
              >服务协议</a
            >》和《
            <a href="https://www.aec188.com/privacy.php" target="_blank" style="font-size: 14px">
              隐私协议</a
            >》
          </div>
        </div>
      </div>
    </div>

    <!-- 账号已绑定微信，是否强制绑定或更换账号？ -->
    <div
      class="tanchuang-box is_bind_update_phone_box"
      style="display: block"
      v-if="is_bindwx == 'y'"
    >
      <div class="ctb-top">
        <div class="tan-ctb-title">提示</div>
        <div class="tanchuang_exit" @click="is_bindwx = 'no'">
          <img src="@/assets/images/main/login_x.png" alt="" />
        </div>
      </div>
      <div class="ctb-line"></div>
      <div class="tan-ctb-title1">当前账号已被绑定，是否强制绑定？</div>

      <div class="ctb-btns" style="margin-top: 0">
        <div class="tanchu_btn_qx" @click="wx_bind_phone_btn_yes">强制绑定</div>
        <div
          class="tanchu_btn_sumbit"
          @click="(is_bindwx = 'no'), (wx_bind_account = ''), (wx_bind_code = '')"
        >
          更换账号
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  login,
  login_phone,
  login_phone_code,
  login_wx,
  login_wx_status,
  login_wx_return,
  wxbind_phone,
  reset_password_getcode,
  reset_user_password,
  user_reg_getcode,
  reg_api,
} from "@/api/user";

import { statistic } from "@/api/dwg";
var MD5 = require("md5.js");
import utils from "@/utils/utils";
import Common from "@/utils/common";

export default {
  name: "Login_page",
  props: ["cur_show_box"],
  data() {
    return {
      headerTitle1: Common.headerTitle1,
      cur_show_box1: "login", // 显示登录 修改密码 微信登陆
      cur_login_type: "account", //账号登录手机登录 显示
      IS_JIZHUWO: false, //是否记住账号密码
      LOGIN_FORM: "account", //上次登录来自
      login_account: "", //账号登录
      login_pwd: "", //账号登录密码
      show_id_login_err_code: 1, //账号密码错误提示code
      phone_account: "", //手机登录账号
      phone_code: "", //手机登录验证码
      phone_code_get_btn_show: true, //手机登录获取验证码按钮
      phone_login_code_count: 0, //手机登录获取验证码后计时
      phone_login_code_timer: null, //手机登录获取验证码定时器
      wx_login_status_timer: null, //微信登录获取status定时器
      wx_login_qocde: "0", //微信登陆二维码中的文字控制
      uuid: "",
      wx_login_status_first_404: false,
      wx_qcode: "",
      is_bindwx: "n", //wx登录回调是否有绑定，
      wx_bind_account: "", //wx绑定账号
      wx_bind_code: "", //wx绑定密码
      login_bind_phone_err: "", //wx绑定err
      wx_bind_status_timer: null, //微信绑定获取status定时器
      wx_bind_code_get_btn_show: true, //微信绑定账号获取验证码定时器
      wx_bind_code_count: 0,

      reset_pwd_code: "",
      reset_pwd: "",
      reset_pwd1: "",
      reset_pwd_account: "",
      show_reset_pwd_err: "",
      reset_pwd_code_get_btn_show: true,
      reset_pwd_code_status_timer: null,
      reset_pwd_code_count: 0,

      reg_code: "",
      reg_pwd: "", //真实密码
      reg_pwd1: "", //替换密码
      reg_account: "",
      show_reg_err: "",
      reg_code_get_btn_show: true,
      reg_code_status_timer: null,
      reg_code_count: 0,
    };
  },
  created() {
    this.cur_show_box1 = this.$props.cur_show_box;
  },

  mounted() {
    // this.is_jizhuwo()
    this.is_last_login();
  },
  methods: {
    changeRegPwdInput(e) {
      var passwordValue = this.reg_pwd; //真实密码
      var value = e.target.value;
      if (value.length > passwordValue.length) {
        passwordValue += value.substr(passwordValue.length); //截取最后输入的字符串拼接到密码后面
      } else if (value.length < passwordValue.length) {
        passwordValue = passwordValue.substr(0, value.length); //截取密码与value保持相同的长度
      }
      this.reg_pwd1 = value.replace(/[^*]/g, "*"); //替换为*号,隐藏密码
      this.reg_pwd = passwordValue;
    },

    changeResetPwdInput(e) {
      var passwordValue = this.reset_pwd; //真实密码
      var value = e.target.value;
      if (value.length > passwordValue.length) {
        passwordValue += value.substr(passwordValue.length); //截取最后输入的字符串拼接到密码后面
      } else if (value.length < passwordValue.length) {
        passwordValue = passwordValue.substr(0, value.length); //截取密码与value保持相同的长度
      }
      this.reset_pwd1 = value.replace(/[^*]/g, "*"); //替换为*号,隐藏密码
      this.reset_pwd = passwordValue;
    },

    //检测记住我
    is_jizhuwo() {
      var IS_JIZHUWO = localStorage.getItem("IS_JIZHUWO");
      console.log(IS_JIZHUWO);
      if (IS_JIZHUWO == null) {
        this.IS_JIZHUWO = false;
        localStorage.setItem("IS_JIZHUWO", false);
      }
      if (IS_JIZHUWO == "true") {
        this.IS_JIZHUWO = true;
        var USER_INFO_AUTO = JSON.parse(localStorage.getItem("USER_INFO_AUTO"));
        this.login_account = USER_INFO_AUTO != null ? USER_INFO_AUTO.account : "";
        this.login_pwd = USER_INFO_AUTO != null ? USER_INFO_AUTO.user_pwd1 : "";
      } else {
        this.login_account = "";
        this.login_pwd = "";
        this.IS_JIZHUWO = false;
      }
    },

    //检测上次登录 并显示
    is_last_login() {
      var LOGIN_FORM = localStorage.getItem("LOGIN_FORM");
      if (LOGIN_FORM != null) {
        this.LOGIN_FORM = LOGIN_FORM;
      }
    },

    close_login_box() {
      clearInterval(this.wx_login_status_timer);
      if (
        this.$route.path == "/dwgsee" ||
        this.$route.path == "/dwg" ||
        this.$route.path == "/dwg3" ||
        (this.$route.path == "/cdf" && this.$parent.is_no_need_login == true)
      ) {
        if (
          utils.getQueryVariable("share_id") == "" ||
          this.$route.path == "/cdf" ||
          utils.getQueryVariable("link_id") != ""
        ) {
          this.$message.error("必须登录才可查看图纸哦~");
          this.$emit("change_child_value", true);
          return;
        }
      }
      if (this.$route.path == "/pdf") {
        if (utils.getQueryVariable("share_id") == "") {
          this.$message.error("必须登录才可查看图纸哦~");
          this.$emit("change_child_value", true);
          return;
        }
      }
      this.$emit("change_child_value", false);
    },

    onChange_login_account(event) {
      this.login_account = event.target.value;
    },

    onChange_login_pwd(event) {
      this.login_pwd = event.target.value;
    },

    onChange_phone_account(event) {
      this.phone_account = event.target.value;
    },

    onChange_phone_code(event) {
      this.phone_code = event.target.value;
    },

    onChange_wx_bind_account(event) {
      this.wx_bind_account = event.target.value;
    },

    onChange_wx_bind_code(event) {
      this.wx_bind_code = event.target.value;
    },

    //账号密码登录
    login_btn() {
      this.show_id_login_err_code = 0;
      if (this.login_account == "") {
        this.$message.error("账号不能为空");
        return;
      }
      if (this.login_pwd == "") {
        this.$message.error("密码不能为空");
        return;
      }
      if (this.login_account.indexOf("@") < 0) {
        if (!this.isTelCode(this.login_account)) {
          this.$message.error("请输入正确的手机号码");
          return;
        }
      }

      this.login_account_fun();
    },
    login_account_fun() {
      var user = {
        account: this.login_account,
        pwd: new MD5().update(this.login_pwd).digest("hex"),
      };
      login(user)
        .then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 1) {
            // 登录成功
            this.$message.success("登录成功");
            var __userinfor = data.data;
            localStorage.setItem("LOGIN_FORM", "account");
            localStorage.setItem("USER_INFO_TIME", new Date().getTime());
            localStorage.setItem("USER_INFO", JSON.stringify(__userinfor));
            localStorage.setItem("USERTOKEN", JSON.stringify(__userinfor.user_token));
            this.show_id_login_err_code = 0;
            this.$store.state.userinfo = __userinfor;
            this.login_success_all_page_set();
          } else if (data.code == 2) {
            this.show_id_login_err_code = 2;
          } else if (data.code == 3) {
            this.show_id_login_err_code = 3;
          } else if (data.code == 7) {
            this.show_id_login_err_code = 7;
          }
        })
        .catch((err) => {
          // 登录失败
          console.log("登录失败", err);
          this.$message.error("服务器繁忙，请稍后再试");
          this.loginLoading = false;
        });
    },

    // 手机登录获取验证码按钮
    phone_login_getCode() {
      if (this.phone_account == "") {
        this.$message.error("账号不能为空");
        return;
      }
      var user = {
        account: this.phone_account,
        type: 1,
      };
      login_phone_code(user).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          console.log("短信发送成功");
        } else if (data.code == 4) {
          this.$message.error("发送验证码失败，请检查账号是否正确");
        }
      });

      this.phone_login_getCode_count();
    },
    // 手机登录验证码倒计时
    phone_login_getCode_count() {
      const TIME_COUNT = 60;
      if (!this.phone_login_code_timer) {
        this.phone_login_code_count = TIME_COUNT;
        this.phone_code_get_btn_show = false;
        this.phone_login_code_timer = setInterval(() => {
          if (this.phone_login_code_count > 0 && this.phone_login_code_count <= TIME_COUNT) {
            this.phone_login_code_count--;
          } else {
            this.phone_code_get_btn_show = true;
            clearInterval(this.phone_login_code_timer);
            this.phone_login_code_timer = null;
          }
        }, 1000);
      }
    },

    //手机号码登陆
    login_btn_phone() {
      if (this.phone_account == "") {
        this.$message.error("账号不能为空");
        return;
      }
      if (this.phone_code == "") {
        this.$message.error("验证码不能为空");
        return;
      }
      this.login_phone_fun();
    },

    login_phone_fun() {
      var user = {
        account: this.phone_account,
        code: this.phone_code,
      };
      login_phone(user)
        .then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 1) {
            // 登录成功
            this.$message.success("登录成功");
            var __userinfor = data.data;
            localStorage.setItem("LOGIN_FORM", "phone");
            localStorage.setItem("USER_INFO_TIME", new Date().getTime());
            localStorage.setItem("USER_INFO", JSON.stringify(__userinfor));
            localStorage.setItem("USERTOKEN", JSON.stringify(__userinfor.user_token));
            this.$store.state.userinfo = __userinfor;
            this.login_success_all_page_set();
          } else {
            this.$message.error(data.msg);
          }
        })
        .catch((err) => {
          // 登录失败
          console.log("登录失败", err);
          this.$message.error("服务器繁忙，请稍后再试");
          this.loginLoading = false;
        });
    },

    is_show_wx_login() {
      this.cur_login_type = "wx_login";
      this.show_id_login_err_code = 0;
      this.get_wx_login_qcode();
    },

    // 登录成功后各页面判断
    login_success_all_page_set() {
      if (
        this.$route.path == "/dwgsee" ||
        this.$route.path == "/dwg" ||
        this.$route.path == "/dwg3"
      ) {
        if (utils.getQueryVariable("share_id") != "" && this.$parent.is_refresh == false) {
          this.$EventBus.$emit("reflushUserinfo");
          this.$emit("change_child_value", false);
          return;
        }
        if (utils.getQueryVariable("file_id") != "" && this.$parent.is_refresh == false) {
          this.$EventBus.$emit("reflushUserinfo");
          this.$emit("change_child_value", false);
          return;
        }
        this.$router.go(0);
      } else if (this.$route.path == "/bip") {
        this.$router.go(0);
      } else if (this.$route.path == "/cdf") {
        if (utils.getQueryVariable("share_id") != "") {
          this.$EventBus.$emit("reflushUserinfo");
          this.$emit("change_child_value", false);
          return;
        }
        this.$router.go(0);
      } else if (this.$route.path == "/pdf") {
        this.$router.go(0);
      } else if (this.$route.path == "/mshare") {
        this.$router.go(0);
      } else {
        if (this.$store.state.isClickXieyi) {
          this.$store.state.is_show_xieyi = true;
          this.$emit("change_child_value", false);
          this.$emit("updateUserinfo", this.$store.state.userinfo);
        } else {
          this.$router.push("/recently_opened");
        }
      }
    },

    // 修改密码获取验证码
    reset_pwd_login_getCode() {
      this.show_reset_pwd_err = "";
      if (this.reset_pwd_account == "") {
        this.show_reset_pwd_err = "账号不能为空";
        return;
      }
      var user = {
        account: this.reset_pwd_account,
      };
      reset_password_getcode(user).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          console.log("短信发送成功");
          this.reset_pwd_getCode_count();
        } else if (data.code == 2) {
          this.show_reset_pwd_err = "该账号不存在";
        } else if (data.code == 3) {
          this.$message.error("发送验证码失败，请检查账号是否正确");
        }
      });
    },

    // 重置密码验证码倒计时
    reset_pwd_getCode_count() {
      const TIME_COUNT = 60;
      if (!this.reset_pwd_code_status_timer) {
        this.reset_pwd_code_count = TIME_COUNT;
        this.reset_pwd_code_get_btn_show = false;
        this.reset_pwd_code_status_timer = setInterval(() => {
          if (this.reset_pwd_code_count > 0 && this.reset_pwd_code_count <= TIME_COUNT) {
            this.reset_pwd_code_count--;
          } else {
            this.reset_pwd_code_get_btn_show = true;
            clearInterval(this.reset_pwd_code_status_timer);
            this.reset_pwd_code_status_timer = null;
          }
        }, 1000);
      }
    },

    // 重置密码
    reset_pwd_btn() {
      this.show_reset_pwd_err = "";
      if (this.reset_pwd_account == "") {
        this.show_reset_pwd_err = "请输入验证码";
        return;
      }
      if (this.reset_pwd_code == "") {
        this.show_reset_pwd_err = "请输入验证码";
        return;
      }
      if (this.reset_pwd == "") {
        this.show_reset_pwd_err = "请输入新密码";
        return;
      }
      if (this.reset_pwd.length < 6 || this.reset_pwd.length > 16) {
        this.show_reset_pwd_err = "新密码长度在6-16位之间";
        return;
      }
      var send = {
        account: this.reset_pwd_account,
        password: this.reset_pwd,
        code: this.reset_pwd_code,
      };
      reset_user_password(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          this.$message.success("恭喜您，修改密码成功");
          this.reset_pwd = "";
        } else if (data.code == 2 || data.code == 3 || data.code == 4) {
          this.show_reset_pwd_err = data.msg;
        } else if (data.code == 5) {
          this.show_reset_pwd_err = "修改失败，不能与原密码相同";
        } else {
          this.$message.error("修改失败，请稍后再试");
        }
      });
    },

    // 注册获取验证码
    reg_login_getCode() {
      this.show_reg_err = "";
      if (this.reg_account == "") {
        this.show_reg_err = "账号不能为空";
        return;
      }
      if (!this.isTelCode(this.reg_account)) {
        this.show_reg_err = "请输入正确的手机号码";
        return;
      }
      var user = { account: this.reg_account };
      user_reg_getcode(user).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          this.reg_getCode_count();
        } else if (data.code == 2) {
          this.show_reg_err = "该账号已注册";
        } else {
          this.$message.error("发送验证码失败，请稍后再试");
        }
      });
    },

    // 注册验证码倒计时
    reg_getCode_count() {
      const TIME_COUNT = 60;
      if (!this.reg_code_status_timer) {
        this.reg_code_count = TIME_COUNT;
        this.reg_code_get_btn_show = false;
        this.reg_code_status_timer = setInterval(() => {
          if (this.reg_code_count > 0 && this.reg_code_count <= TIME_COUNT) {
            this.reg_code_count--;
          } else {
            this.reg_code_get_btn_show = true;
            clearInterval(this.reg_code_status_timer);
            this.reg_code_status_timer = null;
          }
        }, 1000);
      }
    },

    //注册
    reg_btn() {
      this.show_reg_err = "";
      if (this.reg_account == "") {
        this.show_reg_err = "请输入正确的手机号码";
        return;
      }
      if (!this.isTelCode(this.reg_account)) {
        this.show_reg_err = "请输入正确的手机号码";
        return;
      }
      if (this.reg_code == "") {
        this.show_reg_err = "请输入验证码";
        return;
      }
      if (this.reg_pwd == "") {
        this.show_reg_err = "请输入密码";
        return;
      }
      if (this.reg_pwd.length < 6 || this.reg_pwd.length > 16) {
        this.show_reg_err = "密码长度在6-16位之间";
        return;
      }
      var send = {
        account: this.reg_account,
        password: this.reg_pwd,
        code: this.reg_code,
      };

      reg_api(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          this.$message.success("注册成功");
          if (utils.getQueryVariable("share_id") != "") {
            statistic("share_reg", "", utils.getQueryVariable("share_id"));
          }
          this.reg_login();
        } else if (data.code == 2 || data.code == 3 || data.code == 4) {
          this.show_reg_err = data.msg;
        } else {
          this.$message.error("注册失败，请稍后再试");
        }
      });
    },

    reg_login() {
      var user = {
        account: this.reg_account,
        pwd: new MD5().update(this.reg_pwd).digest("hex"),
      };
      login(user)
        .then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 1) {
            // 登录成功
            this.$message.success("登录成功");
            var __userinfor = data.data;
            localStorage.setItem("LOGIN_FORM", "account");
            localStorage.setItem("USER_INFO_TIME", new Date().getTime());
            localStorage.setItem("USER_INFO", JSON.stringify(__userinfor));
            localStorage.setItem("USERTOKEN", JSON.stringify(__userinfor.user_token));
            this.$store.state.userinfo = __userinfor;
            this.login_success_all_page_set();
          }
        })
        .catch((err) => {
          // 登录失败
          console.log("登录失败", err);
          this.$message.error("服务器繁忙，请稍后再试");
          this.loginLoading = false;
        });
    },

    /*校验电话码格式 */
    isTelCode(str) {
      var reg = /^1[3456789]\d{9}$/;
      return reg.test(str);
    },

    //获取微信二维码
    get_wx_login_qcode() {
      this.wx_login_qocde = "0";
      var that = this;
      login_wx()
        .then((res) => {
          var data = JSON.parse(res.data);
          if (data.code == 1) {
            // 获取扫码状态
            that.uuid = data.data.uuid;
            var wx_qrcode = data.data.wx_qrcode;
            this.wx_login_qocde = "4088";
            that.get_wx_login_status_one(data.data.uuid);
            that.creatLoginQrCode(wx_qrcode);
          }
        })
        .catch(() => {
          that.wx_status_code_back_html(10086);
        });
    },

    get_wx_login_status_one(uuid) {
      var send = {
        uuid: uuid,
      };
      login_wx_status(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          if (data.data.code != "") {
            this.get_wx_login_status(uuid, 3000);
          }
        }
      });
    },

    get_wx_login_status(uuid, time) {
      var send = { uuid: uuid };
      var that = this;
      clearInterval(that.wx_login_status_timer);
      that.wx_login_status_timer = setInterval(function () {
        login_wx_status(send)
          .then((res) => {
            var data = JSON.parse(res.data);
            if (data.code == 1) {
              var str = data.data;
              var code = str.slice(18, 21);
              if (code == "402") {
                console.log("二维码已过期");
                that.wx_status_code_back_html(code);
              } else if (code == "500") {
                that.wx_status_code_back_html(code);
              } else if (code == "403") {
                that.wx_status_code_back_html(code);
                console.log("用户拒绝授权");
              } else if (code == "404") {
                that.wx_status_code_back_html(code);
                clearInterval(that.wx_login_status_timer);
                if (!that.wx_login_status_first_404) {
                  setTimeout(function () {
                    that.wx_login_status_first_404 = true;
                    that.get_wx_login_status(uuid, 2000);
                  }, 3000);
                  return;
                }
                that.get_wx_login_status(uuid, 2000);
                console.log("等待用户授权");
              } else if (code == "408") {
                console.log("等待扫码");
                that.wx_status_code_back_html(code);
              } else if (code == "405") {
                console.log("扫码成功");
                var wx_code = str.slice(38, 70);
                that.wx_status_code_back_html(code);
                clearInterval(that.wx_login_status_timer);
                that.get_wx_login_status_back(uuid, wx_code);
              } else if (code == "666") {
                that.wx_status_code_back_html(code);
                console.log("666");
              }
            }
          })
          .catch(() => {
            that.wx_status_code_back_html("10086");
          });
      }, time);
    },

    //扫码成功回调
    get_wx_login_status_back(uuid, wx_code) {
      var that = this;
      var send = {
        uuid: uuid,
        code: wx_code,
      };
      login_wx_return(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          that.wachat_user_id = data.data.user_id;
          that.wachat_wx_id = data.data.wx_id;
          if (data.data.user_id == "" || data.data.user_id == null || data.data.user_id == "0") {
            //没绑定
            that.is_bindwx = "n";
            that.cur_login_type = "wx_bind_account";
          } else {
            that.is_bindwx = "y";
            //有绑定，弹窗选择 强制还是更换手机号
          }
        } else if (data.code == 200) {
          //直接登陆成功
          that.is_bindwx = "n";
          var __userinfor = data.data;
          localStorage.setItem("LOGIN_FORM", "wx");
          localStorage.setItem("USERTOKEN", JSON.stringify(data.data.user_token));
          localStorage.setItem("USER_INFO", JSON.stringify(__userinfor));
          localStorage.setItem("USER_INFO_TIME", new Date().getTime());
          that.$store.state.userinfo = __userinfor;
          that.login_success_all_page_set();
        } else if (res.code == 4) {
          that.$message.err(res.msg);
        } else {
          console.log(res.msg);
        }
      });
    },

    // 根据不同的code显示不同的文本
    wx_status_code_back_html(code) {
      if (code == "402") {
        clearInterval(this.wx_login_status_timer);
        this.wx_login_qocde = code;
      } else if (code == "403") {
        clearInterval(this.wx_login_status_timer);
        this.wx_login_qocde = code;
      } else if (code == "404") {
        this.wx_login_qocde = code;
      } else if (code == "500") {
        clearInterval(this.wx_login_status_timer);
        this.wx_login_qocde = code;
      } else if (code == "408") {
        this.wx_login_qocde = code;
      } else if (code == "405") {
        this.wx_login_qocde = code;
      } else if (code == "666") {
        clearInterval(this.wx_login_status_timer);
        this.wx_login_qocde = code;
      } else if (code == "10086") {
        clearInterval(this.wx_login_status_timer);
        this.wx_login_qocde = code;
      }
    },

    creatLoginQrCode(url) {
      this.wx_qcode = url;
    },

    // wx绑定获取验证码按钮
    wx_bind_login_getCode() {
      this.login_bind_phone_err = "";
      if (this.wx_bind_account == "") {
        this.login_bind_phone_err = "账号不能为空";
        return;
      }
      var user = {
        account: this.wx_bind_account,
        type: 4,
      };
      login_phone_code(user).then((res) => {
        var data = JSON.parse(res.data);
        this.wx_bind_getCode_count();
        if (data.code == 1) {
          console.log("");
        } else if (data.code == 2) {
          this.login_bind_phone_err = "该账号不存在";
        } else if (data.code == 3) {
          this.login_bind_phone_err = "60秒内请勿重复发送！";
        } else if (data.code == 4) {
          this.login_bind_phone_err = "发送验证码失败，请稍后再试";
        }
      });
    },
    // wx绑定验证码倒计时
    wx_bind_getCode_count() {
      const TIME_COUNT = 60;
      if (!this.wx_bind_status_timer) {
        this.wx_bind_code_count = TIME_COUNT;
        this.wx_bind_code_get_btn_show = false;
        this.wx_bind_status_timer = setInterval(() => {
          if (this.wx_bind_code_count > 0 && this.wx_bind_code_count <= TIME_COUNT) {
            this.wx_bind_code_count--;
          } else {
            this.wx_bind_code_get_btn_show = true;
            clearInterval(this.wx_bind_status_timer);
            this.wx_bind_status_timer = null;
          }
        }, 1000);
      }
    },

    go_to_wx_bind_btn(type) {
      if (this.wx_bind_account == "") {
        this.login_bind_phone_err = "请输入账号";
        return;
      }
      if (this.wx_bind_code == "") {
        this.login_bind_phone_err = "请输入验证码";
        return;
      }

      var send = {
        account: this.wx_bind_account,
        code: this.wx_bind_code,
        type: type,
        wx_id: this.wachat_wx_id,
      };
      var that = this;
      wxbind_phone(send).then((res) => {
        var data = JSON.parse(res.data);
        if (data.code == 1) {
          that.$message.success("恭喜您，绑定成功");
          that.login_bind_phone_err = "";
          that.wx_bind_account = "";
          that.wx_bind_code = "";
          var __userinfor = data.data;
          localStorage.setItem("LOGIN_FORM", "wx");
          localStorage.setItem("USER_INFO", JSON.stringify(__userinfor));
          localStorage.setItem("USERTOKEN", JSON.stringify(data.data.user_token));
          localStorage.setItem("USER_INFO_TIME", new Date().getTime());
          that.login_success_all_page_set();
        } else if (data.code == 2 || data.code == 3 || data.code == 4) {
          that.login_bind_phone_err = data.msg;
        } else if (data.code == 5) {
          that.is_bindwx = "y";
        } else {
          that.$message.error("绑定失败，请稍后再试");
        }
      });
    },

    wx_bind_phone_btn_yes() {
      this.go_to_wx_bind_btn(1);
    },

    // 微信登陆返回账号登陆
    return_account_login_box() {
      this.cur_show_box1 = "login";
      this.cur_login_type = "account";
      clearInterval(this.wx_login_status_timer);
    },

    //微信跳转注册账号
    wx_go_reg_box() {
      this.cur_show_box1 = "reg";
      clearInterval(this.wx_login_status_timer);
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .jizhuwo span.el-checkbox__label {
  color: #606266;
}

.mask {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 50;
  width: 100%;
  height: 100%;
  margin: 0px;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
}

.login-box,
.login-reg-box,
.login-reset-pwd-box {
  width: 750px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  border-radius: 12px;
  z-index: 999;
}

.login-box .logo,
.login-reg-box .logo,
.login-reset-pwd-box .logo {
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

/* 
	.login-box .logo img {
		width: 68px;
		height: 28px;
		margin-top: 5px;
	} */

.login-box .logo .word,
.login-reg-box .logo .word,
.login-reset-pwd-box .logo .word {
  font-size: 25px;
  font-weight: 700;
  color: #333;
  margin-left: 9px;
  font-family: PingFang SC, Microsoft YaHei;
}

.title {
  display: flex;
  flex-direction: row;
  margin-top: 45px;
  align-items: center;
  justify-content: center;
}

.title .title-line {
  width: 60px;
  height: 1px;
  background-color: #ebebeb;
  margin-top: 2px;
}

.title .word {
  font-size: 16px;
  color: #333;
  font-weight: 500;
}

.account,
.login_code,
.login-reset-pwd-code {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

input {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #999999;
}

.account input,
.login_code input,
.login-reset-pwd-box input {
  width: 356px;
  height: 46px;
  text-indent: 38px;
  font-size: 16px;
  color: #333;
  margin-top: 30px;
  outline: none;
  border: solid 1px #dcdcdc;
  border-radius: 8px;
}

.account input:focus,
.login_code input:focus,
.login-reset-pwd-box input:focus {
  color: #333;
  border: 1px solid #4383f0;
}

.password {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.password input {
  width: 356px;
  height: 46px;
  text-indent: 38px;
  font-size: 16px;
  color: #333;
  margin-top: 16px;
  border: solid 1px #dcdcdc;
  outline: none;
  border-radius: 8px;
}

.password input:focus {
  color: #333;
  border: 1px solid #4383f0;
}

#login_btn,
#login_btn_phone,
#login_reg_btn,
#login_reset_pwd_btn,
#login_bind_btn {
  width: 356px;
  height: 42px;
  text-align: center;
  line-height: 42px;
  /* background-image: linear-gradient(129deg, #4383f0 0%, #50acf9 100%); */
  background-color: #4383f0;
  border-radius: 1px;
  color: #fff;
  font-size: 16px;
  margin-top: 15px;
  cursor: pointer;
  border-radius: 8px;
}

#login_reset_pwd_btn {
  margin-top: 30px;
}

.login_btn_no {
  opacity: 0.6;
  cursor: not-allowed;
}

.login_btn_yes {
  opacity: 1;
  cursor: pointer;
}

.toreg {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toreg .right {
  width: 356px;
  margin-top: 15px;
  font-size: 14px;
  padding-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#login_close,
#login-reg-close,
#login-reset-pwd-close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  color: #999;
  width: 30px;
  height: 30px;
}

#login_close img,
#login-reg-close img,
#login-reset-pwd-close img {
  width: 100%;
  height: 100%;
}

#to_reg,
#to_login,
#reset_pwd_to_login {
  color: #4383f0;
  cursor: pointer;
}

.login_btn_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.titile_center {
  width: 356px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 29px;
  user-select: none;
}

.titile_center .word {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #333333;
}

#to_reg,
#to_reset {
  color: #4383f0;
  cursor: pointer;
}

#to_reset:hover {
  opacity: 0.6;
}

#to_reg:hover {
  opacity: 0.6;
}

.reg_commit {
  opacity: 0.5;
}

/*包含以下四种的链接*/
a {
  text-decoration: none;
}

/*正常的未被访问过的链接*/
a:link {
  text-decoration: none;
}

/*已经访问过的链接*/
a:visited {
  text-decoration: none;
}

/*鼠标划过(停留)的链接*/
a:hover {
  text-decoration: none;
}

/* 正在点击的链接*/
a:active {
  text-decoration: none;
}

.go_to_call_we a {
  width: 159px;
  height: 52px;
  display: block;
  color: #4383f0;
}

.main_header_title,
.go_to_login,
.go_to_reg,
.header_imgUrl_title1,
.header_imgUrl_title2,
.header_imgUrl_title3,
.header_imgUrl_title_open_dwg,
.shili_item_name,
.main_2_title1,
.main_2_title2,
.main_2_flex_left_1,
.main_2_flex_right_title,
.main_2_title1 span,
.main_3_flex_right_title,
.main_4_title,
.main_4_item_text1,
.main_4_item_text2,
.main_5_title3,
.main_5_title4,
.login_left_title_a,
.login_left_title_b,
.login_left_title2_item,
.login_right .word {
  font-family: "SourceHanSansSC-Bold", "PingFang SC";
}

.login_box_flex {
  display: flex;
}

.login_left_bg {
  width: 310px;
  height: 480px;
  background-image: url("@/assets/images/main/login_left_bg.png");
}

.login_right {
  width: 440px;
  height: 480px;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  position: relative;
}

.login_left_title2_item {
  display: flex;
  align-items: center;
  color: #fff;
  height: 24px;
  margin-bottom: 16px;
}

.login_left_title2_item img {
  width: 17px;
  height: 17px;
}

.login_left_title2_item div {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-left: 15px;
}

.login_left_title_a,
.login_left_title_b {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  width: 80px;
  height: 35px;
}

.login_left_title_a {
  width: 240px !important;
  font-size: 22px !important;
}

.login_left_title_b {
  font-size: 26px !important;
  width: 224px;
  height: 80px;
  margin-top: 17px;
}

.login_left_flex {
  height: 316px;
  width: 224px;
  display: flex;
  flex-direction: column;
  margin-left: 33px;
  margin-top: 65px;
}

.login_left_title2_flex {
  margin-top: 40px;
}

.password_show {
  position: absolute;
  right: 70px;
  top: 30px;
  /* transform: translateX(-50%); */
  width: 40px;
  height: 42px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

/* .password_show img {
    width: 30px;
    height: 21px;
  } */

.login_bottom_text {
  height: 40px;
  line-height: 40px;
  width: 100%;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #999999;
  background-color: #eeeeee;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  user-select: none;
}

.login_bottom_text a:link {
  text-decoration: none;
}

.login_bottom_text a {
  color: #4383f0;
  font-size: 12px !important;
}

.login_bottom_text a:hover {
  color: red;
}

#get_login_reg_code,
#get_login_reset_pwd_code {
  position: absolute;
  right: 75px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #999999;
  cursor: pointer;
  user-select: none;
  padding: 10px;
  margin-top: 30px;
  overflow: hidden;
}

.collection_box {
  line-height: 1;
}

.login_logo_box {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 40px;
  left: 33px;
}

.login_logo_box img {
  width: 28px;
  height: 28px;
}

.login_logo_box span {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #bfd4ff;
  padding-left: 10px;
}

.hide_show_password_btn {
  display: none;
}

.login_left_title2_item {
  display: flex;
  align-items: center;
  color: #fff;
  height: 24px;
  margin-bottom: 16px;
}

.login_left_title2_item img {
  width: 17px;
  height: 17px;
}

.login_left_title2_item div {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  margin-left: 15px;
}

.login_left_title_a,
.login_left_title_b {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #ffffff;
  width: 80px;
  height: 35px;
}

.login_left_title_b {
  font-size: 28px;
  width: 224px;
  height: 80px;
  margin-top: 17px;
}

.login_left_flex {
  height: 316px;
  width: 224px;
  display: flex;
  flex-direction: column;
  margin-left: 33px;
  margin-top: 65px;
}

.login_left_title2_flex {
  margin-top: 40px;
}

.password_show {
  position: absolute;
  right: 0px;
  top: 20px;
  /* transform: translateX(-50%); */
  width: 40px;
  height: 42px;
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.password_show .password_show_img {
  width: 25px;
  height: 21px;
  background-image: url("@/assets/images/common/1.png");
  background-position: -542px -3px;
}

.password_show .password_show_img_active {
  background-position: -565px -3px;
}

#reset_password_show {
  right: 30px;
  top: 25px;
}

.login_bottom_text {
  display: none;
  height: 40px;
  line-height: 40px;
  width: 100%;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #999999;
  background-color: #eeeeee;
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  user-select: none;
}

.login_bottom_text a:link {
  text-decoration: none;
}

.login_bottom_text a {
  color: #4383f0;
  font-size: 12px !important;
}

.login_bottom_text a:hover {
  color: red;
}

#get_login_reg_code,
#get_login_reset_pwd_code,
.get_phone_login_code,
#get_login_bind_code {
  position: absolute;
  right: 35px;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #999999;
  cursor: pointer;
  user-select: none;
  padding: 15px;
  margin-top: 30px;
  overflow: hidden;
}

.collection_box {
  line-height: 1;
}

.title .word_active {
  font-weight: 600;
  color: rgba(57, 117, 198, 1);
}

.show_phone_login {
  margin-left: 50px;
}

.toreg_right_flex {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.toreg_right_flex span {
  padding-left: 4px;
}

.toreg_right_jzw {
  width: 14px;
  height: 14px;
  background-image: url("@/assets/images/common/1.png");
  background-position: -106px -212px;
}

.toreg_right_jzw_active {
  background-position: -106px -226px;
}

#reg_to_login,
#wx_reg_to_login {
  user-select: none;
  color: #4383f0;
  cursor: pointer;
}

.show_id_login,
.show_phone_login {
  cursor: pointer;
}

.err_msg {
  position: absolute;
  height: 20px;
  right: 30px;
  top: 80px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: rgba(235, 83, 80, 1);
}

.no_shou_code {
  width: 356px;
  text-align: right;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  padding-top: 15px;
}

.go_to_kefu {
  color: #4383f0;
}

.main_header_bg_item1_lianxi {
  cursor: default;
}

.show_wx_login_box {
  display: block;
  margin: 15px auto;
  user-select: none;
  cursor: pointer;
  width: 100px;
}

.show_wx_login_img {
  width: 36px;
  height: 36px;
  background-image: url("@/assets/images/common/1.png");
  background-position: 0px -225px;
}

.show_wx_login_img_box {
  width: 51px;
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.show_wx_login_text {
  width: 48px;
  height: 11px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: rgba(102, 102, 102, 1);
  margin: auto;
}

.show_wx_login_login_top {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: rgba(51, 51, 51, 1);
  margin-left: -20px;
}

.show_wx_login_login_top span {
  cursor: pointer;
  user-select: none;
}

.show_wx_login_login_top span:hover {
  color: #4383f0;
}

.show_wx_login_login_center_title {
  font-size: 20px;
  font-weight: 600;
  font-stretch: normal;
  letter-spacing: 0px;
  color: rgba(57, 117, 198, 1);
  margin-top: 30px;
  margin-bottom: 40px;
  user-select: none;
}

.show_wx_login_login_center_title1 {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: rgba(57, 117, 198, 1);
  margin-top: 30px;
  user-select: none;
  cursor: pointer;
}

.show_wx_login_login_center_title2 {
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: rgba(51, 51, 51, 1);
  margin-top: 14px;
  user-select: none;
}

.show_wx_login_login_box,
.show_wx_login_login_box_bind {
  width: 341px;
  height: 425px;
  margin-top: 16px;
}

.show_wx_login_login_box_flex {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
}

.show_wx_login_login_center {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.show_wx_login_login_center_qcode_border {
  width: 188px;
  height: 188px;
  background-image: url("@/assets/images/common/1.png");
  background-position: 0 -23px;
  overflow: hidden;
  position: relative;
}

.show_wx_login_login_center_qcode {
  width: 158px;
  height: 158px;
  margin-top: 15px;
  margin-left: 15px;
}

.account_left_img {
  width: 16px;
  height: 22px;
  background-image: url("@/assets/images/common/1.png");
  background-position: -601px 0;
  position: absolute;
  top: 42px;
  left: 10px;
}

.code_left_img {
  background-position: -649px 0;
  top: 27px;
  left: 10px;
}

.password_left_img {
  background-position: -697px 0;
  top: 28px;
  left: 10px;
}

.account_left_img_active {
  background-position: -625px 0;
}

.code_left_img_active {
  background-position: -673px 0;
}

.password_left_img_active {
  background-position: -721px 0;
}

.password1_left_img_active {
  background-position: -721px 0;
}

/* .account:hover .account_left_img {
        background-position: -632px -856px;
    }

    .password:hover .password_left_img {
        background-position: -729px -856px;
    }

    .password1:hover .password_left_img {
        background-position: -729px -856px;
    }

    .login_code:hover .code_left_img {
        background-position: -681px -856px;
    } */

.login-reg-box .account_left_img {
  left: 50px;
  top: 41px;
}

.login-reg-box .code_left_img {
  left: 50px;
  top: 30px;
}

.login-reg-box .password_left_img {
  left: 50px;
  top: 30px;
}

.login-reset-pwd-box .account_left_img {
  left: 50px;
  top: 42px;
}

.login-reset-pwd-box .code_left_img {
  left: 50px;
  top: 31px;
}

.login-reset-pwd-box .password_left_img {
  left: 50px;
  top: 31px;
}

#iframe_wx_login {
  width: 400px;
  height: 450px;
  position: fixed;
  overflow-y: hidden;
  top: 50%;
  left: 39%;
  transform: translate(-50%, -50%);
  z-index: 56;
  background-color: #fff;
}

/* #close_wx_login_iframe {
        position: fixed;
        overflow-y: hidden;
        top: 71%;
        left: 39%;
        transform: translate(-50%, -50%);
        z-index: 57;
        cursor: pointer;
        user-select: none;
    }

    #close_wx_login_iframe:hover {
        color: #4383f0;
    } */

.wx_login_qcode img {
  width: 158px;
  height: 158px;
  padding: 0;
  margin: 0;
}

.wx_login_loading_box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
  color: #999;
  text-align: center;
}

.wx_login_loading_text2 {
  margin-top: 5px;
}

.wx_login_loading_box_guoqi .wx_login_loading_text1,
.wx_login_loading_box_guoqi .wx_login_loading_text2 {
  color: green;
}

.show_id_login_err,
.show_phone_login_err,
.show_id_reg_err,
.login_reset_pwd_err,
.login_bind_phone_err {
  position: absolute;
  height: 20px;
  right: 40px;
  top: 80px;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: rgba(235, 83, 80, 1);
  z-index: 1;
}

.show_id_reg_err,
.login_reset_pwd_err {
  top: 10px;
}

.login_bind_phone_err {
  top: 10px;
  right: 0;
}

.toreg_bottom {
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 0px 0px 10px 0px;
  position: absolute;
  left: 0;
  bottom: 0;
}

.show_id_login_err span {
  cursor: pointer;
}

.show_id_login_err span:hover {
  color: #9cbae2;
}

.up_time_login_from {
  position: absolute;
  left: 155px;
  top: 18px;
  width: 70px;
  height: 30px;
  background-image: url("@/assets/images/common/1.png");
  background-position: -235px -211px;
}

.up_time_login_from_account {
  position: absolute;
  left: 155px;
  top: 18px;
}

.up_time_login_from_phone {
  left: 280px;
}

.up_time_login_from_wx {
  width: 80px;
  left: 230px;
  top: 350px;
  background-position: -235px -242px;
}

.show_wx_login_login_box_bing {
  display: none;
}

/* 隐藏显示密码 */
.hide_show_password_btn {
  display: none;
}

.tanchuang-box {
  width: 450px;
  border-radius: 5px;
  box-shadow: -5px 5px 15px 1px rgb(0 0 0 / 10%);
  background-color: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 999;
}

.tanchuang-box .ctb-top,
.wx_qcode_bind_box .ctb-top {
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tanchuang-box .tan-ctb-title,
.wx_qcode_bind_box .tan-ctb-title {
  font-size: 15px;
  color: #333;
  height: 64px;
  line-height: 64px;
  padding-left: 30px;
}

.tanchuang-box .tanchuang_exit,
#feedback_box_exit,
.wx_qcode_bind_box .tanchuang_exit {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-right: 15px;
}

.tanchuang-box .tanchuang_exit img,
#feedback_box_exit img,
.wx_qcode_bind_box .tanchuang_exit img {
  width: 100%;
  height: 100%;
}

.tanchuang-box .ctb-line,
.wx_qcode_bind_box .ctb-line {
  width: 560px;
  height: 1px;
  background-color: #e7e7e7;
  margin-left: 20px;
  transform: scaleY(0.5);
}

.tanchuang-box .tan-ctb-title1 {
  height: 64px;
  padding-left: 30px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  line-height: 64px;
  letter-spacing: 0px;
  color: #000000;
}

.send_phone_item {
  position: relative;
  margin-left: 30px;
  width: 390px;
}

.send_phone_item #send_phone_input {
  width: 290px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 3px 0px 0px 3px;
  border: solid 1px #999999;
  outline: none;
  text-indent: 10px;
}

.send_phone_item #send_phone_input:active {
  border: solid 1px #4383f0 !important;
}

.send_phone_item #send_phone_input:focus {
  border: solid 1px #4383f0 !important;
}

.send_phone_code_btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 102px;
  height: 32px;
  background-color: #eeeeee;
  border-radius: 0px 3px 3px 0px;
  border: solid 1px #999999;
  font-size: 14px;
  line-height: 32px;
  color: #000000;
  text-align: center;
  user-select: none;
  cursor: pointer;
}

.send_phone_item_text {
  width: 390px;
  margin-top: 15px;
  margin-left: 30px;
}

.send_phone_item_text span {
  font-size: 14px;
  letter-spacing: 0px;
  color: #0082f0;
  cursor: pointer;
  user-select: none;
}

.tanchuang-box .ctb-btns {
  margin-top: 40px;
  margin-bottom: 30px;
  display: flex;
  width: 420px;
  justify-content: flex-end;
  user-select: none;
}

.tanchuang-box .ctb-btns div {
  width: 70px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 2px;
  font-size: 14px;
  cursor: pointer;
}

.tanchuang-box .tanchu_btn_qx {
  border: 1px solid #ced0d1;
  color: #999;
}

.tanchuang-box .tanchu_btn_sumbit {
  background-color: #3975c6;
  color: #fff;
  margin-left: 10px;
}
</style>
