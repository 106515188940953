// 用户香菇请求模块

import request from "@/utils/request";
import qs from "qs";
// 用户账号登入
export const login = (data) => {
  return request({
    method: "POST",
    url: "/cloud/login/loginnew",
    data: qs.stringify(data),
  });
};

// 用户手机登入
export const login_phone = (data) => {
  return request({
    method: "POST",
    url: "/cloud/login/phone_login",
    data: qs.stringify(data),
  });
};

// 用户手机登入 获取验证码
export const login_phone_code = (data) => {
  return request({
    method: "POST",
    url: "/cloud/login/send_phone_or_mail",
    data: qs.stringify(data),
  });
};

// 用户微信登录 获取二维码
export const login_wx = (data) => {
  return request({
    timeout: 15000,
    method: "POST",
    url: "/cloud/login/get_wxlogin_code",
    data: qs.stringify(data),
  });
};

// 用户微信登录 扫描二维码状态
export const login_wx_status = (data) => {
  return request({
    method: "POST",
    url: "/cloud/login/get_wxlogin_status",
    data: qs.stringify(data),
  });
};

// 用户微信登录 扫描后回调
export const login_wx_return = (data) => {
  return request({
    method: "POST",
    url: "/cloud/login/pc_new_login_weixin",
    data: qs.stringify(data),
  });
};

// 微信绑定账号
export const wxbind_phone = (data) => {
  return request({
    method: "POST",
    url: "/cloud/login/wxbind_phone",
    data: qs.stringify(data),
  });
};

// 自动token登录
export const usertoken_login = (data) => {
  return request({
    method: "POST",
    url: "/cloud/login/auto_user_token",
    data: qs.stringify(data),
  });
};

export const login_out = (data) => {
  return request({
    method: "POST",
    url: "/cloud/login/login_out",
    // data 用来设置 POST 请求体
    data: qs.stringify(data),
  });
};

export const getUsage = (data) => {
  return request({
    method: "POST",
    url: "/cloud/cloudapi/getUsage",
    data: qs.stringify(data),
  });
};

// 意见反馈
export const sendMsg = (data) => {
  return request({
    method: "POST",
    url: "/cloud/api/sendmsg",
    data: qs.stringify(data),
  });
};

export const get_dwg_infor = (data) => {
  return request({
    method: "POST",
    url: "/cloud/online/get_dwginfo",
    data: qs.stringify(data),
  });
};

// 获取会员状态
export const get_user_vipinfo = (data) => {
  return request({
    method: "POST",
    url: "/cloud/usercenter/get_user_vipinfo",
    data: qs.stringify(data),
  });
};

// 获取购买记录
export const get_user_order = (data) => {
  return request({
    method: "POST",
    url: "/cloud/usercenter/get_user_order",
    data: qs.stringify(data),
  });
};

// 获取开具发票记录
export const get_user_invoice = (data) => {
  return request({
    method: "POST",
    url: "/cloud/usercenter/get_user_invoice",
    data: qs.stringify(data),
  });
};

// 修改个人资料
export const update_userinfo = (data) => {
  return request({
    method: "POST",
    url: "/cloud/usercenter/update_userinfo",
    data: qs.stringify(data),
  });
};

// 密码修改 获取验证码
export const reset_password_getcode = (data) => {
  return request({
    method: "POST",
    url: "/cloud/login/reset_password_getcode",
    data: qs.stringify(data),
  });
};

// 密码修改确定
export const reset_user_password = (data) => {
  return request({
    method: "POST",
    url: "/cloud/login/reset_user_password",
    data: qs.stringify(data),
  });
};

// 注册 获取验证码
export const user_reg_getcode = (data) => {
  return request({
    method: "POST",
    url: "/cloud/login/user_reg_getcode",
    data: qs.stringify(data),
  });
};

// 注册 确定
export const reg_api = (data) => {
  return request({
    method: "POST",
    url: "/cloud/login/user_reg2",
    data: qs.stringify(data),
  });
};

// 检测是否登录
export const login_check = (data) => {
  return request({
    method: "POST",
    url: "/cloud/login/login_check",
    data: qs.stringify(data),
  });
};

// 注销账号 获取验证码
export const cancel_account_send = (data) => {
  return request({
    method: "POST",
    url: "/cloud/usercenter/cancel_account_send",
    data: qs.stringify(data),
  });
};

// 注销确定
export const cancel_account = (data) => {
  return request({
    method: "POST",
    url: "/cloud/usercenter/cancel_account",
    data: qs.stringify(data),
  });
};

//安全验证 发送短信
export const send_bind_wx_code = (data) => {
  return request({
    method: "POST",
    url: "/cloud/usercenter/send_bind_wx_code",
    data: qs.stringify(data),
  });
};

//微信 验证验证码是否正确
export const verification_code = (data) => {
  return request({
    method: "POST",
    url: "/cloud/usercenter/verification_code",
    data: qs.stringify(data),
  });
};

//微信 扫码成功后调用
export const bind_wx_status_back = (data) => {
  return request({
    method: "POST",
    url: "/cloud/usercenter/bind_wx",
    data: qs.stringify(data),
  });
};

// 微信强制绑定
export const forcebind = (data) => {
  return request({
    method: "POST",
    url: "/cloud/usercenter/forcebind",
    data: qs.stringify(data),
  });
};

// 微信解绑
export const cancelbind = (data) => {
  return request({
    method: "POST",
    url: "/cloud/usercenter/cancelbind",
    data: qs.stringify(data),
  });
};

// 绑定，解绑刷新用户信息
export const reset_userinfo = (data) => {
  return request({
    method: "POST",
    url: "/cloud/login/force_refresh_info",
    data: qs.stringify(data),
  });
};
