var _hmt = _hmt || [];
(function () {
  let str = window.location.href;
  if (str.indexOf('/i/') !== -1 || str.indexOf('/i/') !== -1) {
    
  } else {
    var hm = document.createElement("script");
    hm.src = "https://hm.baidu.com/hm.js?242afb1ad37c7d285d47911086b4862d";
    var s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(hm, s);
  }
})();