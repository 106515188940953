<template>
  <el-dialog
    title="安全验证"
    :visible.sync="$store.state.is_show_bindwx_get_code_box"
    width="450px"
    :modal="true"
    :append-to-body="true"
    @close="handleClose"
  >
    <div class="share_box_warp">
      <div class="tan-ctb-title1">
        为保障你的账号安全，请验证账号<span id="bind_user_phone"
          >{{accountText}}</span
        >
      </div>

      <div class="send_phone_item">
        <input
          id="send_phone_input"
          type="text"
          autocomplete="off"
          placeholder="输入验证码"
          v-model="$store.state.wxbind_code"
        />

        <div
          class="send_phone_code_btn"
          data-can-click="yes"
          @click="wxbind_get_code"
          v-show="$store.state.wxbind_get_btn_show"
          >点击获取验证码</div
        >

        <div
          class="send_phone_code_btn"
          style="color: #999"
          v-show="!$store.state.wxbind_get_btn_show"
          >{{ $store.state.wxbind_get_conut }}s后重新获取</div
        >
      </div>
      <div class="send_phone_item_text" style="margin-top: 40px">
        <!-- <div style="text-align: right;">无法接收验证码？<a href='/cloud/api/jump_qq' target="_blank" style="color:#4383f0" class="go_to_kefu">联系客服</a></div> -->
      </div>

      <div class="ctb-btns">
        <div class="tanchu_btn_qx" @click="handleClose">取消</div>
        <div class="tanchu_btn_sumbit" @click="bind_wx_btn_sumbit">确定</div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import utils from '@/utils/utils';
import {
  send_bind_wx_code,
  verification_code,
  cancelbind,
  reset_userinfo
} from '@/api/user'

export default {
  data() {
    return {
      accountText: '123***789',
      account: ''
    }
  },
  mounted() {
    this.accountText = utils.set_hide_phone(this.$parent.userinfo.account)
    this.account = this.$parent.userinfo.account

    var that = this
    // 监听其他组件调用上传文件
    this.$EventBus.$off("resetUserinfoBus");
    this.$EventBus.$on("resetUserinfoBus", function (data) {
      that.resetUserinfo(data);
    });
  },
  methods: {
    handleClose() {
      this.$store.state.is_show_bindwx_get_code_box = false;
    },

    //微信绑定发送短信获取验证码
    wxbind_get_code() {
      var bindaction = this.$store.state.cur_bindwx_action
      var send = {
        account: this.account,
        type: 1
      }
      if (bindaction == 'unbind') {
        send.type = 2
      }

      send_bind_wx_code(send).then((res) => {
        var data = JSON.parse(res.data);
        this.wxbind_get_code_count();
        if (data.code == 3) {
          this.$msg.whiteerr('发送短信失败，请勿在60s内重复发送')
        }
      });
    },

    // 验证码倒计时
    wxbind_get_code_count() {
      const TIME_COUNT = 60;
      if (!this.$store.state.wxbind_cod_timer) {
        this.$store.state.wxbind_get_conut = TIME_COUNT;
        this.$store.state.wxbind_get_btn_show = false;
        this.$store.state.wxbind_cod_timer = setInterval(() => {
          if (
            this.$store.state.wxbind_get_conut > 0 &&
            this.$store.state.wxbind_get_conut <= TIME_COUNT
          ) {
            this.$store.state.wxbind_get_conut--;
          } else {
            this.$store.state.wxbind_get_btn_show = true;
            clearInterval(this.$store.state.wxbind_cod_timer);
            this.$store.state.wxbind_cod_timer = null;
          }
        }, 1000);
      }
    },


    //安全验证 确定按钮
    bind_wx_btn_sumbit() {

      var bindaction = this.$store.state.cur_bindwx_action
      if (this.$store.state.wxbind_code == '') {
        this.$msg.whiteerr('验证码不能为空')
        return
      }
      var send = {
        account: this.account,
        code: this.$store.state.wxbind_code,
        type: 1
      }
      send.type = bindaction == 'bind' ? 1 : 2
      var that = this
      verification_code(send).then((res)=>{
        var data = JSON.parse(res.data)
        if (bindaction == 'bind') { //绑定下确定
          if (data.code == 1) {
            that.$store.state.is_show_bindwx_get_code_box = false;
            that.$store.state.is_show_bindwx_qcode_box = true
            that.$EventBus.$emit("getWxQcode");
          } else if (data.code == 3) {
            that.$msg.whiteerr(data.msg)
          } else if (data.code == 4) {
            that.$msg.whiteerr(data.msg)
          }
        } else if (bindaction == 'unbind') { //解绑下确定
          if (data.code == 1) { 
            console.log('unbind')
            that.cancel_bind(that.$store.state.wxbind_code)
          } else {
            alert(data.msg)
          }
        }

      })
    },

    //解绑
    cancel_bind(wxbind_code) {
      var send = {
        code: wxbind_code
      }
      cancelbind(send).then((res)=>{
        var data = JSON.parse(res.data)
        if (data.code == 1) {
          alert('解绑成功')
          this.resetUserinfo(0)
          // window.location.reload()
        } else {
          alert('解绑失败')
        }
      })
    },


    resetUserinfo(action) {
       // action 0 为普通登录， 1 微信登陆
      var send = {
        'user_token': JSON.parse(localStorage.getItem('USERTOKEN')),
        'type': action,
      }
      reset_userinfo(send).then((res)=>{
        var data = JSON.parse(res.data)
        if (data.code == 1) {
          this.$store.state.userinfo = data.data
          localStorage.setItem("USER_INFO", JSON.stringify(data.data));
          localStorage.setItem("USERTOKEN", JSON.stringify(data.data.user_token))
          window.location.reload()
        }
      })
    },

  },
};
</script>


<style lang="less" scoped>
.ctb-top,
.wx_qcode_bind_box .ctb-top {
  width: 100%;
  height: 64px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.tan-ctb-title,
.wx_qcode_bind_box .tan-ctb-title {
  font-size: 15px;
  color: #000;
  height: 64px;
  line-height: 64px;
  padding-left: 30px;
}

.tanchuang_exit,
#feedback_box_exit,
.wx_qcode_bind_box .tanchuang_exit {
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-right: 15px;
}

.tanchuang_exit img,
#feedback_box_exit img,
.wx_qcode_bind_box .tanchuang_exit img {
  width: 100%;
  height: 100%;
}

.ctb-line,
.wx_qcode_bind_box .ctb-line {
  width: 560px;
  height: 1px;
  background-color: #e7e7e7;
  margin-left: 20px;
  transform: scaleY(0.5);
}

.tan-ctb-title1 {
  height: 40px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #000000;
}

.send_phone_item {
  position: relative;
  width: 410px;
}

.send_phone_item #send_phone_input {
  width: 402px;
  height: 32px;
  background-color: #ffffff;
  border-radius: 3px 0px 0px 3px;
  border: solid 1px #999999;
  outline: none;
  text-indent: 10px;
  font-size: 16px;
  color: #333;
}

.send_phone_item #send_phone_input::-webkit-input-placeholder {
  color: rgba(153, 153, 153, 1);
  font-size: 14px;
}

.send_phone_item #send_phone_input:active {
  border: solid 1px #2072EC !important;
}

.send_phone_item #send_phone_input:focus {
  border: solid 1px #2072EC !important;
}

.send_phone_code_btn {
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
  height: 34px;
  background-color: transparent;
  border-radius: 0px 3px 3px 0px;
  border: solid 1px transparent;
  font-size: 14px;
  line-height: 34px;
  color: #2072EC;
  text-align: center;
  user-select: none;
  cursor: pointer;
}

.send_phone_item_text {
  width: 390px;
  margin-top: 15px;
  margin-left: 30px;
}

.send_phone_item_text span {
  font-size: 14px;
  letter-spacing: 0px;
  color: #0082f0;
  cursor: pointer;
  user-select: none;
}

.ctb-btns {
  display: flex;
  width: 410px;
  justify-content: flex-end;
  user-select: none;
}

.ctb-btns div {
  width: 70px;
  height: 32px;
  text-align: center;
  line-height: 32px;
  border-radius: 2px;
  font-size: 14px;
  cursor: pointer;
}

.tanchu_btn_qx {
  border: 1px solid #ced0d1;
  color: #999;
}

.tanchu_btn_sumbit {
  background-color: #3975c6;
  color: #fff;
  margin-left: 10px;
}

.tanchu_btn_qx:hover {
  background-color: rgba(241, 242, 247, 1);
}

.tanchu_btn_sumbit:hover {
  background-color: #3065ab;
}

#jb_wx_btn,
#jb_qtwx_btn {
  margin-top: 18px;
  width: 122px;
  height: 42px;
  line-height: 42px;
  text-align: center;
  background-color: #eb5350;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
}

/* #bind_wx_name {
    font-family: MicrosoftYaHei;
    font-size: 30px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 20px;
    letter-spacing: 0px;
    color: #333333;
    margin: 14px 0;
    text-align: center;
  } */

.bind_wx_text {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #666666;
}

.bind_wx_flex {
  display: none;
  justify-content: center;
}

#jb_qtwx_btn {
  margin-left: 20px;
  background-color: #fff;
  border: 1px solid #999;
  color: #333;
  display: none;
}

.bindwx_title,
#bind_wx_name {
  text-align: center;
  font-family: SourceHanSansSC-Regular, PingFangSC-Medium;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0px;
  color: #333;
}

#bind_wx_name {
  text-align: left;
  margin: 0 0 10px 0;
}

#bind_wx_name span {
  font-weight: 600;
  color: #000;
}
</style>
